import PropTypes from "prop-types";
import theme from "theme";
import Typography from "@mui/material/Typography";
import ModalDialog from "Core/components/Modal";
import ModalBody from "Core/components/Modal/body";
import ModalHeader from "Core/components/Modal/header";
import STATES from "DAI/ContentDetails/containers/ShareContentContainer/data";
import ShareableOptions from "DAI/ContentDetails/components/ShareContentPopup/ShareableOptions";
import GeneratingOverlay from "DAI/ProcessedData/components/MediaBlockOverlays/generatingOverlay";
import ShareContentFooterActions from "./footerActions";
import ShareDetail from "./shareDetail";
import { ReactComponent as ShareIcon } from "assets/icons/share-content.svg";

export default function ShareContentPopup({
  open,
  shareId,
  currentState,
  writtenOpts,
  mediaOpts,
  onWrittenOptionToggle,
  onMediaOptionToggle,
  onShare,
  onClose,
}) {
  return (
    <ModalDialog
      open={open}
      maxWidth="sm"
      onClose={onClose}
    >
      <ModalHeader icon={<ShareIcon />} onClose={onClose}>
        <Typography
          component="h1"
          sx={{
            color: theme.palette.gray[700],
            fontFamily: "Satoshi",
            fontSize: "24px",
            fontWeight: 900,
            letterSpacing: "0.15px",
            mb: "8px",
          }}
        >
          Share your Deciphr’d content
        </Typography>

        <Typography
          component="span"
          sx={{
            color: "#6D7A83",
            display: "block",
            fontFamily: "Satoshi",
            fontSize: "14px",
            fontWeight: 500,
            mb: "24px",
          }}
        >
          Choose which of the following content you&apos;d like to easily share
        </Typography>
      </ModalHeader>

      <ModalBody>
        <GeneratingOverlay show={currentState === STATES.INITIAL} prefix="Loading" type="" />

        <ShareableOptions
          show={currentState !== STATES.INITIAL}
          writtenOpts={writtenOpts}
          mediaOpts={mediaOpts}
          onWrittenOptionToggle={onWrittenOptionToggle}
          onMediaOptionToggle={onMediaOptionToggle}
        />

        <ShareDetail show={currentState === STATES.SHARED} shareId={shareId} />

        <ShareContentFooterActions
          show={currentState !== STATES.INITIAL}
          currentState={currentState}
          onShare={onShare}
          onClose={onClose}
        />
      </ModalBody>
    </ModalDialog>
  );
}

ShareContentPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  shareId: PropTypes.string,
  currentState: PropTypes.string.isRequired,
  writtenOpts: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    selected: PropTypes.bool,
  })).isRequired,
  mediaOpts: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    selected: PropTypes.bool,
  })).isRequired,
  onWrittenOptionToggle: PropTypes.func.isRequired,
  onMediaOptionToggle: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

ShareContentPopup.defaultProps = {
  shareId: null,
};
