import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function InfoMessage({ show, children }) {
  if (show) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography
          role="alert"
          sx={{
            fontSize: "14px",
            fontStyle: "italic",
            color: "#777E90",
            opacity: 0.5,
          }}
        >
          {children}
        </Typography>
      </Box>
    );
  }

  return null;
}

InfoMessage.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
