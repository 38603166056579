import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import SectionHeader from "DAI/ProcessedData/components/DownloadPopup/sectionHeader";
import ToggleOptions from "DAI/ProcessedData/components/DownloadPopup/toggleOptions";

export default function ShareableOptions({ show, writtenOpts, mediaOpts, onWrittenOptionToggle, onMediaOptionToggle }) {
  if (show) {
    return (
      <Box
        sx={{
          display: "flex",
          gap: "64px",
          minWidth: {
            sm: "500px",
          },
        }}
      >
        <Box component="section" sx={{ flex: 1, minWidth: "184px" }}>
          <SectionHeader>
            Content
          </SectionHeader>

          <ToggleOptions options={writtenOpts} onToggle={onWrittenOptionToggle} />
        </Box>

        <Box component="section" sx={{ flex: 1 }}>
          <SectionHeader>
            Media
          </SectionHeader>

          <ToggleOptions options={mediaOpts} onToggle={onMediaOptionToggle} />
        </Box>
      </Box>
    );
  }

  return null;
}

ShareableOptions.propTypes = {
  show: PropTypes.bool.isRequired,
  writtenOpts: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    selected: PropTypes.bool,
  })).isRequired,
  mediaOpts: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    selected: PropTypes.bool,
  })).isRequired,
  onWrittenOptionToggle: PropTypes.func.isRequired,
  onMediaOptionToggle: PropTypes.func.isRequired,
};
