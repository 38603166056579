import DAIButton from "Core/components/DAIButton";

export default function Download({ downloadLoader, download, isGenerating}) {
  return (
    <DAIButton
      loading={!!downloadLoader}
      disableElevation
      disabled={isGenerating}
      sx={{
        width: "128px",
        height: "40px",
        borderRadius: "90px",
        background: "#6552F3",
        color: "#fff",
        position: "absolute",
        bottom: "10px",
        right: "-70px",
        transform: "translate(-52%, -15%)",
        "&:hover": {
          background: "#6552F3",
        },
      }}
      onClick={download}
    >
      {isGenerating ? "Generating..." : "Download"}
    </DAIButton>
  );
}
