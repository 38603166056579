import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import AskDeciphrAnswer from "DAI/ProcessedData/components/AskDeciphrAnswer";
import AskDeciphrQuestion from "DAI/ProcessedData/components/AskDeciphrQuestion";
import { askDeciphr } from "DAI/ProcessedData/services/apis";
import { setHasOutput } from "DAI/ProcessedData/store/askDeciphrSlice";
import { analytics } from "utils/GTM";

const State = {
  initial: "initial",
  loading: "loading",
  generating: "generating",
  result: "result",
};

export default function AskDeciphrQnA({
  question,
  threadId,
  setThreadId,
  onLoaded,
}) {
  const [answer, setAnswer] = useState("");
  const dispatch = useDispatch();
  const params = useParams();
  const q = useMemo(() => question, [question]);
  const isGenerating = useRef(false);
  const [state, setState] = useState(State.initial);

  const handleAsk = useCallback(async (input) => {
    let newAnswer = "";

    setState(State.loading);
    setAnswer(newAnswer);
    dispatch(setHasOutput(true));

    analytics.track("RAG Page Search Initiated");
    analytics.track("RAG Page Search Query Submitted", {
      query: input,
    });

    try {
      const res = await askDeciphr(params.id, question, threadId);
      const reader = res.body.getReader();
      let chunk = await reader.read();
      onLoaded();

      if (res.headers.get("thread_id")) {
        setThreadId(res.headers.get("thread_id"));
      }

      while (!chunk?.done && chunk?.value) {
        const answerChunk = new TextDecoder().decode(chunk.value);

        newAnswer += answerChunk;

        setState(State.result);
        setAnswer(newAnswer);

        // eslint-disable-next-line no-await-in-loop
        chunk = await reader.read();
      }
    } catch (error) {
      onLoaded();
      setState(State.result);
      setAnswer(
        "I'm sorry, I couldn't find an answer for that. Please try again with "
        + "a different question or rephrase your query...",
      );
    }
  }, []);

  useEffect(() => {
    if (q && !isGenerating.current) {
      isGenerating.current = true;

      handleAsk(q);
    }
  }, []);

  return (
    <>
      <AskDeciphrQuestion question={question} />

      <AskDeciphrAnswer
        answer={answer}
        state={state}
        show={!!answer}
      />
    </>
  );
}

AskDeciphrQnA.propTypes = {
  question: PropTypes.string,
  threadId: PropTypes.string,
  setThreadId: PropTypes.func.isRequired,
  onLoaded: PropTypes.func,
};

AskDeciphrQnA.defaultProps = {
  question: "",
  threadId: null,
  onLoaded: () => { },
};
