import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingLogo from "Core/components/LoadingLogo";

export default function ContentLoadingState({ text = "Loading your content..." }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        height: "70%",
        minHeight: "480px",
      }}
    >
      <LoadingLogo />

      <Typography
        component="span"
        role="alert"
        aria-live="polite"
        sx={{
          fontSize: "15px",
          fontWeight: 600,
          color: "#6552F3",
          display: "block",
          textAlign: "center",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}

ContentLoadingState.propTypes = {
  text: PropTypes.string,
};

ContentLoadingState.defaultProps = {
  text: "Loading your content...",
};
