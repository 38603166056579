import { useSelector, useDispatch } from "react-redux";
import Menu from "@mui/material/Menu";
import ChangeSpeaker from "../../changeSpeaker";
import {
  speakerIndexSelector,
  isShowSpeakerSelector,
  handCloseModal,
  anchorElSelector,
  speakerNameSelector
} from "../../../../store/transcriptSlice";

export default function UpdateSkpeakerDropdown() {
  const isShowSpeaker = useSelector(isShowSpeakerSelector);
  const speakerIndex = useSelector(speakerIndexSelector);
  const anchorEl = useSelector(anchorElSelector);
  const speaker = useSelector(speakerNameSelector);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(handCloseModal());
  };

  return (
    <Menu
      open={isShowSpeaker}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { borderRadius: "12px" },
      }}
      id="demo-positioned-menu"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <ChangeSpeaker speaker={speaker} index={speakerIndex} />
    </Menu>
  );
}
