import audiogramIcon from "assets/icons/audiograms.png";
import clipFinderIcon from "assets/icons/clip-finder.png";
import reelsIcon from "assets/icons/reels.png";
import titlesIcon from "assets/icons/title-suggestions.png";

export const data = {
  "AI Clip Finder": {
    text: "Data collected by Statista shows that online videos had an audience "
      + "reach of 91.8% among internet users worldwide in 2022, with viral "
      + "content being one of the most popular types of videos. Deciphr's AI "
      + "Clip Finder makes it even easier for you to find share-worthy moments "
      + "from your content.",
    icon: clipFinderIcon,
    featureCode: "clipFinder",
  },

  Audiograms: {
    text: "Audiograms can help grab the attention of social media users and entice "
      + "them to listen to your podcast. According to We Edit Podcasts, audiograms "
      + "can increase engagement on social media by up to 10 times.",
    icon: audiogramIcon,
    featureCode: "audiograms",
  },

  "Video Reels": {
    text: "On average, video reels engage 22% more than regular posts. According "
      + "to a study by Growthoid, reels have a higher engagement rate than "
      + "regular posts, making them a powerful tool for social media marketing.",
    icon: reelsIcon,
    featureCode: "reels",
  },

  "Title Suggestions": {
    text: "On average, 8 out of 10 people will read headline copy, but only 2 "
      + "out of 10 will read the rest. This stat from Copyblogger highlights "
      + "the importance of having an attention-grabbing titles and headlines "
      + "to increase the chances of your content being read.",
    icon: titlesIcon,
    featureCode: "headlines",
  },
};
