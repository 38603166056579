import { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import LockedContentBanner from "DAI/ProcessedData/components/LockedContentBanner";
import UnlockMediaModal from "DAI/ProcessedData/components/UnlockMediaModal";
import { data } from "DAI/ProcessedData/services/lockedContent";

export default function ReelsPlaceholder({ show }) {
  const [isOpen, setIsOpen] = useState(false);

  if (show) {
    return (
      <Box>
        <LockedContentBanner
          text={data["Video Reels"].text}
          icon={data["Video Reels"].icon}
          onClick={() => setIsOpen(true)}
        />

        <UnlockMediaModal
          open={isOpen}
          contentType="Video Reels"
          featureCode={data["Video Reels"].featureCode}
          onClose={() => setIsOpen(false)}
        />
      </Box>
    );
  }

  return null;
}

ReelsPlaceholder.propTypes = {
  show: PropTypes.bool.isRequired,
};
