import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import AskDeciphrSuggestion from "DAI/ProcessedData/components/AskDeciphr/suggestion";

const suggestions = [
  "List the most viral moments and quotes from this file.",
  "What's the TL;DR of this episode?",
  "Tell me more about the speaker(s).",
];

const styles = {
  chipList: {
    margin: "40px 0 0 15px",
  },
};

export default function AskDeciphrSuggestions({ show, onClick }) {
  if (show) {
    return (
      <Stack sx={styles.chipList} direction="row" gap="12px">
        {suggestions.map((item, index) => (
          <AskDeciphrSuggestion label={item} key={index} onClick={onClick} />
        ))}
      </Stack>
    );
  }

  return null;
}

AskDeciphrSuggestions.propTypes = {
  show: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
