import { useState } from "react";
import PropType from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import theme from "theme";
import {
  Box,
  ButtonBase,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { selectFileMetadata } from "DAI/ContentDetails/store/contentSlice";
import { getSidebarLinks } from "../ContentLinks/data";
import { ReactComponent as ChevronDownIcon } from "assets/content-details/chevron-down.svg";
import { ReactComponent as PlusIcon } from "assets/content-details/plus.svg";

const styles = {
  menu: {
    border: "1px solid #D0D5DD",
    boxShadow: `
      0px 0.67px 3.5px 0px #00000003,
      0px 2.93px 7.25px 0px #00000004,
      0px 7.2px 14.46px 0px #00000005,
      0px 13.87px 28.35px 0px #00000006,
      0px 23.33px 52.12px 0px #00000008,
      0px 36px 89px 0px #0000000A
    `,
    borderRadius: "10px",
    width: "224px",
    height: "390px",
    maxHeight: "90vh",
    paddingBottom: "8px",

    "& ul": {
      padding: 0,
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
  },
  itemWrapper: {
    padding: "12px 11px 0 11px",
  },
  menuItem: {
    fontFamily: "Satoshi",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "18px",
    color: theme.palette.gray[500],
    height: "34px",
    padding: "8px 14px",
    borderRadius: "8px",
    transition: "all 0.3s ease",

    "&:hover": {
      background: theme.palette.gray[200],
      fontWeight: "700",
      color: theme.palette.gray[800],
    },
  },
  moreBtn: {
    fontFamily: "Satoshi",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "23.2px",
    color: theme.palette.gray[800],
    borderRadius: "38px",
    height: "42px",
    transition: "background-color 0.3s",
    padding: "9.5px 19.5px",
    border: "1px solid #DCDFE5",
    display: "flex",
    gap: "8px",

    "&:hover": {
      backgroundColor: theme.palette.gray[200],
      color: theme.palette.gray[700],
    },
    "&:active": {
      backgroundColor: "#D0D5DD99",
    },

    svg: {
      width: "20px",
      height: "auto",
      color: theme.palette.gray[400],
      transform: "rotate(180deg)",
      transition: "all 0.3s ease",
    },
  },
  openBtn: {
    svg: {
      transform: "rotate(0deg)",
    },
  },
  newContent: {
    fontFamily: "Satoshi",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "32px",
    color: theme.palette.gray[500],
    display: "flex",
    alignItems: "center",
    gap: "12px",
    position: "sticky",
    top: 0,
    background: "white",
    zIndex: 10,
    padding: "12px 18px 9px 12px",
    borderRadius: "10px 10px 0 0",
    height: "auto",
    borderBottom: "1px solid #E3E6EA",

    svg: {
      height: "20px",
      width: "auto",
    },
  },
  heading: {
    fontFamily: "Satoshi",
    fontSize: "12px",
    fontWeight: "700",
    lineHeight: "24px",
    letterSpacing: "0.04em",
    color: theme.palette.gray[400],
    paddingLeft: "23px",
  },
};

export default function ContentLinksDropdown({
  onClose,
  onClickMenuItemClick,
  onClickNewContent,
}) {
  const navigate = useNavigate();
  const metadata = useSelector(selectFileMetadata);
  const sidebarLinks = getSidebarLinks(metadata.preset || "podcast").flatMap((link) => link.tabs);
  const [localOpen, setLocalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(sidebarLinks[0]);

  const toggleMenu = (e) => {
    e?.stopPropagation();

    if (e?.currentTarget) {
      setAnchorEl(e.currentTarget);
    }

    if (!localOpen && onClose) {
      onClose();
    }

    setLocalOpen(!localOpen);
  };

  const handleClickMenuItem = async (e, index) => {
    e.stopPropagation();
    const item = sidebarLinks[index];

    if (onClickMenuItemClick) {
      onClickMenuItemClick(item, index);
    }

    if (item.action) {
      item.action(item, index);
    }

    navigate(`/content/${metadata.fileId}?tab=${item.value}`);
    setSelected(item);
    toggleMenu();
  };

  return (
    <>
      <ButtonBase
        disableRipple
        sx={[
          styles.moreBtn,
          localOpen && styles.openBtn,
        ]}
        type="button"
        tabIndex={-1}
        onClick={toggleMenu}
      >
        {selected.name}

        <ChevronDownIcon />
      </ButtonBase>

      <Menu
        anchorEl={anchorEl}
        open={localOpen}
        PaperProps={{
          sx: styles.menu,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={toggleMenu}
      >
        <MenuItem
          sx={[styles.menuItem, styles.newContent]}
          onClick={onClickNewContent}
        >
          <PlusIcon />

          New Content
        </MenuItem>

        <Box sx={styles.heading}>
          CONTENT
        </Box>

        {sidebarLinks.map((item, index) => (
          <Box
            key={item.id}
            sx={styles.itemWrapper}
          >
            <MenuItem
              sx={[styles.menuItem]}
              onClick={(e) => handleClickMenuItem(e, index)}
            >
              <Stack
                direction="row"
                alignItems="center"
                gap="8px"
              >
                <Box>
                  {item.name}
                </Box>
              </Stack>
            </MenuItem>
          </Box>
        ))}
      </Menu>
    </>
  );
}

ContentLinksDropdown.propTypes = {
  onClose: PropType.func,
  onClickMenuItemClick: PropType.func,
  onClickNewContent: PropType.func,
};

ContentLinksDropdown.defaultProps = {
  onClose: () => { },
  onClickMenuItemClick: () => { },
  onClickNewContent: () => { },
};
