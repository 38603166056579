import { useDispatch, useSelector } from "react-redux";
import * as clipboard from "clipboard-polyfill";
import Stack from "@mui/material/Stack";
import DAIButton from "Core/components/DAIButton";
import { ContentWrapperBody, ContentWrapperHeader } from "DAI/ProcessedData/containers/ContentWrapper";
import SectionTitle from "DAI/ContentDetails/components/SectionTitle";
import InfoMessage from "DAI/ProcessedData/components/InfoMessage";
import GeneratingOverlay from "DAI/ProcessedData/components/MediaBlockOverlays/generatingOverlay";
import { selectFileMetadata, selectProcessed } from "DAI/ContentDetails/store/contentSlice";
import { selectContent } from "DAI/ProcessedData/store/writtenContentSlice";
import { setToast } from "store/toastSlice";
import { analytics } from "utils/GTM";
import { ChipCopyOnClick } from "./ChipCopyOnClick";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";

export default function SEOKeywordsContainer() {
  const keywords = useSelector(selectContent("focusKeywords"));
  const processed = useSelector(selectProcessed);
  const { version } = useSelector(selectFileMetadata);
  const dispatch = useDispatch();
  const displaySeoKeywords = keywords?.length > 0;

  const onCopy = () => {
    const clipboardItems = new clipboard.ClipboardItem({
      "text/plain": new Blob([keywords.join(", ")], { type: "text/plain" }),
    });

    clipboard.write([clipboardItems]);

    dispatch(
      setToast({
        message: "Copied to your clipboard",
        severity: "success",
        autoClose: true,
      }),
    );

    analytics.track("Copy to clipboard", { category: "SEO Keywords" });
  };

  return (
    <div style={{ marginBottom: "48px" }}>
      <ContentWrapperHeader>
        <SectionTitle title="Focus Keywords" />

        {displaySeoKeywords && (
          <DAIButton
            variant="text"
            aria-label="Copy all SEO keywords to clipboard"
            className="copy-all-btn"
            startIcon={<CopyIcon />}
            sx={{
              p: "4px 16px",
              minWidth: 0,
              fontSize: "16px",
              fontWeight: 500,
            }}
            disableElevation
            onClick={onCopy}
          >
            Copy
          </DAIButton>
        )}
      </ContentWrapperHeader>

      <ContentWrapperBody>
        {keywords !== null && keywords?.length > 0 && (
          <Stack direction="row" flexWrap="wrap" rowGap="20px" columnGap="10px">
            {keywords.map((x, index) => (
              <ChipCopyOnClick label={x} key={index} />
            ))}
          </Stack>
        )}

        <GeneratingOverlay
          show={processed === "N" && (keywords === null || keywords?.length === 0) && version !== null}
          type="Focus Keywords"
        />

        <GeneratingOverlay
          show={processed === null && keywords === null}
          prefix="Loading"
          type="Focus Keywords"
        />

        <InfoMessage show={!displaySeoKeywords && version === null}>
          SEO Keywords were not generated for this file.
        </InfoMessage>
      </ContentWrapperBody>
    </div>
  );
}
