import { useMemo } from "react";
import PropType from "prop-types";
import ReactVideoThumbnail from "react-video-thumbnail";
import { Box, Stack } from "@mui/material";
import PlayArrow from "@mui/icons-material/PlayArrow";
import { ReactComponent as DummyVideoThumbnailImg } from "assets/reels/placeholder-reel-thumbnail.svg";

const styles = {
  root: {
    width: "136px",
    height: "113px",
    padding: 0,
    position: "relative",
    borderRadius: "16px",
    overflow: "hidden",
    border: "none",

    "&::before": {
      content: "' '",
      position: "absolute",
      left: "0px",
      top: "0px",
      width: "100%",
      height: "100%",
      background: "#00000033",
    },

    "&:hover": {
      ".playIcon": {
        bgcolor: "#E6E8EC !important",
      },
    },
  },
  img: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
  playIcon: {
    width: "30px",
    height: "30px",
    borderRadius: "30px",
    bgcolor: "#FFFFFF",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    transition: "background-color 0.3s",

    "& svg": {
      height: "24px",
      width: "auto",
    },
  },
  timeText: {
    padding: "2px 8px",
    borderRadius: "24px",
    color: "white",
    bgcolor: "#1A1A1AB2",
    fontFamily: "Satoshi",
    fontSize: "10px",
    fontWeight: "700",
    lineHeight: "16px",
    position: "absolute",
    bottom: "10px",
    right: "10px",
  },
  generated: {
    height: "100%",
    width: "100%",

    "& img": {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
  },
};

/**
 * @param {Object} props
 * @param {string} [props.videoUrl]
 * @param {string} [props.thumbUrl]
 * @param {string} [props.title]
 * @param {number} [props.durationInMillis]
 * @param {() => void} [props.onClick]
 */
export default function VideoThumbnail({
  videoUrl,
  thumbUrl,
  title,
  durationInMillis,
  onClick,
}) {
  const thumbnail = useMemo(() => {
    if (!(videoUrl || thumbUrl)) {
      return <DummyVideoThumbnailImg />;
    }

    if (!thumbUrl) {
      return (
        <Box sx={styles.generated}>
          <ReactVideoThumbnail
            key={videoUrl}
            videoUrl={videoUrl}
            snapshotAtTime={5}
          />
        </Box>
      );
    }

    return <Box component="img" sx={styles.img} src={thumbUrl} alt={title} />;
  }, [thumbUrl, videoUrl, title]);

  const handleClick = () => {
    onClick();
  };

  function formatTime(duration) {
    if (!duration || duration <= 0) {
      return "00:00:00";
    }

    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${hours}:${minutes}:${seconds}`;
  }

  return (
    <Stack
      component="button"
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={styles.root}
      disabled={!(thumbUrl || videoUrl)}
      onClick={handleClick}
    >
      {thumbnail}

      <Box className="playIcon" sx={styles.playIcon}>
        <PlayArrow />
      </Box>

      <Box sx={styles.timeText}>{formatTime(durationInMillis)}</Box>
    </Stack>
  );
}

VideoThumbnail.propTypes = {
  videoUrl: PropType.string,
  thumbUrl: PropType.string,
  title: PropType.string,
  durationInMillis: PropType.string,
  onClick: PropType.func,
};

VideoThumbnail.defaultProps = {
  videoUrl: "",
  thumbUrl: "",
  title: "",
  durationInMillis: 0,
  onClick: () => { },
};
