import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Button,
  Checkbox,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import Brightness1Icon from "@mui/icons-material/Brightness1";

import {
  speakerListSelector,
  handleOpenModal,
  updateAllSpeakerAsync,
  delSpeakerAsync,
  speakerUpdateLoadingSelector,
} from "../../store/transcriptSlice";
import editIcon from "../../assets/edit-icon.svg";
import deleteIcon from "../../assets/deleteRed.svg";
import { analytics } from "../../utils/GTM";

const Styles = {
  wrapper: {
    padding: "10px 23px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 500,
    color: "#868686",
    transition: "ease all 0.5s",

    ".edit": {
      opacity: 0,
      cursor: "pointer",
    },
    "&:hover": {
      ".edit": {
        opacity: 1,
      },
    },
  },
};

function ChangeSpeaker({ speaker, index }) {
  const speakers = useSelector(speakerListSelector);
  const [checked, setChecked] = useState(speaker);
  const isLoading = useSelector(speakerUpdateLoadingSelector);
  const [selectedSpeaker, setSelectedSpeaker] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setChecked(speaker);
  }, [speaker]);

  const handleChange = (event) => {
    setChecked(event.target.value);
    setSelectedSpeaker(event.target.value);
    dispatch(
      updateAllSpeakerAsync({
        oldSpeaker: speaker,
        newSpeaker: event.target.value,
        isall: false,
        index,
      })
    );
    analytics.track("Updated speaker name");
  };

  return (
    <Box
      sx={{
        width: "220px",
      }}
    >
      <FormControl sx={{ width: "100%" }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          {speakers.map((item, idx) => (
            <Box key={idx} sx={Styles.wrapper}>
              <FormControlLabel
                value={item}
                control={
                  <Checkbox
                    icon={
                      <Brightness1Icon
                        sx={{
                          color: "#D6D6D6",
                        }}
                      />
                    }
                    checkedIcon={
                      <TripOriginIcon
                        sx={{
                          color: "#6552F3",
                        }}
                      />
                    }
                  />
                }
                checked={checked === item}
                label={item}
                onChange={handleChange}
              />
              <Box
                sx={{
                  display:
                    selectedSpeaker === item
                      ? isLoading
                        ? "none"
                        : "flex"
                      : "flex",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(
                      handleOpenModal({
                        isNew: false,
                        speaker: item,
                      })
                    );
                  }}
                >
                  <img src={editIcon} style={{ width: "13px" }} alt="icon" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedSpeaker(item);
                    dispatch(
                      delSpeakerAsync({
                        speaker: item,
                      })
                    );
                  }}
                >
                  <img src={deleteIcon} style={{ width: "13px" }} alt="icon" />
                </Box>
              </Box>
              <Box
                sx={{
                  display:
                    selectedSpeaker === item
                      ? isLoading
                        ? "block"
                        : "none"
                      : "none",
                }}
              >
                <CircularProgress size={20} />
              </Box>
            </Box>
          ))}
        </RadioGroup>
        <FormLabel
          id="demo-radio-buttons-group-label"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "11px 18px",
            alignItems: "center",
          }}
        >
          <Button
            variant="text"
            sx={{
              color: "#6552F3",
              textTransform: "none",
              padding: "0px",
              fontSize: "14px",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "transparent !important",
                boxShadow: "none !important",
              },
            }}
            onClick={() =>
              dispatch(
                handleOpenModal({
                  isNew: true,
                })
              )
            }
          >
            + Add new
          </Button>
        </FormLabel>
      </FormControl>
    </Box>
  );
}

export default ChangeSpeaker;
