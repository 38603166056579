import PropTypes from "prop-types";
import theme from "theme";
import { Box, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DAIButton from "Core/components/DAIButton";
import { ReactComponent as FilmIcon } from "assets/content-details/film-icon.svg";

const styles = {
  root: {
    height: "86px",
    borderRadius: "18px",
    padding: "17px 26px",
    background: "linear-gradient(-37.13deg, #FFF 58.44%, #E8E6FF 105.48%)",
    border: `1px solid ${theme.palette.primary[200]}`,
  },
  title: {
    fontFamily: "Satoshi",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "28.8px",
    color: "#6552F3",
  },
  subtext: {
    fontFamily: "Satoshi",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "19px",
    letterSpacing: "0.02em",
    marginTop: "4px",
    color: theme.palette.gray[500],
  },
  btn: {
    margin: 0,
    py: "8px",
    minWidth: "auto",
    padding: "10px 13px 10px 10px",
  },
};

export default function CreateReelCTA({ show, onClick }) {
  if (show) {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={styles.root}
      >
        <Stack
          direction="row"
          gap="21px"
        >
          <FilmIcon />

          <Box>
            <Box sx={styles.title}>
              Create a new Reel
            </Box>

            <Box sx={styles.subtext}>
              Create you own reel
            </Box>
          </Box>
        </Stack>

        <DAIButton
          size="sm"
          startIcon={<AddIcon sx={{ fontSize: "20px" }} />}
          sx={styles.btn}
          onClick={onClick}
        >
          New Reel
        </DAIButton>
      </Stack>
    );
  }

  return null;
}

CreateReelCTA.propTypes = {
  show: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
