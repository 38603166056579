import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Box, IconButton, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { audioFileSelector } from "store/audiogramSlice";
import { selecteTimeStampSelector } from "store/transcriptSlice";

const style = {
  root: {
    position: "sticky",
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    backgroundColor: "#fff",
    height: "80px",
    boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  body: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "95%",
  },
};

export default function AudioPlayer({ display }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const selectedTimeStamp = useSelector(selecteTimeStampSelector);
  const url = useSelector(audioFileSelector);
  const audioRef = useRef(null);

  useEffect(() => {
    if (selectedTimeStamp) {
      audioRef.current.currentTime = selectedTimeStamp;
      setCurrentTime(selectedTimeStamp);
    }
  }, [selectedTimeStamp]);

  useEffect(() => {
    const audio = audioRef.current;
    const handleLoadedMetadata = () => {
      const newDuration = audioRef.current?.duration;

      if (newDuration && newDuration !== Infinity) {
        setDuration(newDuration);
      }

      setIsLoading(false);
    };

    if (audio) {
      audio.addEventListener("loadedmetadata", handleLoadedMetadata);
    }

    return () => {
      if (audio) {
        audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
      }
    };
  }, [url]);

  const handlePlayPauseClick = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }

    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleSeek = (e) => {
    const newTime = e.target.value;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <Box
      sx={{
        ...style.root,
        // eslint-disable-next-line no-nested-ternary
        display: url ? (display ? "flex" : "none") : "none",
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        ref={audioRef}
        src={url}
        onTimeUpdate={handleTimeUpdate}
        preload="auto"
      />

      {isLoading && (
        <Box sx={{ width: "90%" }}>
          <Typography
            sx={{
              color: "#23262F",
              fontSize: "1rem",
              fontWeight: 500,
            }}
          >
            Loading Audio...
          </Typography>

          <LinearProgress
            sx={{
              backgroundColor: "#E5E5E5",
              "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#6552F3",
              },
            }}
          />
        </Box>
      )}

      {!isLoading && (
        <Box sx={style.body}>
          <Box>
            <IconButton
              aria-label={isPlaying ? "play" : "pause"}
              onClick={handlePlayPauseClick}
            >
              {!isPlaying ? (
                <PlayCircleIcon sx={{ fontSize: "2rem", color: "#6552F3" }} />
              ) : (
                <PauseCircleIcon sx={{ fontSize: "2rem", color: "#6552F3" }} />
              )}
            </IconButton>
          </Box>

          <span
            style={{
              width: "40px",
              color: "#23262F",
            }}
          >
            {formatTime(currentTime)}
          </span>

          <Box
            sx={{
              width: "100%",
              position: "relative",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                left: 0,
                alignItems: "center",
                justifyContent: "center",
                pointerEvents: "none",
                pl: 2,
              }}
            >
              <Box
                style={{
                  display: "flex",
                  gap: 4,
                }}
              >
                {[...Array(425)].map((_, index) => (
                  <span
                    key={index}
                    style={{
                      height: "30px",
                      width: "3px",
                      backgroundColor: "#fff",
                    }}
                  />
                ))}
              </Box>
            </Box>

            <input
              type="range"
              min="0"
              max={duration}
              value={currentTime}
              onChange={handleSeek}
              style={{
                width: "100%",
                height: "30px",
                WebkitAppearance: "none",
                cursor: "pointer",
                background: `linear-gradient(to right, #6552F3 0%,
                  #6552F3 ${(currentTime / duration) * 100}%,
                  #D4D4D4 ${(currentTime / duration) * 100}%, #D4D4D4 100%)`,
              }}
            />
          </Box>

          <span
            style={{
              width: "40px",
              color: "#23262F",
            }}
          >
            {formatTime(duration)}
          </span>
        </Box>
      )}
    </Box>
  );
}
