import { rollbar } from "Core/helpers/rollbar";
import { getUserPlan } from "DAI/Subscription/services/utils";

/**
 * Check if the user account has been verified
 *
 * @returns {boolean} A boolean to indicate if account is verified
 */
export const isAccountVerified = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (userInfo && userInfo.user.emailVerified === true);
};

/**
 * Get the duration, in seconds, of a provided
 * audio or video file.
 *
 * @param {File} file
 * @returns {Promise<number>} Promise object representing the duration of the file
 */
export const getMediaDuration = (file) => {
  const allowedTypes = ["audio", "video"];
  const typeOfFile = file.type.split("/")[0];

  if (allowedTypes.indexOf(typeOfFile) === -1) {
    throw new Error("getDuration() expects an audio or video file as input.");
  }

  const mediaElement = document.createElement("audio");
  const objUrl = URL.createObjectURL(file);
  let duration = null;

  mediaElement.volume = 0;
  mediaElement.autoplay = true;
  mediaElement.preload = "auto";

  mediaElement.setAttribute("src", objUrl);

  return new Promise((resolve) => {
    const listener = (event) => {
      duration = event.currentTarget.duration || 60;

      resolve(duration);

      // Clean up
      setTimeout(() => {
        mediaElement.pause();
        mediaElement.removeEventListener("canplay", listener);
        mediaElement.remove();

        URL.revokeObjectURL(objUrl);
      }, 100);
    };

    mediaElement.addEventListener("canplay", listener);
  });
};

/**
 * Check whether the file size of an uploaded file
 * falls within the limits of a user's subscription plan
 *
 * @param {File} file
 * @param {string} plan The user's current subscription plan
 * @returns {boolean} A boolean indicating if the user is allowed to upload the file
 */
export const isFileSizeWithinLimit = (file, plan) => {
  const sizeInBytes = file.size;
  const subscriptionPlan = getUserPlan(plan);

  if (!subscriptionPlan) {
    throw new Error(`No subscription plan named ${plan} exists. Provide a valid plan name.`);
  }

  if (subscriptionPlan.maxFileSize) { // can be null, indicating no file limit cap
    const isValid = sizeInBytes <= subscriptionPlan.maxFileSize;

    if (!isValid) {
      rollbar.warning(
        "Upload file size is above the maximum threshold for current plan",
        { plan, fileName: file.name, fileSize: file.size },
      );
    }

    return isValid;
  }

  // Pro plan users, no cap on file size
  return true;
};

/**
 * Check whether the duration/length of an uploaded file
 * falls within the limit of a user's subscription plan
 *
 * @param {number} duration The file's duration, in seconds
 * @param {string} plan The user's current subscription plan
 * @param {File} file The file being uploaded
 * @param {number} credits The remaining credits on the user's plan
 * @returns {boolean} A boolean indicating if the user is allowed to upload the file
 */
export const isFileDurationWithinLimit = (duration, plan, file, credits) => {
  if (plan === "") {
    return false;
  }

  const subscriptionPlan = getUserPlan(plan);

  if (!subscriptionPlan) {
    throw new Error(`No subscription plan named ${plan} exists. Provide a valid plan name.`);
  }

  if (subscriptionPlan.maxFileLength !== null) {
    // Max file length is in minutes, convert it to seconds before comparison
    const isValid = duration <= (subscriptionPlan.maxFileLength * 60);

    if (!isValid) {
      rollbar.warning(
        "Upload file duration is longer than the maximum threshold for current plan",
        { plan, file, duration: (duration / 60), credits },
      );
    }

    return isValid;
  }

  // Pro plan users, no cap on file duration
  return true;
};

/**
 * Get the file size and duration limits for a
 * given subscription plan.
 *
 * @param {string} plan
 * @returns {object} An object with size and duration properties
 */
export const getFileLimitsForPlan = (plan) => {
  if (plan === "") {
    return false;
  }

  const subscriptionPlan = getUserPlan(plan);

  if (!subscriptionPlan) {
    throw new Error(`No subscription plan named ${plan} exists. Provide a valid plan name.`);
  }

  let size;

  if (subscriptionPlan.maxFileSize !== null) {
    const fileSizeInGB = subscriptionPlan.maxFileSize / (1024 * 1024 * 1024);

    if (fileSizeInGB < 1) {
      size = `${fileSizeInGB * 1000} MB`;
    } else {
      size = `${fileSizeInGB} GB`;
    }
  }

  return { size, duration: subscriptionPlan.maxFileLength };
};

/**
 * Check whether a given file duration is above the
 * minimum file duration threshold (1 minute)
 *
 * @param {number} duration
 * @returns {boolean} A boolean indicating whether the file is above the minimum duration threshold
 */
export const isAboveMinDurationThreshold = (duration) => {
  const minDuration = 60; // seconds

  if (duration < minDuration) {
    rollbar.info(
      "Upload file duration is less than 1 minute long",
      { duration },
    );
  }

  return duration >= minDuration;
};

/**
 * Check whether a provided editing feature is available
 * on the current user's subscription plan.
 *
 * @param {string} feature
 * @param {string} plan
 * @returns {boolean} A boolean indicating whether the feature is available
 */
export const isEditFeatureAvailable = (feature, plan) => {
  if (plan === "") {
    return false;
  }

  const subscriptionPlan = getUserPlan(plan);

  if (!subscriptionPlan) {
    throw new Error(`No subscription plan named ${plan} exists. Provide a valid plan name.`);
  }

  return subscriptionPlan.editingFeatures.indexOf(feature) > -1;
};

/**
 * Check if a feature is available for a user with their current plan
 *
 * @param {string} feature Feature for checking if it's available
 * @param {string} plan Current plan of the user
 * @returns `true` if the feature is available for the user with their current plan. `false` if not
 */
export const isFeatureAvailable = (feature, plan) => {
  if (plan === "") {
    return false;
  }

  const subscriptionPlan = getUserPlan(plan);

  if (!subscriptionPlan) {
    return false;
  }

  return subscriptionPlan.availableFeatures.indexOf(feature) > -1;
};
