import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { getDefaultTab } from "DAI/ContentDetails/containers/ContentLinks/data";
import OutputRating from "DAI/ContentDetails/components/OutputRating";
import CONTENT_TYPES from "DAI/ContentDetails/services/contentTypes";
import { presetContentMap } from "DAI/ContentDetails/services/presets";
import { FEATURES } from "DAI/Subscription/services/plans";
import { getUserPlan } from "DAI/Subscription/services/utils";
import { selectFileType, selectPreset } from "DAI/ContentDetails/store/contentSlice";
import { isContentReady } from "DAI/ProcessedData/store/writtenContentSlice";
import { audiogramSelector } from "store/audiogramSlice";
import { videoReelsSelector } from "store/dai/videoreelSlice";
import { userInfoSelector } from "store/user";
import axios from "utils/axiosConfig";
import { analytics } from "utils/GTM";
import { isFeatureAvailable } from "utils/subscriptionChecks";

const getContentType = (planCode, tabId, preset, fileType) => {
  const userPlan = getUserPlan(planCode || "FREE");
  const defaultTab = getDefaultTab(userPlan.name, preset, fileType || "");
  const defaultTabValue = defaultTab.tabValue;
  const contentType = (tabId !== null)
    ? presetContentMap[preset]?.[tabId]
    : presetContentMap[preset]?.[defaultTabValue];
  const name = contentType ? CONTENT_TYPES[contentType.toUpperCase()].name : "";

  return name;
};

export default function OutputRatingContainer() {
  const userInfo = useSelector(userInfoSelector);
  const preset = useSelector(selectPreset);
  const fileType = useSelector(selectFileType);
  const audiograms = useSelector(audiogramSelector);
  const reels = useSelector(videoReelsSelector);
  const [rating, setRating] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { id } = useParams();
  const [queryParams] = useSearchParams();
  const contentType = getContentType(userInfo.plan, queryParams.get("tab"), preset, fileType);
  const isContentAvailable = useSelector(isContentReady(contentType));

  const potentiallyLockedItems = [
    CONTENT_TYPES.AUDIOGRAMS.name,
    CONTENT_TYPES.FOCUS_KEYWORDS.name,
    CONTENT_TYPES.REELS.name,
    CONTENT_TYPES.TITLE_SUGGESTIONS.name,
  ];
  const lockedContent = {
    [CONTENT_TYPES.AUDIOGRAMS.name]: (audiograms === null),
    [CONTENT_TYPES.REELS.name]: (reels === null),
    [CONTENT_TYPES.TITLE_SUGGESTIONS.name]: isFeatureAvailable(FEATURES.titleSuggestions, userInfo.plan) === false,
    [CONTENT_TYPES.FOCUS_KEYWORDS.name]: isFeatureAvailable(FEATURES.focusKeywords, userInfo.plan) === false,
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const textarea = event.currentTarget.querySelector("textarea");

    if (textarea) {
      analytics.track("Content Feedback", {
        category: contentType,
        feedback: textarea.value,
        fileId: id,
        rating,
      });

      if (process.env.REACT_APP_ENV === "production") {
        if (rating) {
          try {
            await axios({
              method: "POST",
              url: `/content-rating/${id}`,
              data: {
                user: {
                  name: `${userInfo.firstName} ${userInfo.lastName}`,
                  email: userInfo.email,
                  plan: userInfo.plan,
                },

                content: {
                  rating,
                  category: contentType,
                  feedback: textarea.value,
                },
              },
            });
          } catch (e) {
            // noop
          }
        }
      }

      setIsSubmitted(true);
    }
  };

  useEffect(() => {
    setRating();
    setIsSubmitted(false);
  }, [queryParams]);

  if (potentiallyLockedItems.indexOf(contentType) > -1) {
    if (lockedContent[contentType]) {
      return null;
    }
  } else if (isContentAvailable === false) {
    return null;
  }

  return (
    <OutputRating
      contentType={contentType}
      isComplete={isSubmitted}
      onRatingChanged={setRating}
      onSubmit={onSubmit}
    />
  );
}
