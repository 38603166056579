import theme from "theme";

const styles = {
  root: {
    fontFamily: "Satoshi",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "32px",
    textAlign: "left",
    listStyleType: "none",
    color: theme.palette.gray[500],
    transition: "color 0.3s ease",
    minWidth: "200px",
  },
  active: {
    color: theme.palette.gray[800],
  },
  bar: {
    height: "24px",
    width: "4px",
    background: "#D0D5DD",
    borderRadius: "10px",
  },
  activeBar: {
    background: "#6552F3",
  },
};

export default styles;
