import * as clipboard from "clipboard-polyfill";
import { stripHTML } from "DAI/helpers";

/**
 * Copy HTML as rich text to clipboard
 *
 * @param {string} html HTML string to copy
 * @returns {Promise<void>}
 */
export async function copyHTMLToClipboard(html) {
  const plainText = stripHTML(html);

  const clipboardItems = new clipboard.ClipboardItem({
    "text/html": new Blob([html], { type: "text/html" }),
    "text/plain": new Blob([plainText], { type: "text/plain" }),
  });

  await clipboard.write([clipboardItems]);
}

/**
 * Copy plain text to clipboard
 *
 * @param {string} plain text string to copy
 * @returns {Promise<void>}
 */
export async function copyPlainTextToClipboard(text) {
  const clipboardItems = new clipboard.ClipboardItem({
    "text/plain": new Blob([text], { type: "text/plain" }),
  });

  await clipboard.write([clipboardItems]);
}
