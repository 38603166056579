const styles = {
  root: {
    borderRadius: "24px",
    width: "50%",
  },
  wraper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    pb: "47px",
  },
  main: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "24px",
    color: "#23262F",
    pb: "19px",
  },
  tag: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#777E91",
    maxWidth: "80%",
    textAlign: "center",
  },
  outlineBtn: {
    height: "48px",
    width: "160px",
    borderRadius: "999px",
    border: "1px solid #6552F3",
    color: "#6552F3",
    fontWeight: 600,
    lineHeight: "24px",
    fontSize: "16px",
    fontFamily: "Inter",
  },
  btnWraper: {
    display: "flex",
    gap: "30px",
    pt: "49px",
  },
  close: {
    position: "absolute",
    top: "28px",
    right: "28px",
    cursor: "pointer",
  },
};

export default styles;
