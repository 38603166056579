import { Box, Typography } from "@mui/material";
import playIcn from "assets/icons/playicn.svg";

export default function BannerReels() {
  return (
    <Box sx={styles.root}>
      <img src={playIcn} alt="play" />
      <Typography sx={styles.text}>
        Deciphr selects clips based on the most captivating and shareable parts
        of your video, ensuring every moment shines in the spotlight.
      </Typography>
    </Box>
  );
}

const styles = {
  root: {
    p: "20px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    borderRadius: "16px",
    bgcolor: "#F6F5FF",
    mb: "24px",
  },
  text: {
    color: "#333F4E",
    fontFamily: "DM Sans",
    fontSize: "16px",
    fontWeight: 500,
  },
};
