import PropType from "prop-types";
import { Grid, Stack } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DAIButton from "Core/components/DAIButton";
import { ReactComponent as AISparkle } from "assets/content-details/ai-assistant.svg";

const styles = {
  root: {
    width: "100%",
  },
  section: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "18px 43px 21px 33px",
    borderBottom: "1px solid #E3E6EA",
    borderTop: "1px solid #E3E6EA",
    background: "white",
    scrollBehavior: "smooth",

    "&:first-child": {
      borderRight: "1px solid #E3E6EA",
    },
  },
  backBtn: {
    height: "41px",
    width: "41px",

    svg: {
      height: "20px",
      width: "auto",
    },
  },
  closeBtn: {
    height: "24px",
    width: "24px",
    padding: 0,
    margin: 0,
    color: "#09244B",

    svg: {
      height: "24px",
      width: "auto",
    },
  },
  chatHeader: {
    fontFamily: "Satoshi",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "32px",
    letterSpacing: "-0.01em",
    textAlign: "left",

    svg: {
      height: "24px",
    },
  },
};

export default function Header({
  onClickBack,
  onClose,
}) {
  return (
    <Grid
      container
      sx={styles.root}
    >
      <Grid
        item
        xs={6}
        sx={styles.section}
      >
        <DAIButton
          sx={styles.backBtn}
          variant="outlined"
          iconButton
          onClick={onClickBack}
        >
          <ArrowBackRoundedIcon />
        </DAIButton>
      </Grid>

      <Grid
        item
        alignItems="center"
        justifyContent="space-between"
        xs={6}
        sx={styles.section}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap="14px"
          sx={styles.chatHeader}
        >
          <AISparkle />

          Chat
        </Stack>

        <DAIButton
          iconButton
          variant="text"
          sx={styles.closeBtn}
          onClick={onClose}
        >
          <CloseRoundedIcon />
        </DAIButton>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  onClickBack: PropType.func,
  onClose: PropType.func,
};

Header.defaultProps = {
  onClickBack: () => { },
  onClose: () => { },
};
