import PropType from "prop-types";
import { Box } from "@mui/material";

const styles = {
  root: {
    // padding: "36px 114px",
    paddingTop: "36px",
    background: "white",
    borderRadius: "1rem",
    minHeight: "50vh",
  },
};

export default function Body({ children }) {
  return (
    <Box sx={styles.root}>
      {children}
    </Box>
  );
}

Body.propTypes = {
  children: PropType.oneOf([PropType.element, PropType.bool]),
};

Body.defaultProps = {
  children: false,
};
