/**
* 1. Modal title, optional [x]
* 2. Modal body with child [x]
* 3. Modal footer, optional [x]
* 4. Modal type = alert, confirm, general
* 5. Modal maxWidth, minWidth, size etc.
* 6. On close callback
*/
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";

export default function ModalDialog({
  sx,
  open,
  maxWidth,
  fullScreen,
  blurBackdrop,
  children,
  onClose,
}) {
  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      sx={[
        {
          "& .MuiPaper-root": {
            p: "0 40px 40px",
            borderRadius: "24px",
            overflowX: "hidden",

            "@media screen and (max-width: 767px)": {
              padding: "0 20px 28px",
            },

            ...sx,
          },

          "& .MuiModal-backdrop": {
            backdropFilter: blurBackdrop ? "blur(4.8px)" : "none",
            background: "rgba(0, 0, 0, 0.48)",
          },
        },
      ]}
      onClose={onClose}
    >
      {children}
    </Dialog>
  );
}

ModalDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  fullScreen: PropTypes.bool,
  blurBackdrop: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

ModalDialog.defaultProps = {
  sx: {},
  maxWidth: "lg",
  fullScreen: false,
  blurBackdrop: false,
};
