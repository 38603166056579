import { useEffect } from "react";
import { analytics } from "./GTM";
import { routesMap } from "./routesMap";

export const initServices = () => {
  /**
   *
   * https://developers.intercom.com/installing-intercom/docs/intercom-javascript
   */
  const options = {
    api_base: process.env.REACT_APP_INTERCOM_API_BASE,
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
  };
  window.Intercom("boot", options);

  /**
   * Amplitude
   * https://www.docs.developers.amplitude.com/data/sdks/marketing-analytics-browser/
   *
   */
  analytics.init();
};

/**
 * update Intercom on every page change
 */
export const updateIntercom = () => {
  window.Intercom("update");
};

/**
 * Hide intercom bubble on mounting the page and
 * showing it again on page change
 */
export const useHideIntercomBubbleInPage = () => {
  useEffect(() => {
    const bubbleEl = document.querySelector("#intercom-container");

    if (bubbleEl) {
      bubbleEl.style.display = "none";
    }

    return () => {
      if (bubbleEl) {
        bubbleEl.style.display = "block";
      }
    };
  }, []);
};

/**
 * This function is used to update the user data on Intercom
 * @param {*object} param0
 * @returns void
 *
 */
export const updateIntercomUserData = ({
  email,
  firstName,
  plan,
  audioUsed,
  audioTotal,
  companyName,
  lastName,
  transcriptUsed,
  transcriptTotal,
  regenRemaining,
}) => {
  // get user Id and isEmailVerified from local storage
  const { user } = JSON.parse(localStorage.getItem("userInfo")) || {};
  const { emailVerified, localId } = user || {};

  if (email && localId) {
    // calculate audio usage left
    const audioUsageLeft = audioTotal - audioUsed;
    const transcriptUsageLeft = transcriptTotal - transcriptUsed;

    // update user data on Intercom
    window.Intercom("update", {
      user_id: localId,
      email,
      name: firstName,
      "Subscribed Plan": plan || "",
      plan: plan || "",
      "First Name": firstName || "",
      "Last Name": lastName || "",
      "Company name": companyName || "",
      "Audio Usage Left from Plan": audioUsageLeft,
      "Audio Usage Left from Plan - Document Uploads": transcriptUsageLeft,
      "AI Assistant Regenerates Left": regenRemaining,
      email_verified: emailVerified,
    });
  }
};

/**
 *
 * @param {*string} pathname
 * @param {*string} search
 * @returns string
 *
 * This function is used to get the page name for analytics
 *
 */
const pathName = (pathname, search) => {
  let path = pathname.split("/")[1];

  if (path === "auth") {
    // eslint-disable-next-line prefer-destructuring
    path = pathname.split("/")[2];
  }

  if (path === "password-reset") {
    if (search.includes("resetPassword")) {
      path = routesMap["password-reset"].resetPassword;
    } else if (search.includes("verifyEmail")) {
      path = routesMap["password-reset"].verifyEmail;
    }

    return path;
  }

  const routeName = routesMap[path] || "page view";

  if (typeof routeName === "object") {
    const routeSubName = routeName[search];

    return routeSubName;
  }

  return routeName;
};

function getUrlParam(name, search) {
  // eslint-disable-next-line no-useless-escape
  const pathname = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp(`[\\?&]${pathname}=([^&#]*)`);
  const results = regex.exec(search);

  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

/**
 * @param {*string} path
 * @param {*string} search
 * This function is used to track the page view
 * and send the page name to analytics
 *
 */
export const analyticsUpdate = (path, search) => {
  const analyticsPageName = pathName(path, search);
  analytics.track(analyticsPageName, {
    "content type": "page view",
    url: path + search,
    source: getUrlParam("source", search),
  });
};
