import { lazy, Suspense, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BlockNoteEditor } from "@blocknote/core";
import { chunk } from "lodash";
import { copyPlainTextToClipboard } from "DAI/helpers/clipboard";
import ContentHeading from "DAI/ContentDetails/containers/ContentHeading";
import { ContentWrapperBody } from "DAI/ProcessedData/containers/ContentWrapper";
import GeneratingOverlay from "DAI/ProcessedData/components/MediaBlockOverlays/generatingOverlay";
import { selectProcessed } from "DAI/ContentDetails/store/contentSlice";
import { selectContent } from "DAI/ProcessedData/store/writtenContentSlice";
import { getSubscriptionPlan } from "store/user";

const MarkdownEditor = lazy(() => import("DAI/MarkdownEditor/components/Editor"));

export default function ChaptersContainer({ mode }) {
  const [query] = useSearchParams();
  const tab = query.get("tab");
  const chapters = useSelector(selectContent(tab));
  const userPlan = useSelector(getSubscriptionPlan);
  const processed = useSelector(selectProcessed);
  const [blocks, setBlocks] = useState([]);
  const initialized = useRef(false);
  const editor = BlockNoteEditor.create();

  const handleCopy = async (e, editorInstance) => {
    const selection = editorInstance.getSelection();
    const htmlString = await editorInstance.blocksToHTMLLossy(selection.blocks);
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    const cells = doc.querySelectorAll("td");

    const texts = [];
    cells.forEach((cell) => {
      if (cell.innerText) {
        texts.push(cell.innerText);
      }
    });
    const rows = chunk(texts, 2);
    const textToCopy = rows.reduce(
      (text, [timestamp, summary]) => `${text}${timestamp} ${summary}\n`,
      "",
    );
    copyPlainTextToClipboard(textToCopy);

    e.preventDefault();
  };

  useEffect(() => {
    const isInitialized = initialized.current;

    if (chapters && isInitialized === false) {
      const getBlocks = async () => {
        try {
          const content = await editor.tryParseMarkdownToBlocks(chapters);

          initialized.current = true;

          setBlocks(content);
        } catch (e) {
          //
        }
      };

      initialized.current = true;

      getBlocks();
    }
  }, [editor, chapters]);

  return (
    <>
      <ContentHeading
        title="Chapters"
        show={mode === "preview"}
        options={{ userPlan }}
      />

      <ContentWrapperBody>
        <GeneratingOverlay
          show={processed === "N" && chapters === null}
          type="Chapters"
        />

        <GeneratingOverlay
          show={processed === null && chapters === null}
          prefix="Loading"
          type="Chapters"
        />

        {chapters !== null && chapters !== "" && blocks.length > 0 && (
          <Suspense fallback={<div />}>
            <MarkdownEditor
              initialContent={blocks}
              contentType="chapters"
              onCopy={handleCopy}
            />
          </Suspense>
        )}
      </ContentWrapperBody>
    </>
  );
}

ChaptersContainer.propTypes = {
  mode: PropTypes.oneOf(["preview", "viewonly"]),
};

ChaptersContainer.defaultProps = {
  mode: "preview",
};
