import { useEffect, useState } from "react";
import PropType from "prop-types";
import theme from "theme";
import { Box, Skeleton, Typography } from "@mui/material";

const styles = {
  root: {
    fontFamily: "Satoshi",
  },
  title: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "24px",
    letterSpacing: "0.06em",
    textTransform: "uppercase",
    color: theme.palette.gray[600],
  },
  preview: {
    marginTop: "16px",
  },
  seeMore: {
    fontFamily: "Satoshi",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    color: theme.palette.brand[500],
    marginLeft: "8px",
    display: "inline-block",

    "&:hover": {
      cursor: "pointer",
    },
  },
  skeleton: {
    fontSize: "16px",
  },
};

export default function ExpandableText({
  title,
  text,
}) {
  const PREVIEW_LENGTH = 200;
  const [preview, setPreview] = useState();
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const togglePreview = () => {
    console.log("toggleing");

    if (isExpanded) {
      setPreview(text.slice(0, PREVIEW_LENGTH));
      setIsExpanded(false);
    } else {
      setPreview(text);
      setIsExpanded(true);
    }
  };

  useEffect(() => {
    if (text.length <= PREVIEW_LENGTH) {
      setPreview(text);
      setIsPreviewing(false);
    } else {
      setPreview(text.slice(0, PREVIEW_LENGTH));
      setIsPreviewing(true);
    }
  }, [text]);

  return (
    <Box sx={styles.root}>
      <Typography component="h5" sx={styles.title}>
        {title}
      </Typography>

      <Typography sx={styles.preview}>
        {preview || (
          <>
            <Skeleton variant="text" sx={styles.skeleton} />

            <Skeleton variant="text" sx={styles.skeleton} />

            <Skeleton variant="text" sx={styles.skeleton} />
          </>
        )}

        {isPreviewing && !isExpanded && (
          <Box
            component="span"
            sx={styles.seeMore}
            onClick={togglePreview}
          >
            See More
          </Box>
        )}

        {isPreviewing && isExpanded && (
          <Box
            component="span"
            sx={styles.seeMore}
            onClick={togglePreview}
          >
            See Less
          </Box>
        )}
      </Typography>
    </Box>
  );
}

ExpandableText.propTypes = {
  title: PropType.string,
  text: PropType.string,
};

ExpandableText.defaultProps = {
  title: "",
  text: "",
};
