const styles = {
  root: {
    position: "relative",
    height: "100%",
    width: "100%",
    scrollBehavior: "smooth",
  },
  input: {
    width: "100%",
    padding: "0px 35px 42px 30px",
    background: "white",
  },
  endPlaceholder: {
    height: "1px",
    width: "1px",
    overflow: "hidden",
    opacity: 0,
  },
};

export default styles;
