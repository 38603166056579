import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DAIButton from "Core/components/DAIButton";
import DownloadPopup from "DAI/ProcessedData/components/DownloadPopup";
import { downloadAllAsZip, downloadWrittenContent } from "DAI/ContentDetails/services/apis";
import { getContentTypeLabel } from "DAI/ContentDetails/services/presets";
import { selectContentList, selectFileMetadata, selectPreset } from "DAI/ContentDetails/store/contentSlice";
import { setToast } from "store/toastSlice";
import { download, isSafari } from "utils";
import { analytics } from "utils/GTM";

export default function DownloadContainer() {
  const metadata = useSelector(selectFileMetadata);
  const preset = useSelector(selectPreset);
  const contentList = useSelector(selectContentList);
  const [show, setShow] = useState(false);
  const [writtenOptions, setWrittenOptions] = useState([]);
  const [mediaOptions, setMediaOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const togglePopup = () => {
    setShow((visible) => !visible);
  };

  const onWrittenOptionToggle = (value) => {
    const options = [...writtenOptions];
    const index = options.findIndex((option) => option.value === value);

    if (index > -1) {
      options[index].selected = !options[index].selected;

      setWrittenOptions(options);
    }
  };

  const onMediaOptionToggle = (value) => {
    const options = [...mediaOptions];
    const index = options.findIndex((option) => option.value === value);

    if (index > -1) {
      options[index].selected = !options[index].selected;

      setMediaOptions(options);
    }
  };

  const onDownload = async () => {
    const written = writtenOptions.filter((option) => option.selected).map((option) => option.value);
    const media = mediaOptions.filter((option) => option.selected).map((option) => option.value);
    const params = new URLSearchParams();
    const selected = [...written, ...media];
    let tab;

    setIsLoading(true);

    selected.forEach((type) => {
      params.set(type, "1");
    });

    if (params.get("audiogram") === null && params.get("videoreels") === null) {
      // Only written content has been selected
      if (isSafari()) {
        tab = window.open("", Date.now());

        tab.focus();
      }

      try {
        const data = await downloadWrittenContent({ fileId: metadata.fileId, queryParams: params });

        download(data, metadata.fileName, "docx", tab);
        analytics.track("Download", {
          category: "Content",
          label: "DOC",
          selected_Options: selected,
        });
      } catch (e) {
        dispatch(
          setToast({
            message: "Content could not be downloaded. Please retry in a bit.",
            severity: "warning",
          }),
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      // Download everything
      tab = window.open(`${window.location.origin}/download?${params.toString()}`, Date.now());

      tab.focus();

      try {
        const data = await downloadAllAsZip({ fileId: metadata.fileId, params });

        tab.location = data.url;

        analytics.track("Download", {
          category: "Content + media",
          label: "zip",
          selected_Options: selected,
        });

        setTimeout(() => {
          tab.close();
        }, 3000);
      } catch (e) {
        tab.close();
        window.focus();

        dispatch(
          setToast({
            message: "Content could not be downloaded. Please retry in a bit.",
            severity: "warning",
          }),
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (preset && writtenOptions.length === 0) {
      const options = contentList.map((type) => ({
        label: getContentTypeLabel(type),
        value: type,
        selected: true,
      }));

      setWrittenOptions(() => options);
    }
  }, [contentList, preset, writtenOptions]);

  useEffect(() => {
    if (metadata.fileType === "audio") {
      setMediaOptions([
        { label: "Audiograms", value: "audiogram" },
      ]);
    } else if (metadata.fileType === "video") {
      setMediaOptions([
        { label: "Audiograms", value: "audiogram" },
        { label: "Video Reels", value: "videoreels" },
      ]);
    }
  }, [metadata?.fileType]);

  return (
    <>
      <DAIButton
        variant="outlined"
        startIcon={<FileDownloadOutlinedIcon />}
        sx={{
          py: "8px",
          minWidth: "auto",
          borderWidth: "1px",

          "&:hover": {
            borderWidth: "1px",
          },
        }}
        onClick={togglePopup}
      >
        Download
      </DAIButton>

      <DownloadPopup
        open={show}
        isLoading={isLoading}
        writtenOpts={writtenOptions}
        mediaOpts={mediaOptions}
        onWrittenOptionToggle={onWrittenOptionToggle}
        onMediaOptionToggle={onMediaOptionToggle}
        onDownload={onDownload}
        onClose={togglePopup}
      />
    </>
  );
}
