import { Box, Button, Grid, IconButton } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchIcon from "@mui/icons-material/Search";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import {
  setFindText,
  findTextSelector,
  textFoundCountSelector,
  setReplaceText,
  replaceTextSelector,
  replaceAll,
  highlightForward,
  highlightBackward,
  replaceSpecficTrancript,
  displayFindAndReplaceSelector
} from "../../store/transcriptSlice";
import { analytics } from "../../utils/GTM";

const Input = ({ label, icon, value, onChange, placeholder }) => {
  return (
    <OutlinedInput
      id={"outlined-adornment" + label}
      startAdornment={icon}
      aria-describedby={label}
      inputProps={{
        "aria-label": label,
      }}
      sx={{
        width: "100%",
        height: "40px",
        borderRadius: "8px",
        border: "solid 2px #E6E8EC",
        outline: "none",

        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#ffffff3b",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#ffffff3b",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#ffffff3b",
        },

        "& .MuiOutlinedInput-input": {
          fontSize: "14px",
          color: "#23262F",
        },
      }}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};
let timeoutId = null;

export default function FindNdReplace({ display }) {
  const [search, setSearch] = useState("");
  const searchTerm = useSelector(findTextSelector);
  const replace = useSelector(replaceTextSelector);
  const textFoundCount = useSelector(textFoundCountSelector);
  const displayFindAndReplace = useSelector(displayFindAndReplaceSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if(displayFindAndReplace) {
      setSearch(searchTerm);
    }
  }, [displayFindAndReplace, searchTerm]);

  const searchTermHandler = (e) => {
    setSearch(e.target.value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      dispatch(setFindText(e.target.value));
      timeoutId = null;
    }, 500);
  };

  const replaceAllHandler = () => {
    setSearch("");
    dispatch(replaceAll());
    analytics.track("Replaced all transcript");
  };

  return (
    <Box
      sx={{
        borderBottom: "solid 1px #E6E8EC",
        display: display ? "block" : "none",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={6}>
          <Input
            label="Search"
            icon={<SearchIcon sx={{ color: "#777E90", mr: "5px" }} />}
            placeholder="Search"
            value={search}
            onChange={searchTermHandler}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <Input
            label="Replace"
            icon={
              <ModeEditOutlineOutlinedIcon
                sx={{ color: "#777E90", mr: "5px" }}
              />
            }
            placeholder="Replace"
            value={replace}
            onChange={(e) => dispatch(setReplaceText(e.target.value))}
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          py: "32px",
        }}
      >
        <Grid item xs={12} sm={6} lg={6}>
          <Button
            variant="text"
            sx={{
              fontSize: "14px",
              color: "#777E91",
              "&:hover": {
                backgroundColor: "transparent !important",
                boxShadow: "none !important",
                color: "#777E91 !important",
              },
            }}
          >
            {textFoundCount} found
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <Box
            sx={{
              textAlign: "right",
            }}
          >
            <Button
              disabled={
                replace.trim() === "" ||
                searchTerm.trim() === "" ||
                textFoundCount === 0
              }
              variant="text"
              sx={{
                fontSize: "14px",
                color: "#23262F",
                "&:hover": {
                  backgroundColor: "transparent !important",
                  boxShadow: "none !important",
                  color: "#23262F !important",
                },
              }}
              onClick={() => {
                dispatch(replaceSpecficTrancript());
                analytics.track("Replaced Specific Word in Transcript");
              }}
            >
              Replace
            </Button>
            <Button
              disabled={
                replace.trim() === "" ||
                searchTerm.trim() === "" ||
                textFoundCount === 0
              }
              variant="text"
              sx={{
                fontSize: "14px",
                color: "#23262F",
                "&:hover": {
                  backgroundColor: "transparent !important",
                  boxShadow: "none !important",
                  color: "#23262F !important",
                },
              }}
              onClick={replaceAllHandler}
            >
              Replace All
            </Button>
            <IconButton
              onClick={() => dispatch(highlightBackward())}
              size="small"
              disabled={textFoundCount === 0}
            >
              <WestIcon
                sx={{
                  color: textFoundCount === 0 ? "#cccccc" : "#777E91",
                }}
              />
            </IconButton>
            <IconButton
              onClick={() => dispatch(highlightForward())}
              size="small"
              disabled={textFoundCount === 0}
            >
              <EastIcon
                sx={{
                  color: textFoundCount === 0 ? "#cccccc" : "#777E91",
                }}
              />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
