import { Box, IconButton, Input, Modal, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import {
  handCloseModal,
  isShowModalSelector,
  selectedSpeakerNameSelector,
  isEditSpeakerModelSelector,
  addSpeakerAsync,
  updateAllSpeakerAsync,
  speakerIndexSelector,
  speakerUpdateLoadingSelector,
} from "../../store/transcriptSlice";
import { analytics } from "../../utils/GTM";

const style = {
  styleModal: {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 447,
    zIndex: "30",
    paddingTop: "15px",
    paddingBottom: "30px",
    width: "100%",
    background: "#F6F6F6",
    borderRadius: "10px",
    "& .title-modal": {
      fontSize: "32px",
      fontWeight: "700",
      lineHeight: "unset",
      mt: 3,
    },
    "& .iconBtn": {
      position: "absolute",
      top: "10px",
      right: "10px",
      color: "#868686",
    },
  },
  styleInput: {
    width: " 100%",
    borderBottom: "unset",
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
    ".MuiInputBase-input": {
      background: "#FFFFFF",
      border: " 1px solid #DDDDDD",
      borderRadius: "8px",
      height: "40px",
      padding: "0px 19px",
    },
  },
  styleButton: {
    height: "44px",
    borderRadius: "8px",
  },
};

function ModalSpeak() {
  const dispatch = useDispatch();
  const isEditSpeakerModel = useSelector(isEditSpeakerModelSelector);
  const selectedSpeakerName = useSelector(selectedSpeakerNameSelector);
  const isShowModal = useSelector(isShowModalSelector);
  const [value, setValue] = React.useState("");
  const id = useSelector(speakerIndexSelector);
  const isLoading = useSelector(speakerUpdateLoadingSelector);
  const [checked, setChecked] = React.useState(true);

  let placeholder = "";

  if (isEditSpeakerModel) {
    placeholder = selectedSpeakerName;
  } else {
    placeholder = "Enter speaker name";
  }

  const handleClick = () => {
    if (isEditSpeakerModel) {
      dispatch(
        updateAllSpeakerAsync({
          oldSpeaker: selectedSpeakerName,
          newSpeaker: value,
          index: id,
          isall: checked,
        })
      );
      analytics.track(
        `Edited ${checked ? "all speaker name" : "one speaker name"}`
      );
    } else {
      dispatch(
        addSpeakerAsync({
          speaker: value,
        })
      );
      analytics.track("Added speaker");
    }
    setValue("");
  };

  return (
    <Box>
      <Modal
        open={isShowModal}
        onClose={() => dispatch(handCloseModal())}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style.styleModal}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography id="modal-modal-title" className="title-modal">
              Speaker name
            </Typography>
          </Box>
          <IconButton
            onClick={() => dispatch(handCloseModal())}
            className="iconBtn"
          >
            <CloseIcon />
          </IconButton>

          <Box
            id="modal-modal-description"
            sx={{ padding: "27px 41px 0px 33px" }}
          >
            <Input
              sx={style.styleInput}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="input-speak"
              placeholder={placeholder}
            />
            <Box>
              {isEditSpeakerModel && (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setChecked(e.target.checked)}
                        checked={checked}
                        sx={{
                          "&.Mui-checked": {
                            color: "#6552F3",
                          },
                        }}
                      />
                    }
                    label="Apply All"
                  />
                </FormGroup>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "32px",
                gap: "16px",
              }}
            >
              <LoadingButton
                loading={isLoading}
                sx={{
                  borderRadius: "99px",
                  textTransform: "capitalize",
                  height: "44px",
                  background: "#6552F3",
                  flex: "1",
                  color: "#fff",
                  "&:hover": { background: "#6552F3" },
                }}
                onClick={handleClick}
              >
                {isEditSpeakerModel ? "Save" : "Add"}
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default ModalSpeak;
