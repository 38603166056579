import PropTypes from "prop-types";
import DAIButton from "Core/components/DAIButton";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";

const typeToLabelMap = {
  showNotes: "Show Notes",
  abstract: "Summary",
  insights: "Chapters",
  transcript: "Transcript",
  quotes: "Quotes",
  article: "Article",
  tweet: "Tweet Thread",
  caption: "Captions",
  seoKeywords: "SEO Keywords",
  headlines: "Title Suggestions",
};

export default function CopyContent({ type, onCopy }) {
  const onClick = () => {
    onCopy(type);
  };

  return (
    <DAIButton
      variant="text"
      startIcon={<CopyIcon color="#6552F3" />}
      alt={`copy ${typeToLabelMap[type]} to clipboard`}
      title={`Copy ${typeToLabelMap[type]} to clipboard`}
      onClick={onClick}
      sx={{
        p: "4px 16px",
        minWidth: 0,
        fontSize: "16px",
        fontWeight: 500,
      }}
    >
      Copy
    </DAIButton>
  );
}

CopyContent.propTypes = {
  type: PropTypes.oneOf([
    "showNotes",
    "summary",
    "chapters",
    "transcript",
    "quotes",
    "article",
    "tweetThread",
    "captions",
    "focusKeywords",
    "headline",
    "titleSuggestions",
    "meetingMinutes",
    "actionItems",
    "decisionLog",
    "importantDates",
    "qAndA",
    "nextAgenda",
    "followUpEmails",
    "sessionRecap",
    "whitePaper",
    "faqs",
    "newsletter",
    "landingPage",
  ]).isRequired,
  onCopy: PropTypes.func.isRequired,
};
