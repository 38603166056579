import { lazy, Suspense, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { camelCase, throttle } from "lodash";
import { Helmet } from "react-helmet-async";
import { Box, Container, Grid, Stack } from "@mui/material";
import Header from "DAI/ContentDetails/layouts/ContentDetailsLayout/Header";
import ContentContainer from "DAI/ContentDetails/containers/ContentContainer";
import ContentLinks from "DAI/ContentDetails/containers/ContentLinks";
import FileChat from "DAI/ContentDetails/containers/FileChat";
import HeroContainer, { HeroContainerMode } from "DAI/ContentDetails/containers/HeroContainer";
import HeaderNav from "DAI/Navigation/components/HeaderNav";
import {
  createContent,
} from "DAI/ContentDetails/services/apis";
import {
  getFileDetails,
  isFileProcessed,
  selectFileId,
  selectFileMetadata,
  selectHeadline,
  selectIsLoading,
} from "DAI/ContentDetails/store/contentSlice";
import {
  addCreatedContentType,
  createdContentTypesSelector,
  isWrittenContentInitSelector,
  possibleContentTypesSelector,
  setContent,
  setIsStreaming,
} from "DAI/ProcessedData/store/writtenContentSlice";
import AudioPlayer from "pages/mainTopic/audioplayer";
import { setToast } from "store/toastSlice";
import { setFindAndReplace } from "store/transcriptSlice";
import { useHideIntercomBubbleInPage } from "utils/services";
import styles from "./styles";

const SubscriptionCheck = lazy(() => import("DAI/ContentDetails/containers/SubscriptionCheck"));

const FETCH_INTERVAL = 15 * 1000; // 15 seconds

const PageLayout = {
  Base: "Base",
  Chat: "Chat",
};

export default function RevampedContentDetailsPage() {
  const metadata = useSelector(selectFileMetadata);
  const fileId = useSelector(selectFileId);
  const headline = useSelector(selectHeadline);
  const isLoading = useSelector(selectIsLoading);
  const isProcessed = useSelector(isFileProcessed);
  const createdContentTypes = useSelector(createdContentTypesSelector);
  const possibleContentTypes = useSelector(possibleContentTypesSelector);
  const isWrittenContentInit = useSelector(isWrittenContentInitSelector);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get("tab");
  const [layout, setLayout] = useState(PageLayout.Base);
  const headerRef = useRef(null);
  const contentRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useHideIntercomBubbleInPage();

  const handleOpenChat = () => {
    setLayout(PageLayout.Chat);
  };

  const handleCloseChat = () => {
    setLayout(PageLayout.Base);
  };

  const goBack = () => {
    navigate("/library");
  };

  const getDimensions = (elementRef) => {
    const { width, height } = elementRef.current.getBoundingClientRect();

    return {
      width,
      height,
    };
  };

  const updateCreatedContentType = useCallback(
    throttle(
      (contentType, value) => {
        dispatch(setContent({
          contentType,
          value,
        }));
      },
      300,
    ),
    [],
  );

  const handleCreateContentFlow = async (label, type) => {
    try {
      dispatch(setIsStreaming(true));
      const res = await createContent({
        fileId,
        type,
      });
      const transformedType = camelCase(type);

      dispatch(
        addCreatedContentType({
          label,
          value: type,
        }),
      );

      const reader = res.body.getReader();
      let chunk = await reader.read();
      let content = "";

      while (!chunk?.done && chunk?.value) {
        const textChunk = new TextDecoder().decode(chunk.value);
        content += textChunk;
        updateCreatedContentType(transformedType, content);

        // eslint-disable-next-line no-await-in-loop
        chunk = await reader.read();
      }

      dispatch(setIsStreaming(false));
    } catch (error) {
      setToast({
        message: "Error creating content",
        severity: "error",
      });
      dispatch(setIsStreaming(false));
    }
  };

  const useHandleCreateFlow = () => {
    useEffect(() => {
      if (!isWrittenContentInit) {
        return () => { };
      }

      const typeToCreate = searchParams.get("create");
      const label = searchParams.get("create-label");

      if (!typeToCreate || !createdContentTypes?.length || !fileId) {
        return () => { };
      }

      const isAlreadyCreated = createdContentTypes.find(
        (item) => item.value === typeToCreate,
      );

      if (isAlreadyCreated) {
        navigate(`/content/${fileId}?tab=${camelCase(typeToCreate)}`);

        return () => { };
      }

      if (currentTab) {
        handleCreateContentFlow(label, typeToCreate);
      } else {
        navigate(
          `/content/${fileId}?tab=${camelCase(typeToCreate)}&create=${typeToCreate}&create-label=${label}`,
        );
      }

      return () => { };
    }, [
      searchParams,
      createdContentTypes,
      possibleContentTypes,
      fileId,
      isWrittenContentInit,
    ]);
  };

  useHandleCreateFlow();

  useEffect(() => {
    if (!fileId) {
      dispatch(getFileDetails({ fileId: params.id, isRecheck: false }));
    }
  }, [fileId, params.id, dispatch]);

  // Reset find/replace state for transcripts in store
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(setFindAndReplace(false));
    };
  }, [dispatch, searchParams]);

  useEffect(() => {
    let interval;

    if (fileId) {
      if (isProcessed === false) {
        // Try to keep fetching data at intervals of 30 seconds
        // This will update pending content when it becomes available
        interval = setInterval(() => {
          dispatch(getFileDetails({ fileId, isRecheck: true }));
        }, FETCH_INTERVAL);
      } else {
        clearInterval(interval);
      }
    }

    return () => {
      clearInterval(interval);
    };
  }, [fileId, isProcessed, dispatch]);

  useEffect(() => {
    if (!headerRef.current || !contentRef.current) {
      return;
    }

    const windowHeight = window.innerHeight;
    const headerDimensions = getDimensions(headerRef);
    const height = windowHeight - headerDimensions.height;

    contentRef.current.style.height = `${height}px`;
  }, [layout]);

  return (
    <Box sx={styles.root}>
      <Helmet>
        <title>{headline ? `${headline} | Deciphr AI` : "Deciphr AI"}</title>
      </Helmet>

      <Suspense fallback={<div />}>
        <SubscriptionCheck />
      </Suspense>

      {layout === PageLayout.Base ? (
        <>
          <Box sx={{ height: "90px" }}>
            <HeaderNav showLogo />
          </Box>

          <Box
            sx={styles.baseHero}
          >
            <HeroContainer
              onClickChat={handleOpenChat}
            />
          </Box>

          <Container sx={styles.content} maxWidth="lg">
            <Stack
              direction="row"
              gap="77px"
            >
              <ContentLinks currentTab={currentTab} isLoading={isLoading} />

              <Box sx={styles.rightSide}>
                <ContentContainer tabId={currentTab} />

                <AudioPlayer
                  display={
                    currentTab === "transcript"
                    && (metadata.fileType === "audio" || metadata.fileType === "video")
                  }
                />
              </Box>
            </Stack>

          </Container>
        </>
      ) : (
        <Box>
          <Box ref={headerRef}>
            <Box sx={{ height: "90px" }}>
              <HeaderNav showLogo />
            </Box>

            <Header
              onClickBack={goBack}
              onClose={handleCloseChat}
            />
          </Box>

          <Grid container ref={contentRef}>
            <Grid item xs={6} sx={styles.splitSection}>
              <Box sx={styles.chatHero}>
                <HeroContainer
                  mode={HeroContainerMode.Chat}
                  onClickChat={handleOpenChat}
                />
              </Box>

              <Box sx={styles.chatModeContentSection}>
                <ContentContainer tabId={currentTab} />
              </Box>

              <AudioPlayer
                display={
                  currentTab === "transcript"
                  && (metadata.fileType === "audio" || metadata.fileType === "video")
                }
              />

            </Grid>

            <Grid item xs={6} sx={styles.splitSection}>
              <FileChat />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}
