export const styles = {
  root: {
    display: "flex",
  },
  controls: {
    position: "absolute",
    bottom: "10px",
    background: "#333333",
    borderRadius: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 0px",
    mx: 1,
    height: "40px",
  },
  controlFullWidth: {
    width: "95%",
  },
  controlHalfWidth: {
    width: "75%",
    left: 0,
    transform: "translateY(-15%)",
  },
  paper: {
    borderRadius: "24px",
  },
  wrapper: {
    flex: "1 1 50%",
    aspectRatio: "1",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#000",
    position: "relative",
  },
  playerWrapper: {
    height: "600px",
  },
  downloadButton: {
    width: "128px",
    minWidth: 0,
    position: "absolute",
    bottom: "17px",
    right: "5px",
  },
  progressbarWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
  },
  progress: {
    height: "40px",
    width: "100%",
    opacity: 0.1,
    position: "absolute",
    bottom: -14,
    zIndex: 1,
    cursor: "pointer",
  },
  textWrapper: {
    position: "absolute",
    top: "52%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    overflow: "hidden",
    width: "50%",
  },
  text: {
    fontSize: "1.7rem",
    fontWeight: "bold",
    lineHeight: "1.2",
    fontFamily: "HelveticaBold",
    color: "#000",
    // "-webkit-text-stroke-width": "1px",
    // "-webkit-text-stroke-color": "#7060FF",
  },
  brandedTextWrapper: {
    position: "absolute",
    top: "90%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    overflow: "hidden",
    width: "50%",
  },
  brandedText: {
    fontSize: "1rem",
    fontWeight: "bold",
    lineHeight: "1.2",
    fontFamily: "HelveticaBold",
    color: "#fff",
    // "-webkit-text-stroke-width": "1px",
    // "-webkit-text-stroke-color": "#7060FF",
  },
};



export const covertTextPositioning = {
  8: {
    top: "13%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  7:{
    top: "13%",
    left: "45%",
    transform: "translate(-50%, -50%)",
  },
  9:{
    top: "13%",
    left: "55%",
    transform: "translate(-50%, -50%)",
  },
  5: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  4:{
    top: "50%",
    left: "45%",
    transform: "translate(-50%, -50%)",
  },
  6:{
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
  },
  2: {
    top: "85%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  1:{
    top: "85%",
    left: "45%",
    transform: "translate(-50%, -50%)",
  },
  3:{
    top: "85%",
    left: "55%",
    transform: "translate(-50%, -50%)",
  },
};

export const convertFontSize = {
  small: "1.2rem",
  mid: "1.7rem",
  large: "2.2rem",
};