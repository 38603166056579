/** @jsxImportSource @emotion/react */
import { Box, Button } from "@mui/material";
import GeneratingOverlay from "DAI/ProcessedData/components/MediaBlockOverlays/generatingOverlay";
import EditTranscript from "./editTranscript";
import downArrow from "assets/shape.svg";

const styles = {
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
};

/**
 * @param {Object} props
 * @param {({timestamp?: string; speaker?: string; text?: string}[] | null)} [props.trans]
 * @param {(timestamp?: string) => void} [props.handleTimestamps]
 * @param {(index?: number, e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void} [props.onClick]
 * @param {(text?: string, index?: number) => void} [props.saveHandler]
 */
function RenderTranscript({
  trans,
  handleTimestamps,
  onClick,
  saveHandler,
  highlightText,
}) {
  if (!trans) {
    return <GeneratingOverlay show type="transcript" />;
  }

  return (
    <Box sx={styles.root}>
      {trans.map((item, idx) => (
        <Box
          key={item.timestamp}
          sx={{
            pb: "16px",
            borderBottom: "solid 1px #E6E8EC",
            pt: "18px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              gap: "8px",
            }}
          >
            <Box
              sx={{
                height: "min-content",
                padding: "4px 8px",
                borderRadius: "24px",
                background: "#23262F",
                color: "#fff",
                fontWeight: "600",
                fontSize: "12px",
                cursor: "pointer",
                pointerEvents: handleTimestamps ? "auto" : "none",
              }}
              onClick={() => handleTimestamps && handleTimestamps(item?.timestamp || null)}
            >
              {item?.timestamp}
            </Box>

            <Button
              endIcon={onClick ? <img src={downArrow} alt="" /> : undefined}
              onClick={(e) => onClick && onClick(idx, e)}
              sx={{
                minWidth: "auto",
                p: "2px 16px",
                bgcolor: "#E6E8EC",
                color: "#000",
                borderRadius: "999px",
                fontSize: "12px",
                fontWeight: 600,
                cursor: "pointer",
                pointerEvents: onClick ? "auto" : "none",

                "&:hover": {
                  bgcolor: "#B1B5C3 !important",
                  boxShadow: "none",
                },
              }}
            >
              {item?.speaker}
            </Button>
          </Box>

          <Box
            sx={{
              pt: "16px",
            }}
          >
            <EditTranscript
              transcriptText={item?.text}
              tabIndex={idx}
              save={saveHandler}
              highlightText={highlightText}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default RenderTranscript;
