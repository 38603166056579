import PropTypes from "prop-types";
import Box from "@mui/material/Box";

export default function ModalBody({ children }) {
  return (
    <Box
      sx={{
        width: "100%",
        py: "32px",
      }}
    >
      {children}
    </Box>
  );
}

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
};
