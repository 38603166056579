import { useEffect } from "react";
import { Provider } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  ErrorBoundary,
  historyContext,
  Provider as RollbarProvider,
} from "@rollbar/react";
import { createBrowserHistory } from "history";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { rollbar } from "Core/helpers/rollbar";
import DeciphrRoutes from "routes";
import store from "store";
import { history } from "utils/history";
import { analyticsUpdate, initServices, updateIntercom } from "utils/services";

const Analytics = {};
const browserHistory = createBrowserHistory();

// Lazy load to reduce the main bundle size
async function importAnalytics() {
  try {
    const module = await import("utils/services");

    Analytics.initServices = module.initServices;
    Analytics.updateIntercom = module.updateIntercom;
    Analytics.analyticsUpdate = module.analyticsUpdate;
  } catch (e) {
    //
  }
}

function App() {
  history.navigate = useNavigate();
  history.location = useLocation();

  const path = history.location.pathname;
  const { search } = history.location;

  useEffect(() => {
    initServices();
  }, []);

  useEffect(() => {
    const initialize = async () => {
      await importAnalytics();

      updateIntercom();
      analyticsUpdate(path, search);
    };

    initialize();
  }, [path, search]);

  useEffect(() => {
    const unlisten = browserHistory.listen(historyContext(rollbar));

    return () => {
      if (unlisten) {
        unlisten();
      }
    };
  }, []);

  return (
    <Provider store={store}>
      <HelmetProvider>
        <Helmet>
          <title>Deciphr AI</title>
        </Helmet>

        <RollbarProvider instance={rollbar}>
          <ErrorBoundary>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
              <DeciphrRoutes />
            </GoogleOAuthProvider>
          </ErrorBoundary>
        </RollbarProvider>
      </HelmetProvider>
    </Provider>
  );
}

export default App;
