import { Typography } from "@mui/material";
import { formatTime } from "./util/timeformat";

const TimeFormat = ({ start, end, time, isEdit }) => {
  return (
    <Typography variant="body2" sx={{ color: "#fff" }}>
      {!isEdit ? formatTime(time) : formatTime(time - start / 1000)}/{" "}
      {formatTime((end - start) / 1000)}
    </Typography>
  );
};

export default TimeFormat;
