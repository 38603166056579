import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DAIButton from "Core/components/DAIButton";
import { setToast } from "store/toastSlice";
import { analytics } from "utils/GTM";

export default function ShareDetail({ show, shareId }) {
  const dispatch = useDispatch();

  const onCopy = () => {
    dispatch(
      setToast({
        message: "URL copied to clipboard!",
        severity: "success",
        autoClose: true,
      }),
    );

    analytics.track("Copied Shared Url");
  };

  const onClick = () => {
    analytics.track("Opened Shared Url in New Tab", {
      url: `${process.env.REACT_APP_SHARE_URL}/${shareId}`,
    });

    window.open(`${process.env.REACT_APP_SHARE_URL}/${shareId}`, "_blank");
  };

  if (show) {
    return (
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: "12px" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <TextField
            id="outlined-controlled"
            onCopy={onCopy}
            sx={{
              borderRadius: "100px",
              backgroundColor: "#eee",
              flex: 1,
              ml: 2,

              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },

              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              },
            }}
            value={`${process.env.REACT_APP_SHARE_URL}/${shareId}`}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <CopyToClipboard
                  text={`${process.env.REACT_APP_SHARE_URL}/${shareId}`}
                  onCopy={onCopy}
                >
                  <IconButton>
                    <ContentCopyIcon sx={{ color: "#000" }} />
                  </IconButton>
                </CopyToClipboard>
              ),
            }}
          />
        </Box>

        <DAIButton ariaLabel="View Shared Page" title="View Shared Page" onClick={onClick}>
          View
        </DAIButton>
      </Box>
    );
  }
}

ShareDetail.propTypes = {
  show: PropTypes.bool.isRequired,
  shareId: PropTypes.string,
};

ShareDetail.defaultProps = {
  shareId: null,
};
