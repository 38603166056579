import { useEffect, useRef, useState } from "react";
import PropType from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import PlayPauseButtons from "DAI/ProcessedData/components/AudiogramComponents/audiogramPlayer/playPauseButtns";
import Progressbar from "DAI/ProcessedData/components/AudiogramComponents/audiogramPlayer/progressbar";
import VolumBtn from "DAI/ProcessedData/components/AudiogramComponents/audiogramPlayer/volumBtn";
import CancelButton from "DAI/ProcessedData/components/videoReels/cancelBtn";
import TimeFormat from "DAI/ProcessedData/components/videoReels/playerComp/timeFormat";
import { selectedVideoSelector, setSelectedVideo } from "store/dai/videoreelSlice";
import { analytics } from "utils/GTM";
import { styles } from "./styles";

export default function VideoReelPlayer({
  download,
  open,
  close,
}) {
  const selectedUrlPayload = useSelector(selectedVideoSelector);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setMute] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [time, setTime] = useState(0);
  const [length, setLength] = useState(0);
  const [lagloader, setLagloader] = useState(false);
  const [progress, setProgress] = useState(0);
  const player = useRef(null);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setSelectedVideo(null));
    setLength(0);
    setProgress(0);
    setTime(0);
    setMute(false);
    setIsPlaying(false);
    close();
    analytics.track("Video Reel Modal closed");
  };

  useEffect(() => {
    if (selectedUrlPayload?.video_url) {
      setVideoUrl(`${selectedUrlPayload.video_url}?v=${new Date().getTime()}`);
    }
  }, [selectedUrlPayload]);

  const handlePlayClick = () => {
    setIsPlaying(!isPlaying);
  };

  const handleMute = () => {
    setMute(!isMuted);
  };

  const handleProgressChange = (currProgress) => {
    const newProgress = (currProgress / 100) * length;
    player.current.seekTo(newProgress, "seconds");
    setProgress(currProgress);
  };

  const handleTimeUpdate = ({ playedSeconds }) => {
    setTime(playedSeconds);
    setProgress((playedSeconds / length) * 100);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { borderRadius: "24px" } }}
      maxWidth="sm"
    >
      <Box sx={styles.root}>
        <Box sx={styles.wrapper}>
          <Box sx={styles.playerWrapper}>
            <ReactPlayer
              url={videoUrl}
              ref={player}
              loop
              width="100%"
              height="100%"
              muted={isMuted}
              playing={isPlaying}
              onProgress={handleTimeUpdate}
              onDuration={setLength}
              onBuffer={() => setLagloader(true)}
              onBufferEnd={() => setLagloader(false)}
            />
          </Box>

          <CancelButton
            handleClose={handleClose}
            show
            color="#fff"
            showRoundIcon
          />

          <Box sx={{ ...styles.controls, ...styles.controlHalfWidth }}>
            <PlayPauseButtons
              isPlaying={isPlaying}
              handlePlayClick={handlePlayClick}
              lagloader={lagloader}
            />

            <Box sx={styles.progressbarWrapper}>
              <TimeFormat time={time} length={length} />

              <Progressbar
                progress={progress}
                handleProgressChange={handleProgressChange}
                sx={styles.progress}
              />
            </Box>

            <VolumBtn isMuted={isMuted} handleMute={handleMute} />
          </Box>

          <Button
            variant="contained"
            disableElevation
            sx={styles.downloadButton}
            onClick={() => download(selectedUrlPayload)}
          >
            Download
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

VideoReelPlayer.propTypes = {
  download: PropType.func,
  open: PropType.bool,
  close: PropType.func,
};

VideoReelPlayer.defaultProps = {
  download: () => { },
  open: false,
  close: () => { },
};
