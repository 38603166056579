import PropTypes from "prop-types";
import theme from "theme";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function ToggleOptions({ options, onToggle }) {
  if (options.length === 0) {
    return (
      <div
        role="alert"
        style={{
          fontFamily: "Satoshi",
          fontSize: "16px",
          fontWeight: 700,
          marginTop: "28px",
          marginBottom: "28px",
        }}
      >
        Not available
      </div>
    );
  }

  return (
    <>
      {options.map((option, index) => (
        <FormControlLabel
          control={(
            <Checkbox
              checked={option.selected || false}
              sx={{ mr: "24px" }}
            />
          )}
          label={option.label}
          value={option.value || ""}
          key={index}
          sx={{
            display: "block",
            ml: 0,
            my: "28px",

            "& .MuiTypography-root": {
              color: theme.palette.gray[700],
              fontFamily: "Satoshi",
              fontSize: "16px",
              fontWeight: 700,
            },
          }}
          onChange={(e) => onToggle(e.target.value)}
        />
      ))}
    </>
  );
}

ToggleOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    value: PropTypes.string.isRequired,
  })).isRequired,
  onToggle: PropTypes.func.isRequired,
};
