import { lazy, Suspense, useEffect, useRef, useState } from "react";
import PropType from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { BlockNoteEditor } from "@blocknote/core";
import { Scrollbars } from "react-custom-scrollbars-2";
import Box from "@mui/material/Box";
import ContentHeading from "DAI/ContentDetails/containers/ContentHeading";
import { ContentWrapperBody } from "DAI/ProcessedData/containers/ContentWrapper";
import {
  displayFindAndReplaceSelector,
  getFileVersion,
  handShowModal,
  isTimestampedSelector,
  rawTextSelector,
  setSelecteTimeStamp,
  transcriptSelector,
  updateSpecficTrancript,
} from "store/transcriptSlice";
import { getSubscriptionPlan } from "store/user";
import UpdateSkpeakerDropdown from "./components/UpdateSpeakerDropdown";
import FindNdReplace from "./findReplace";
import SpeakerModel from "./modalSpeak";
import RenderTranscript from "./renderTranscript";

const MarkdownEditor = lazy(() => import("DAI/MarkdownEditor/components/Editor"));

const timestampToSec = (timestamp) => {
  const timeArray = timestamp.split(":");
  const hours = parseInt(timeArray[0], 10);
  const minutes = parseInt(timeArray[1], 10);
  const seconds = parseInt(timeArray[2], 10);
  return hours * 3600 + minutes * 60 + seconds;
};

function TranscriptV2({
  isPreview,
}) {
  const fileVersion = useSelector(getFileVersion);
  const transcripts = useSelector(transcriptSelector);
  const rawText = useSelector(rawTextSelector);
  const isTimestamped = useSelector(isTimestampedSelector);
  const displayFindAndReplace = useSelector(displayFindAndReplaceSelector);
  const userPlan = useSelector(getSubscriptionPlan);
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const [trans, setTrans] = useState([]);
  const scrollbarsRef = useRef(null);
  const [blocks, setBlocks] = useState([]);
  const dispatch = useDispatch();
  const initialized = useRef(false);
  const editor = BlockNoteEditor.create();

  const onClick = (index, event) => {
    dispatch(
      handShowModal({
        index,
        anchorEl: event.currentTarget,
        speaker: transcripts[index].speaker,
      }),
    );
  };

  const saveHandler = (payload) => {
    dispatch(updateSpecficTrancript(payload));
  };

  useEffect(() => {
    if (isPreview) {
      const items = transcripts.slice(0, 5);
      setTrans(items);
    } else {
      setTrans(transcripts);
    }
  }, [isPreview, transcripts]);

  useEffect(() => {
    const highlighted = document.querySelector(".highlight-dark");

    if (highlighted) {
      const scrollTop = highlighted.offsetTop - 100;
      scrollbarsRef.current.view.scroll({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  }, [highlightedIndex]);

  const highlightText = (
    text,
    searchTerm,
    currentHighlightedIndex,
    tabIndex,
  ) => {
    if (!searchTerm) {
      return text;
    }

    let wordIndex = 0;
    const section = tabIndex;
    const regex = new RegExp(`(${searchTerm})`, "g"); // case sensitive
    return text.split(regex).map((chunk, index) => {
      if (chunk.toLowerCase() === searchTerm.toLowerCase()) {
        const isHighlighted = currentHighlightedIndex
          && currentHighlightedIndex.section === section
          && currentHighlightedIndex.wordIndex === (wordIndex + 1);

        wordIndex += 1;

        setHighlightedIndex(
          currentHighlightedIndex.wordIndex + currentHighlightedIndex.section,
        );
        return (
          <span
            key={index}
            className={isHighlighted ? "highlight-dark" : "highlight"}
          >
            {chunk}
          </span>
        );
      }

      return chunk;
    });
  };

  const handleTimestamps = (timestamp) => {
    if (timestamp) dispatch(setSelecteTimeStamp(timestampToSec(timestamp)));
  };

  useEffect(() => {
    const isInitialized = initialized.current;

    if (rawText && isInitialized === false) {
      const getBlocks = async () => {
        try {
          const content = await editor.tryParseMarkdownToBlocks(rawText);

          initialized.current = true;

          setBlocks(content);
        } catch (e) {
          //
        }
      };

      initialized.current = true;

      getBlocks();
    }
  }, [editor, rawText]);

  if (!isTimestamped) {
    return (
      <Box>
        <ContentHeading
          title="Transcript"
          fileVersion={fileVersion}
          show={!isPreview}
          options={{}}
          onToggle={() => { }}
        />

        <ContentWrapperBody>
          {rawText !== null && rawText !== "" && blocks.length > 0 && (
            <Suspense fallback={<div />}>
              <MarkdownEditor
                key={blocks}
                initialContent={blocks}
                contentType="transcript"
              />
            </Suspense>
          )}
        </ContentWrapperBody>
      </Box>
    );
  }

  return (
    <Box>
      <ContentHeading
        title="Transcript"
        fileVersion={fileVersion}
        show={!isPreview}
        options={{
          feature: "show_notes",
          userPlan,
          actionButtonType: "find",
          isActionVisible: !isPreview,
        }}
        onToggle={() => { }}
      />

      <ContentWrapperBody>
        <FindNdReplace display={displayFindAndReplace} />

        {displayFindAndReplace ? (
          <Scrollbars
            ref={scrollbarsRef}
            style={{
              height: "calc(100vh - 300px)",
            }}
            renderThumbHorizontal={() => <div style={{ display: "none" }} />}
          >
            <RenderTranscript
              trans={trans}
              highlightText={highlightText}
              havePadding
              onClick={onClick}
              handleTimestamps={handleTimestamps}
              saveHandler={saveHandler}
            />
          </Scrollbars>
        ) : (
          <RenderTranscript
            trans={trans}
            highlightText={highlightText}
            onClick={onClick}
            handleTimestamps={handleTimestamps}
            saveHandler={saveHandler}
          />
        )}
      </ContentWrapperBody>

      <UpdateSkpeakerDropdown />

      <SpeakerModel />
    </Box>
  );
}

TranscriptV2.propTypes = {
  isPreview: PropType.bool,
};

TranscriptV2.defaultProps = {
  isPreview: false,
};

export default TranscriptV2;
