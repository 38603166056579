import { lazy, Suspense, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import BannerReels from "DAI/ProcessedData/components/videoReels/banner";
import {
  createNewReel as createNewReelAction,
  CreateReelFlowState as State,
  setCreateReelFlowState,
  videoReelsSliceSelector,
} from "store/dai/videoreelSlice";
import { transcriptSelector } from "store/transcriptSlice";
import { analytics } from "utils/GTM";

const CreateReelDrawer = lazy(() => import("DAI/ProcessedData/components/videoReels/CreateReelDrawer"));
const ProcessingDialog = lazy(() => import("DAI/ProcessedData/components/videoReels/ProcessingDialog"));

export default function CreateNewReelsFlow({ show, open, onClose }) {
  const transcripts = useSelector(transcriptSelector);
  const [creationErrorMsg, setCreationErrorMsg] = useState("");
  const { createReelFlowState: state } = useSelector(videoReelsSliceSelector);
  const dispatch = useDispatch();

  const setState = (value) => {
    dispatch(setCreateReelFlowState(value));
  };

  const { id: docId } = useParams();

  const closeProcessingDialog = () => {
    setState(State.Base);
    onClose();
    analytics.track("Create a New Reel - Closed");
  };

  const openProcessingDialog = () => {
    setState(State.Done);
  };

  const closeCreateNewReels = () => {
    setState(State.Base);
    onClose();
  };

  const createNewReel = async (excerpt) => {
    setCreationErrorMsg("");
    setState(State.Generating);

    const res = await dispatch(
      createNewReelAction({ docId, excerpt }),
    ).unwrap();

    if (res.payload instanceof Error) {
      setCreationErrorMsg(res.payload.message);
      setState(State.Error);

      return;
    }

    analytics.track("Create a New Reel - Generated");

    openProcessingDialog();
  };

  useEffect(() => {
    if (open) {
      setState(State.Create);
      analytics.track("Create a New Reel - Opened");
    }
  }, [open]);

  if (show) {
    return (
      <Box>
        <BannerReels />

        <Suspense lazy={<div />}>
          <CreateReelDrawer
            transcript={transcripts}
            isOpen={open}
            errorMsg={creationErrorMsg}
            onClose={closeCreateNewReels}
            onClickCreateReel={createNewReel}
          />

          <ProcessingDialog open={state === State.Done} onClose={closeProcessingDialog} />
        </Suspense>
      </Box>
    );
  }

  return null;
}

CreateNewReelsFlow.propTypes = {
  show: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
