import { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import { getSubscriptionPlan } from "DAI/Subscription/store/subscriptionSlice";
import { IconClose } from "components/icons";
import { analytics } from "utils/GTM";
import { isFeatureAvailable } from "utils/subscriptionChecks";
import { styles } from "./style";
import rocketIcon from "assets/promo/rocket.svg";

export default function UnlockMediaModal({ open, contentType, featureCode, onClose }) {
  const planCode = useSelector(getSubscriptionPlan);
  const navigate = useNavigate();
  const currentPlanHasFeature = isFeatureAvailable(featureCode, planCode || "FREE");

  const handleUpgrade = () => {
    onClose();
    navigate("/plans");
  };

  useEffect(() => {
    if (open) {
      analytics.track("Media Content Locked Warning Modal Opened", { content: contentType });
    }
  }, [open, contentType]);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={onClose}
      PaperProps={{
        style: styles.root,
      }}
    >
      <Box sx={styles.close}>
        <IconButton aria-label="Close" onClick={onClose}>
          <IconClose />
        </IconButton>
      </Box>

      <Box sx={styles.wraper}>
        <Box sx={{ padding: "14px", borderRadius: 999, bgcolor: "#F5F4FF", width: "max-content", mb: "24px" }}>
          <img
            src={rocketIcon}
            alt="Feature locked"
            height={20}
            width={20}
            style={{ display: "block", objectFit: "cover" }}
          />
        </Box>

        <Typography sx={styles.main}>
          {contentType} not generated
        </Typography>

        <Typography sx={styles.tag}>
          {contentType} were not generated for this file because it was
          uploaded while your account was on a subscription plan that
          did not include&nbsp;

          {contentType}.
        </Typography>

        {currentPlanHasFeature === false && (
          <>
            <Typography sx={{ ...styles.tag, mt: "16px" }}>
              Unlock {contentType} by upgrading your plan!
            </Typography>

            <Box sx={styles.btnWraper}>
              <Button
                variant="outlined"
                sx={styles.outlineBtn}
                onClick={onClose}
              >
                Maybe later
              </Button>

              <Button sx={styles.btn} onClick={handleUpgrade}>
                Explore Plans
              </Button>
            </Box>
          </>
        )}

        {currentPlanHasFeature && (
          <Box sx={styles.btnWraper}>
            <Button
              variant="outlined"
              sx={styles.outlineBtn}
              onClick={onClose}
            >
              Close
            </Button>
          </Box>
        )}
      </Box>
    </Dialog>
  );
}

UnlockMediaModal.propTypes = {
  open: PropTypes.bool.isRequired,
  contentType: PropTypes.oneOf(["audiograms", "video reels", "Title Suggestions"]).isRequired,
  featureCode: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
