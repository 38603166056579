import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

export default function SectionHeader({ children }) {
  return (
    <header>
      <Typography
        component="h2"
        sx={{
          color: "#000",
          fontFamily: "Satoshi",
          fontSize: "14px",
          fontWeight: "700",
          letterSpacing: "0.5px",
          mb: "8px",
          textTransform: "uppercase",
        }}
      >
        {children}
      </Typography>
    </header>
  );
}

SectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
};
