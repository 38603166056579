import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import ContentCopy from "@mui/icons-material/ContentCopy";

const typeToLabelMap = {
  showNotes: "Show Notes",
  abstract: "Summary",
  insights: "Chapters",
  transcript: "Transcript",
  quotes: "Quotes",
  article: "Article",
  tweet: "Tweet Thread",
  caption: "Captions",
  seoKeywords: "SEO Keywords",
  headlines: "Title Suggestions",
};

export default function CopyContent({ type, onCopy }) {
  const onClick = () => {
    onCopy(type);
  };

  return (
    <IconButton
      size="small"
      alt={`copy ${typeToLabelMap[type]} to clipboard`}
      title={`Copy ${typeToLabelMap[type]} to clipboard`}
      onClick={onClick}
      sx={{
        opacity: 0.5,
        "&:hover": {
          opacity: 1,
        },
      }}
    >
      <ContentCopy sx={{ fontSize: "16px" }} />
    </IconButton>
  );
}

CopyContent.propTypes = {
  type: PropTypes.oneOf([
    "showNotes",
    "summary",
    "chapters",
    "transcript",
    "quotes",
    "article",
    "tweetThread",
    "captions",
    "focusKeywords",
    "headline",
    "titleSuggestions",
    "meetingMinutes",
    "actionItems",
    "decisionLog",
    "importantDates",
    "qAndA",
    "nextAgenda",
    "followUpEmails",
    "sessionRecap",
    "whitePaper",
    "faqs",
    "newsletter",
    "landingPage",
  ]).isRequired,
  onCopy: PropTypes.func.isRequired,
};
