import PropTypes from "prop-types";
import theme from "theme";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

export default function ModalHeader({ children, icon, iconPosition, isLoading, textAlign, onClose }) {
  return (
    <Box sx={{ position: "sticky", top: 0, zIndex: 4, bgcolor: "#FFF" }}>
      <Box sx={{ position: "relative", pt: children ? "40px" : 0 }}>
        {!!icon && (
          <div style={{ lineHeight: "48px", textAlign: iconPosition || "left" }}>
            <div
              aria-hidden="true"
              style={{
                borderRadius: "999px",
                color: theme.palette.primary[500],
                display: "inline-block",
                height: "48px",
                width: "48px",
                marginBottom: "8px",
              }}
            >
              {icon}
            </div>
          </div>
        )}

        {!!children && (
          <Typography
            component="h1"
            sx={{
              fontSize: "32px",
              fontWeight: 700,
              textAlign: textAlign || "center",
              lineHeight: "125%",
              pr: (textAlign === "center") ? 0 : "40px",
            }}
          >
            {children}
          </Typography>
        )}

        <IconButton
          aria-label="Close"
          disabled={isLoading}
          title="Close"
          sx={{
            position: "absolute",
            top: "24px",
            right: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon sx={{ color: theme.palette.gray[500], fontSize: "28px" }} />
        </IconButton>
      </Box>
    </Box>
  );
}

ModalHeader.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(["left", "center", "right"]),
  isLoading: PropTypes.bool,
  textAlign: PropTypes.oneOf(["left", "center", "right", "justify"]),
  onClose: PropTypes.func.isRequired,
};

ModalHeader.defaultProps = {
  children: null,
  icon: null,
  iconPosition: "left",
  isLoading: false,
  textAlign: "left",
};
