export const styles = {
    root: {
      position: "relative",
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      color: "text.primary",
      background:"#2b2b2b"
    },
    textWrapper: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      overflow: "hidden",
      width: "95%",
    },
    text: {
      fontSize: "2.5rem",
      fontWeight: "bold",
      lineHeight: "1.2",
      fontFamily: "HelveticaBold",
      color: "#fff",
    },
    controls: {
      position: "absolute",
      bottom: "10px",
      background: "#333333",
      borderRadius: "37px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "5px 0px",
      mx: 1,
      height: "40px",
    },
    controlFullWidth:{
      width: "95%",
    },
    controlHalfWidth:{
      width: "70%",
      left: "37%",
      transform: "translate(-52%, -15%)",
    },
    progressbarWrapper: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      position: "relative",
    },
  };