import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import theme from "theme";

const styles = {
  chip: {
    background: "#F5F4FF",
    color: theme.palette.brand[500],
    fontFamily: "DM Sans",
    fontSize: "0.875rem",
    fontWeight: 500,

    "&:hover": {
      background: "#E8E5FF",
    }
  },
};

export default function AskDeciphrSuggestion({ label, onClick }) {
  return (
    <Chip
      sx={styles.chip}
      label={label}
      onClick={() => onClick(label)}
    />
  );
}

AskDeciphrSuggestion.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
