import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { displayFindAndReplaceSelector, toggleDisplayFindAndReplace } from "store/transcriptSlice";

export default function FindAndReplaceToggleButton({ show }) {
  const isVisible = useSelector(displayFindAndReplaceSelector);
  const dispatch = useDispatch();

  const onToggle = () => {
    dispatch(toggleDisplayFindAndReplace());
  };

  if (show) {
    return (
      <Button
        aria-label="Find and replace"
        sx={{
          display: "block",
          minWidth: "64px",
          borderRadius: "999px",
          border: "2px solid #E6E8EC",
          backgroundColor: !isVisible ? "#fff" : "#777E91",
          color: !isVisible ? "#23262F" : "#ffffff",
          fontFamily: "DM Sans",
          fontWeight: 400,
          fontSize: "16px",

          "&:hover": {
            backgroundColor: !isVisible ? "#fff !important" : "#777E91 !important",
            boxShadow: "none !important",
            color: !isVisible ? "#23262F !important" : "#ffffff !important",
            border: "solid 2px #E6E8EC !important",
          },
        }}
        onClick={onToggle}
      >
        Find
      </Button>
    );
  }

  return false;
}
