export const messages = {
  credits: {
    title: "Your file is too powerful!",
    message: "Your file duration exceeds the Upload Minutes available on your account. "
      + "You have [[UPLOAD_CREDITS]] minutes of media upload credits remaining. "
      + "Would you like to upgrade your subscription plan with more Upload Minutes "
      + "to keep uploading?",
    tag: "Credit Overdrawn",
    showPrimaryCTA: true,
  },
  docCredits: {
    title: "Your file is too powerful!",
    message: "You have reached the maximum limit of transcript uploads for your current plan. "
      + "Would you like to upgrade your plan or purchase an add-on to keep uploading?",
    tag: "Plan limit reached",
    showPrimaryCTA: true,
  },
  duration: {
    title: "Your file is too powerful!",
    message: "Maximum file duration for your current subscription plan is [[DURATION]] minutes. "
      + "Would you like to upgrade your subscription plan to upload files with unlimited file duration?",
    tag: "Subscription Limit",
    showPrimaryCTA: true,
  },
  minDuration: {
    title: "Your file is too short",
    message: "Audio and video files should be at least 1 minute long.",
    tag: "Minimum Duration",
    showPrimaryCTA: false,
  },
  fileSize: {
    title: "Your file is too powerful!",
    message: "The maximum file size that can be uploaded for your current subscription "
      + "plan is [[SIZE]].",
    tag: "File Size Limit",
    showPrimaryCTA: false,
  },
  unavailable: {
    title: "Choose the right plan for you!",
    message: "This feature is not available for your current subscription plan. Would "
      + "you like to upgrade your plan to use this feature?",
    tag: "Feature Unavailable",
    showPrimaryCTA: true,
  },
  editing: {
    title: "Choose the right plan for you!",
    message: "Saving your edits is not available for the current plan. Would you "
      + "like to upgrade your plan to use this feature?",
    tag: "Saving Edits Unavailable",
    showPrimaryCTA: true,
  },
};
