import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import theme from "theme";
import { Rating } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import StarIcon from "@mui/icons-material/Star";
import FeedbackForm from "./FeedbackForm";

const styles = {
  root: {
    border: `2px solid ${theme.palette.gray[200]}`,
    borderRadius: "12px",
    mb: "48px",
    padding: "16px 32px",
  },
  question: {
    fontFamily: "Satoshi",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "1.48rem",
    color: theme.palette.gray[700],
  },
  rating: {
    "& .MuiRating-iconEmpty": {
      color: theme.palette.gray[300],
    },
  },
};

export default function OutputRating({ contentType, isComplete, onRatingChanged, onSubmit }) {
  const [isTextboxShown, setIsTextBoxShown] = useState(false);

  const onRate = (_, value) => {
    setIsTextBoxShown(true);
    onRatingChanged(value);
  };

  useEffect(() => {
    if (!isComplete) {
      setIsTextBoxShown(false);
    }
  }, [isComplete]);

  return (
    <Box sx={styles.root}>
      {!isComplete && (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={styles.question}>
              How was your experience with {contentType}?
            </Typography>

            <Rating
              sx={styles.rating}
              max={5}
              precision={1}
              emptyIcon={<StarIcon />}
              onChange={onRate}
            />
          </Box>

          <FeedbackForm show={isTextboxShown} onSubmit={onSubmit} />
        </>
      )}

      {isComplete && (
        <Typography
          sx={{
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 500,
            color: "#23262F",
          }}
        >
          Thanks for letting us know! 🎉
        </Typography>
      )}
    </Box>
  );
}

OutputRating.propTypes = {
  contentType: PropTypes.string,
  isComplete: PropTypes.bool.isRequired,
  onRatingChanged: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

OutputRating.defaultProps = {
  contentType: " this content",
};
