import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import DAIButton from "Core/components/DAIButton";
import { IconClose } from "components/icons";
import {
  errorMsgTypeSelector,
  mediaFileModalSelector,
  setMediaFileModal,
} from "store/payment";
import { getCreditsRemaining, getSubscriptionPlan } from "store/user";
import { analytics } from "utils/GTM";
import { getFileLimitsForPlan } from "utils/subscriptionChecks";
import { messages } from "./data";
import styles from "./style";
import ErrorImg from "assets/Bug.png";

function FileError() {
  const userPlan = useSelector(getSubscriptionPlan);
  const credits = useSelector(getCreditsRemaining);
  const open = useSelector(mediaFileModalSelector);
  const errorMsgType = useSelector(errorMsgTypeSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      setMediaFileModal({
        open: false,
        type: "",
      }),
    );
  };

  const handleUpgrade = () => {
    navigate("/plans");
    dispatch(
      setMediaFileModal({
        open: false,
        type: "",
      }),
    );
  };

  const getErrorMessage = () => {
    try {
      let message;
      const limits = getFileLimitsForPlan(userPlan);

      if (errorMsgType === "duration") {
        message = messages[errorMsgType].message.replace("[[DURATION]]", limits.duration);
      } else if (errorMsgType === "fileSize") {
        message = messages[errorMsgType].message.replace("[[SIZE]]", limits.size);
      } else if (errorMsgType === "credits") {
        message = messages[errorMsgType].message
          .replace("[[UPLOAD_CREDITS]]", Math.floor(credits.media));
      } else {
        message = messages[errorMsgType]?.message;
      }

      return message;
    } catch (e) {
      return "";
    }
  };

  useEffect(() => {
    if (open) {
      analytics.track(`${messages[errorMsgType]?.title} Error Modal Opened`, {
        message: messages[errorMsgType]?.tag,
      });
    }
  }, [open, errorMsgType]);

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      onClose={handleClose}
      PaperProps={{
        style: styles.root,
      }}
    >
      <Box sx={styles.close}>
        <IconButton onClick={handleClose}>
          <IconClose />
        </IconButton>
      </Box>

      <Box sx={styles.wraper}>
        <Box>
          <img src={ErrorImg} alt="error" />
        </Box>

        <Typography sx={styles.main}>{messages[errorMsgType]?.title}</Typography>

        <Typography sx={styles.tag}>{getErrorMessage()}</Typography>

        <Box sx={styles.btnWraper}>
          <Button
            variant="outlined"
            sx={styles.outlineBtn}
            onClick={handleClose}
          >
            Close
          </Button>

          {messages[errorMsgType]?.showPrimaryCTA && (
            <DAIButton onClick={handleUpgrade}>
              Upgrade
            </DAIButton>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}

export default FileError;
