import { useCallback, useEffect, useState } from "react";
import PropType from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import DAIButton from "Core/components/DAIButton";
import ShareContentPopup from "DAI/ContentDetails/components/ShareContentPopup";
import { getContentTypeLabel } from "DAI/ContentDetails/services/presets";
import { selectContentList, selectFileMetadata, selectPreset } from "DAI/ContentDetails/store/contentSlice";
import { setToast } from "store/toastSlice";
import { createUpdateShareOutput, getShareOutput } from "utils/apis";
import { analytics } from "utils/GTM";
import STATES from "./data";

export default function ShareContentContainer({
  hideBtn,
  open,
  onClose,
}) {
  const metadata = useSelector(selectFileMetadata);
  const preset = useSelector(selectPreset);
  const contentList = useSelector(selectContentList);
  const [show, setShow] = useState(false);
  const [writtenOptions, setWrittenOptions] = useState([]);
  const [mediaOptions, setMediaOptions] = useState([]);
  const [state, setState] = useState(STATES.INITIAL);
  const [shareId, setShareId] = useState(null);
  const dispatch = useDispatch();

  const getSharedStatus = useCallback(async (fileId) => {
    try {
      const data = await getShareOutput(fileId);
      const items = data?.share?.shared_items || [];
      const written = writtenOptions.map((option) => {
        const selected = items.includes(option.value);

        return { ...option, selected };
      });
      const media = mediaOptions.map((option) => {
        const selected = items.includes(option.value);

        return { ...option, selected };
      });

      setWrittenOptions(written);
      setMediaOptions(media);
      setState(STATES.SHARED);
      setShareId(data.share.id);
    } catch (e) {
      if (e.code === 404) {
        // Not shared, ignore error
        setState(STATES.UNSHARED);
      }
    }
  }, [mediaOptions, writtenOptions]);

  const togglePopup = () => {
    if (show) {
      onClose();
    }

    setShow((visible) => !visible);
  };

  const onWrittenOptionToggle = (value) => {
    const options = [...writtenOptions];
    const index = options.findIndex((option) => option.value === value);

    if (index > -1) {
      options[index].selected = !options[index].selected;

      setWrittenOptions(options);
    }
  };

  const onMediaOptionToggle = (value) => {
    const options = [...mediaOptions];
    const index = options.findIndex((option) => option.value === value);

    if (index > -1) {
      options[index].selected = !options[index].selected;

      setMediaOptions(options);
    }
  };

  const onShare = async () => {
    const written = writtenOptions.filter((option) => option.selected).map((option) => option.value);
    const media = mediaOptions.filter((option) => option.selected).map((option) => option.value);
    const selected = [...written, ...media];
    const tab = window.open("", Date.now());

    if (metadata.fileType === "audio" || metadata.fileType === "video") {
      // Include the audio player in the shared page
      selected.push("serving_url");
    }

    setState(STATES.LOADING);

    try {
      const data = await createUpdateShareOutput({
        doc_id: metadata.fileId,
        shared_items: selected,
      });

      tab.location.href = `${process.env.REACT_APP_SHARE_URL}/${data.id}`;

      tab.focus();
      analytics.track("Successfully Shared Content", {
        shareable_id: data.id,
        share_options: selected,
      });

      setShareId(data.id);
      setState(STATES.SHARED);
    } catch (e) {
      dispatch(
        setToast({
          message: "Content could not be shared. Please retry in a bit.",
          severity: "error",
        }),
      );
    }
  };

  useEffect(() => {
    if (preset && writtenOptions.length === 0) {
      const options = contentList.map((type) => ({
        label: getContentTypeLabel(type),
        value: type,
        selected: false,
      }));

      setWrittenOptions(() => options);
    }
  }, [contentList, preset, writtenOptions]);

  useEffect(() => {
    if (metadata.fileType === "audio" && metadata.hasMedia) {
      setMediaOptions([
        { label: "Audiograms", value: "audiogram" },
      ]);
    } else if (metadata.fileType === "video" && metadata.hasMedia) {
      setMediaOptions([
        { label: "Audiograms", value: "audiogram" },
        { label: "Video Reels", value: "videogram" },
      ]);
    }
  }, [metadata?.fileType]);

  useEffect(() => {
    let timeout;

    if (metadata.fileId && state === STATES.INITIAL) {
      timeout = setTimeout(() => {
        getSharedStatus(metadata.fileId);
      }, 3848);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [metadata?.fileId, state, getSharedStatus]);

  useEffect(() => {
    setShow(open);
  }, [open]);

  return (
    <>
      {!hideBtn && (
        <DAIButton
          variant="outlined"
          startIcon={<ShareOutlinedIcon />}
          sx={{
            py: "8px",
            minWidth: "auto",
          }}
          onClick={togglePopup}
        >
          Share
        </DAIButton>
      )}

      <ShareContentPopup
        open={show}
        shareId={shareId}
        currentState={state}
        writtenOpts={writtenOptions}
        mediaOpts={mediaOptions}
        onWrittenOptionToggle={onWrittenOptionToggle}
        onMediaOptionToggle={onMediaOptionToggle}
        onShare={onShare}
        onClose={togglePopup}
      />
    </>
  );
}

ShareContentContainer.propTypes = {
  hideBtn: PropType.bool,
  open: PropType.bool,
  onClose: PropType.func,
};

ShareContentContainer.defaultProps = {
  hideBtn: false,
  open: false,
  onClose: () => { },
};
