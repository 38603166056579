import PropType from "prop-types";
import { Stack } from "@mui/material";

const styles = {
  root: {
    padding: "30px 0px",
    borderRadius: "1rem 1rem 0 0",
    position: "sticky",
    top: "0",
    left: "0",
    zIndex: 2,
    background: "white",
  },
};

export default function Header({ children }) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={styles.root}
    >
      {children}
    </Stack>
  );
}

Header.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropType.any,
};

Header.defaultProps = {
  children: undefined,
};
