import LinearProgress from "@mui/material/LinearProgress";

const Progressbar = ({ handleProgressChange, progress, sx }) => {
  const handleClick = (event) => {
    const progressBarRect = event.currentTarget.getBoundingClientRect();
    const clickedX = event.clientX - progressBarRect.left;
    const progressBarWidth = progressBarRect.width;
    const newProgress = (clickedX / progressBarWidth) * 100;
    handleProgressChange(newProgress);
  };
  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      sx={{
        ...(sx || styles.root),
      }}
      onClick={handleClick}
    />
  );
};

export default Progressbar;

const styles = {
  root: {
    height: "40px",
    width: "100%",
    opacity: 0.1,
    position: "absolute",
    bottom: -10,
    zIndex: 1,
    cursor: "pointer",
  },
};
