import ActionItemsContainer from "DAI/ProcessedData/containers/ActionItemsContainer";
import ArticlesContainer from "DAI/ProcessedData/containers/ArticlesContainer";
import AudiogramsContainer from "DAI/ProcessedData/containers/AudiogramContainer";
import CaptionsContainer from "DAI/ProcessedData/containers/CaptionsContainer";
import ChaptersContainer from "DAI/ProcessedData/containers/ChaptersContainer";
import DecisionLogContainer from "DAI/ProcessedData/containers/DecisionLogContainer";
import FAQsContainer from "DAI/ProcessedData/containers/FAQsContainer";
import FollowUpEmailsContainer from "DAI/ProcessedData/containers/FollowUpEmailsContainer";
import ImportantDatesContainer from "DAI/ProcessedData/containers/ImportantDatesContainer";
import LandingPageContainer from "DAI/ProcessedData/containers/LandingPageContainer";
import MeetingMinutesContainer from "DAI/ProcessedData/containers/MeetingMinutesContainer";
import NewsletterContainer from "DAI/ProcessedData/containers/NewsletterContainer";
import NextAgendaContainer from "DAI/ProcessedData/containers/NextAgendaContainer";
// import ClipFinderContainer from "DAI/ProcessedData/containers/ClipFinderContainer";
import QAndAContainer from "DAI/ProcessedData/containers/QAndAContainer";
import QuotesContainer from "DAI/ProcessedData/containers/QuotesContainer";
import SEOKeywordsContainer from "DAI/ProcessedData/containers/SEOKeywordsContainer";
import SessionRecapContainer from "DAI/ProcessedData/containers/SessionRecapContainer";
import ShowNotesContainer from "DAI/ProcessedData/containers/ShowNotesContainer";
import SummaryContainer from "DAI/ProcessedData/containers/SummaryContainer";
import TitleSuggestionsContainer from "DAI/ProcessedData/containers/TitleSuggestionsContainer";
import TweetsContainer from "DAI/ProcessedData/containers/TweetsContainer";
import VideoReelsContainer from "DAI/ProcessedData/containers/VideoReelsContainer";
import WhitePaperContainer from "DAI/ProcessedData/containers/WhitePaperContainer";
import Transcript from "pages/newViewTranscript/transcriptV2";

const CONTENT_TYPES = {
  SHOW_NOTES: {
    name: "Show Notes", value: "showNotes", container: ShowNotesContainer,
  },

  SUMMARY: {
    name: "Summary", value: "summary", container: SummaryContainer,
  },

  QUOTES: {
    name: "Quotes", value: "quotes", container: QuotesContainer,
  },

  CHAPTERS: {
    name: "Chapters", value: "chapters", container: ChaptersContainer,
  },

  TRANSCRIPT: {
    name: "Transcript", value: "transcript", container: Transcript,
  },

  ARTICLE: {
    name: "Article", value: "article", container: ArticlesContainer,
  },

  TWEET_THREAD: {
    name: "Tweet Thread", value: "tweetThread", container: TweetsContainer,
  },

  CAPTIONS: {
    name: "Captions", value: "captions", container: CaptionsContainer,
  },

  FOCUS_KEYWORDS: {
    name: "Focus Keywords", value: "focusKeywords", container: SEOKeywordsContainer,
  },

  TITLE_SUGGESTIONS: {
    name: "Title Suggestions", value: "titleSuggestions", container: TitleSuggestionsContainer,
  },

  MEETING_MINUTES: {
    name: "Meeting Minutes", value: "meetingMinutes", container: MeetingMinutesContainer,
  },

  ACTION_ITEMS: {
    name: "Action Items", value: "actionItems", container: ActionItemsContainer,
  },

  DECISION_LOG: {
    name: "Decision Log", value: "decisionLog", container: DecisionLogContainer,
  },

  IMPORTANT_DATES: {
    name: "Important Dates", value: "importantDates", container: ImportantDatesContainer,
  },

  Q_AND_A: {
    name: "Q&A", value: "qAndA", container: QAndAContainer,
  },

  NEXT_AGENDA: {
    name: "Next Agenda", value: "nextAgenda", container: NextAgendaContainer,
  },

  FOLLOW_UP_EMAILS: {
    name: "Follow-up Emails", value: "followUpEmails", container: FollowUpEmailsContainer,
  },

  SESSION_RECAP: {
    name: "Session Recap", value: "sessionRecap", container: SessionRecapContainer,
  },

  WHITE_PAPER: {
    name: "White Paper", value: "whitePaper", container: WhitePaperContainer,
  },

  FAQS: {
    name: "FAQs", value: "faqs", container: FAQsContainer,
  },

  NEWSLETTER: {
    name: "Newsletter", value: "newsletter", container: NewsletterContainer,
  },

  LANDING_PAGE: {
    name: "Landing Page", value: "landingPage", container: LandingPageContainer,
  },

  AUDIOGRAMS: {
    name: "Audiograms", value: "audiograms", container: AudiogramsContainer,
  },

  REELS: {
    name: "Video Reels", value: "reels", container: VideoReelsContainer,
  },
};

export default CONTENT_TYPES;
