import PropType from "prop-types";
import theme from "theme";
import { Box, Stack, Typography } from "@mui/material";
import { ReactComponent as CornerDownRightIcon } from "assets/content-details/corner-down-right.svg";

const styles = {
  title: {
    fontFamily: "Satoshi",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "29px",
    letterSpacing: "0.01em",
    textAlign: "center",
    marginBottom: "49px",
  },
  item: {
    background: theme.palette.brand[100],
    borderRadius: "16px",
    padding: "9.5px 24px",
    height: "67px",
    width: "380px",
    fontFamily: "Satoshi",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    textAlign: "left",
    color: theme.palette.brand[500],
    cursor: "pointer",
    transition: "all 0.3s ease",

    "&:hover": {
      background: theme.palette.brand[200],
    },
  },
  icon: {
    width: "17px",
    height: "auto",
    alignSelf: "start",
  },
};

export default function SuggestionList({
  onClick,
}) {
  const suggestions = [
    "List the most viral moments and quotes from this file.",
    "What's the TL;DR of this file?",
    "Tell me more about the speaker(s).",
  ];

  const handleClick = (suggestion) => {
    onClick(suggestion);
  };

  return (
    <Stack
      alignItems="center"
    >
      <Typography component="h3" sx={styles.title}>
        Ask questions about this file
      </Typography>

      <Stack gap="21px">
        {suggestions.map((suggestion) => (
          <Stack key={suggestion} direction="row" sx={styles.item} gap="12px" onClick={() => handleClick(suggestion)}>
            <CornerDownRightIcon sx={styles.icon} />

            <Box>
              {suggestion}
            </Box>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

SuggestionList.propTypes = {
  onClick: PropType.func,
};

SuggestionList.defaultProps = {
  onClick: () => { },
};
