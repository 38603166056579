import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import * as clipboard from "clipboard-polyfill";
import showdown from "showdown";
import { stripHTML } from "DAI/helpers";
import LockIcon from "DAI/ContentDetails/components/lockContent";
import ProcessingLoader from "DAI/ContentDetails/components/ProcessingLoader";
import CopyContent from "DAI/ProcessedData/components/CopyContent/iconButton";
import CopyTextButton from "DAI/ProcessedData/components/CopyContent/textButton";
import { getUserPlan } from "DAI/Subscription/services/utils";
import { selectFileMetadata } from "DAI/ContentDetails/store/contentSlice";
import { copyItemToClipboard, selectClipboard } from "DAI/ProcessedData/store/writtenContentSlice";
import { setToast } from "store/toastSlice";
import { getSubscriptionPlan } from "store/user";
import { analytics } from "utils/GTM";

const isEmpty = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  }

  return value === null || value === "";
};

export default function CopyToClipboard({ tabName, type, version = "icon" }) {
  const metadata = useSelector(selectFileMetadata);
  const copiedItem = useSelector(selectClipboard);
  const planSubscription = useSelector(getSubscriptionPlan);
  const dispatch = useDispatch();
  const { availableFeatures } = getUserPlan(planSubscription) || {};
  const isFeatureEnabledInCurrentPlan = availableFeatures?.includes(type);

  const convertMDToHTML = useCallback((mdString) => {
    const converter = new showdown.Converter({ tables: true });
    const html = converter.makeHtml(mdString);

    return html;
  }, []);

  const onCopy = () => {
    dispatch(copyItemToClipboard({ type }));
    dispatch(
      setToast({
        message: "Copied to your clipboard",
        severity: "success",
        autoClose: true,
      }),
    );

    analytics.track("Copy to clipboard", { category: tabName });
  };

  useEffect(() => {
    if (copiedItem.type !== type) {
      return;
    }

    if (!copiedItem.value) {
      return;
    }

    const richText = convertMDToHTML(copiedItem.value || "");
    let plainText = stripHTML(richText);

    // HACK: Ugly workaround to remove the table headers for chapters
    if (type === "insights") {
      plainText = plainText.replace("Timestamp", "").replace("Summary", "");
    }

    const clipboardItems = new clipboard.ClipboardItem({
      "text/html": new Blob([richText], { type: "text/html" }),
      "text/plain": new Blob([plainText], { type: "text/plain" }),
    });

    clipboard.write([clipboardItems]);
  }, [convertMDToHTML, copiedItem, type]);

  if (!isFeatureEnabledInCurrentPlan && isEmpty(copiedItem.value) && metadata.processed !== "N") {
    return <LockIcon />;
  }

  if (isEmpty(copiedItem.value) && metadata.processed === "N" && metadata.viewable) {
    return <ProcessingLoader altText={`Generating ${tabName}`} />;
  }

  if (version === "icon") {
    return <CopyContent type={type} onCopy={onCopy} />;
  }

  return <CopyTextButton type={type} onCopy={onCopy} />;
}

CopyToClipboard.propTypes = {
  tabName: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "showNotes",
    "summary",
    "chapters",
    "transcript",
    "quotes",
    "article",
    "tweetThread",
    "captions",
    "focusKeywords",
    "headline",
    "titleSuggestions",
    "meetingMinutes",
    "actionItems",
    "decisionLog",
    "importantDates",
    "qAndA",
    "nextAgenda",
    "followUpEmails",
    "sessionRecap",
    "whitePaper",
    "faqs",
    "newsletter",
    "landingPage",
    "content_brain",
  ]).isRequired,
  version: PropTypes.oneOf(["icon", "text"]).isRequired,
};
