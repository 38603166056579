import theme from "theme";
import { Box } from "@mui/material";
import Body from "./Body";
import Header from "./Header";

export const ContentWrapperHeader = Header;
export const ContentWrapperBody = Body;

const styles = {
  root: {
    border: `2px solid ${theme.palette.gray[200]}`,
    borderRadius: "1rem",
    background: "#FFF",
    height: "100%",
  },
};

export function ContentWrapper({ children }) {
  return (
    <Box sx={styles.root}>
      {children}
    </Box>
  );
}

export default ContentWrapper;
