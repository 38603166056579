import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import * as clipboard from "clipboard-polyfill";

export const ChipCopyOnClick = ({ label }) => {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
    clipboard.writeText(label);
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };

  return (
    <Tooltip title="Copied" placement="top" open={open}>
      <Chip sx={styles.chip} label={label} onClick={onClick} />
    </Tooltip>
  );
};

const styles = {
  chip: {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "16px",
  },
};
