import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BlockNoteEditor } from "@blocknote/core";
import ContentHeading from "DAI/ContentDetails/containers/ContentHeading";
import { ContentWrapperBody } from "DAI/ProcessedData/containers/ContentWrapper";
import GeneratingOverlay from "DAI/ProcessedData/components/MediaBlockOverlays/generatingOverlay";
import { selectProcessed } from "DAI/ContentDetails/store/contentSlice";
import { selectContent } from "DAI/ProcessedData/store/writtenContentSlice";
import { getSubscriptionPlan } from "store/user";

const MarkdownEditor = lazy(() => import("DAI/MarkdownEditor/components/Editor"));

export default function MeetingMinutesContainer() {
  const [query] = useSearchParams();
  const tab = query.get("tab");
  const meetingMinutes = useSelector(selectContent(tab));
  const userPlan = useSelector(getSubscriptionPlan);
  const processed = useSelector(selectProcessed);
  const [blocks, setBlocks] = useState([]);
  const initialized = useRef(false);
  const editor = BlockNoteEditor.create();

  useEffect(() => {
    const isInitialized = initialized.current;

    if (meetingMinutes && isInitialized === false) {
      const getBlocks = async () => {
        try {
          const content = await editor.tryParseMarkdownToBlocks(meetingMinutes);

          initialized.current = true;

          setBlocks(content);
        } catch (e) {
          //
        }
      };

      initialized.current = true;

      getBlocks();
    }
  }, [editor, meetingMinutes]);

  return (
    <>
      <ContentHeading
        title="Meeting Minutes"
        show
        options={{ userPlan }}
      />

      <ContentWrapperBody>
        <GeneratingOverlay
          show={processed === "N" && meetingMinutes === null}
          type="Meeting Minutes"
        />

        <GeneratingOverlay
          show={processed === null && meetingMinutes === null}
          prefix="Loading"
          type="Meeting Minutes"
        />

        {meetingMinutes !== null && meetingMinutes !== "" && blocks.length > 0 && (
          <Suspense fallback={<div />}>
            <MarkdownEditor initialContent={blocks} contentType="meetingMinutes" />
          </Suspense>
        )}
      </ContentWrapperBody>
    </>
  );
}
