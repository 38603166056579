import { Box, IconButton } from "@mui/material";
import PauseRounded from "@mui/icons-material/PauseCircle";
import PlayArrowRounded from "@mui/icons-material/PlayCircle";

export default function PlayPauseButtons({ isPlaying, handlePlayClick }) {
  return (
    <Box>
      <IconButton
        aria-label={isPlaying ? "play" : "pause"}
        onClick={handlePlayClick}
      >
        {!isPlaying ? (
          <PlayArrowRounded sx={{ fontSize: "2rem", color: "#fff" }} />
        ) : (
          <PauseRounded sx={{ fontSize: "2rem", color: "#fff" }} />
        )}
      </IconButton>
    </Box>
  );
}
