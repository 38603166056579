import { forwardRef } from "react";

function Player({ actualVideo,handleVideoTimeUpdate }, ref) {
  return (
    <video
      id="video"
      loop
      style={{ width: "100%", height: "100%" }}
      ref={ref}
      onTimeUpdate={handleVideoTimeUpdate}
      preload="auto"
    >
      <source src={`${actualVideo}?timestamp=${Date.now()}`} type="video/mp4" />
    </video>
  );
}

export default forwardRef(Player);
