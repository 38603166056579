import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { ContentWrapperBody, ContentWrapperHeader } from "DAI/ProcessedData/containers/ContentWrapper";
import CreateNewReelsFlow from "DAI/ProcessedData/containers/CreateNewReelsFlow";
import ReelsPlaceholder from "DAI/ProcessedData/containers/ReelsPlaceholder";
import VideoReelPlayer from "DAI/ProcessedData/containers/videoReelPlayer";
import SectionTitle from "DAI/ContentDetails/components/SectionTitle";
import GeneratingOverlay from "DAI/ProcessedData/components/MediaBlockOverlays/generatingOverlay";
import MediaCard from "DAI/ProcessedData/components/MediaCard";
import CreateReelCTA from "DAI/ProcessedData/components/videoReels/CreateReelCTA";
import { selectFileType, selectHasMedia } from "DAI/ContentDetails/store/contentSlice";
import { setVideoReel } from "DAI/MediaEditor/store/videoReelEditorSlice";
import {
  checkAllVideoReelsStatus,
  downloadableVideoReelsLinksSelector,
  pendingVideoReelsIdsSelector,
  setSelectedVideo,
  videoReelsSelector,
} from "store/dai/videoreelSlice";
import { setToast } from "store/toastSlice";
import { analytics } from "utils/GTM";

const STATUS_CHECK_INTERVAL = 10000; // 10 seconds

export default function VideoReelTabs() {
  const videos = useSelector(videoReelsSelector);
  const listOfPendingVideoReelsId = useSelector(pendingVideoReelsIdsSelector);
  const downloadableVideoReelsLinks = useSelector(
    downloadableVideoReelsLinksSelector,
  );
  const fileType = useSelector(selectFileType);
  const hasMedia = useSelector(selectHasMedia);
  const params = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [creatorOpen, setCreatorOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (listOfPendingVideoReelsId.length === 0) {
        clearInterval(interval);
        return;
      }

      dispatch(checkAllVideoReelsStatus(params.id));
    }, STATUS_CHECK_INTERVAL);

    return () => clearInterval(interval);
  }, [listOfPendingVideoReelsId, params.id, dispatch]);

  const download = async (item) => {
    const reelUrl = downloadableVideoReelsLinks.find(
      (video) => item.id === video.id,
    )?.video_url;

    if (!reelUrl) {
      return;
    }

    dispatch(
      setToast({
        message: "Starting download",
        severity: "success",
      }),
    );

    try {
      const response = await fetch(reelUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const tag = document.createElement("a");

      tag.href = url;
      tag.download = "video-reel.mp4";
      tag.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 10000);
      window.open(reelUrl, "_blank");

      analytics.track("Downloaded video reel", { video: reelUrl });

      if (item.is_user_generated) {
        analytics.track("Create a New Reel - Downloaded", { video: reelUrl });
      }
    } catch (error) {
      dispatch(setToast({ message: error.message, severity: "error" }));
    }
  };

  const handlePlayVideo = (video) => {
    const eventName = "Preview Video Reel Modal Opened";
    analytics.track(eventName, { video_reel_id: video.id });

    dispatch(setSelectedVideo(video));
    setOpen(true);
    analytics.track("Create a New Reel - Previewed", { video: video.video_url });
  };

  const handleClickCustomize = (video) => {
    const eventName = "Edit Video Reel Modal Opened";
    analytics.track(eventName, { video_reel_id: video.id });
    dispatch(setVideoReel(video));
    navigate(`/main_topic/${params.id}/video-reels/${video.id}`);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <ContentWrapperHeader>
        <SectionTitle title="Video Reels" />
      </ContentWrapperHeader>

      <CreateReelCTA
        show={fileType === "video" && hasMedia === true}
        onClick={() => setCreatorOpen(true)}
      />

      <ContentWrapperBody>
        <ReelsPlaceholder
          show={fileType !== "video" || (fileType === "video" && hasMedia === false)}
        />

        <CreateNewReelsFlow
          show={fileType === "video" && hasMedia === true}
          open={creatorOpen}
          onClose={() => setCreatorOpen(false)}
        />

        {videos !== null && (
          <Stack
            marginTop="20px"
            gap="32px"
            pb="32px"
          >
            {(videos || [])
              .map((item) => (
                <MediaCard
                  key={item.id}
                  isPending={listOfPendingVideoReelsId.includes(item.id) || !item.video_url}
                  isEditable={!!item.background_video_url}
                  media={item}
                  onClickDownload={() => download(item)}
                  onClickPlay={handlePlayVideo}
                  onClickCustomize={handleClickCustomize}
                />
              ))}
          </Stack>
        )}

        <GeneratingOverlay
          show={fileType === "video" && hasMedia === true && videos === null}
          type="Video Reels"
        />
      </ContentWrapperBody>

      {/* <PortraitBanner type="video" /> */}
      <VideoReelPlayer
        download={download}
        open={open}
        close={() => setOpen(false)}
      />
    </Box>
  );
}
