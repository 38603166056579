export function throttle(func, limit) {
  let inThrottle = false;

  return (arg) => {
    if (!inThrottle) {
      func(arg);

      inThrottle = true;

      setTimeout(() => {
        inThrottle = false;
      }, limit);
    }
  };
}

export const isSafari = () => {
  const UA = navigator.userAgent;

  if (UA.includes("Chrome") || UA.includes("Firefox")) {
    return false;
  }
  const match = UA.match(/^.*Safari\/.+$/);

  return match && match.length > 0;
};

export function download(data, filename, ext, tab) {
  if (isSafari()) {
    const mimeTypes = {
      "pdf": "application/pdf",
      "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "txt": "text/plain",
    };
    const blob = new File([data], `${filename}.${ext}`, { type: mimeTypes[ext] });
    const url = URL.createObjectURL(blob);

    tab.location = url;
  } else {
    // For normal, well-behaved browsers
    const url = URL.createObjectURL(data);
    const a = document.createElement("a");

    a.download = `${filename}.${ext}`;
    a.href = url;
    a.target = "_self";

    a.click();

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      a.remove();
      URL.revokeObjectURL(url);
    }, 100);
  }
}
