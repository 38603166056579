import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

export default function SectionDescription({ children }) {
  return (
    <Typography
      sx={{
        borderBottom: "thin solid #C4C4C4",
        color: "#6D7A83",
        fontFamily: "Satoshi",
        fontSize: "14px",
        fontWeight: 500,
        maxWidth: "184px",
        pb: "12px",
      }}
    >
      {children}
    </Typography>
  );
}

SectionDescription.propTypes = {
  children: PropTypes.node.isRequired,
};
