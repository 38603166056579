const styles = {
  root: {
    bgcolor: "white",
    color: "black",
  },
  actions: {
    marginTop: "39px",
  },
  chatBtn: {
    height: "42px",
    width: "112px",
    minWidth: "112px",
    margin: 0,
  },
  backBtn: {
    width: "41px",
    height: "41px",
  },
};

export default styles;
