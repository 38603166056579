import React from "react";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";

export default function CancelButton({
  handleClose,
  show,
  color = "#fff",
  showRoundIcon,
}) {
  if (!show) return null;
  return (
    <IconButton
      onClick={handleClose}
      sx={{
        position: "absolute",
        top: "10px",
        right: "10px",
        zindex: 999,
      }}
    >
      {showRoundIcon ? (
        <CancelIcon
          sx={{
            fontSize: "2rem",
            color,
          }}
        />
      ) : (
        <CloseIcon
          sx={{
            fontSize: "1.5rem",
            color,
          }}
        />
      )}
    </IconButton>
  );
}
