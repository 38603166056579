import theme from "theme";

const styles = {
  icon: {
    width: "64px",
    height: "64px",
    padding: "10px 13px",
    border: `3px solid ${theme.palette.brand[500]}`,
    borderRadius: "9999px",
    color: theme.palette.brand[500],
  },
};

export default styles;
