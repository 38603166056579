import AskDeciphrContainer from "DAI/ProcessedData/containers/AskDeciphrContainer";
import CONTENT_TYPES from "DAI/ContentDetails/services/contentTypes";
import { ReactComponent as GeneralContentIcon } from "assets/icons/general-content-icon.svg";
import { ReactComponent as MultimediaContentIcon } from "assets/icons/multimedia-content-icon.svg";
import { ReactComponent as SocialContentIcon } from "assets/icons/social-content-icon.svg";

const contentFileTypes = [
  "audio",
  "video",
  "document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "text/plain",
];

const mediaFileTypes = ["audio", "video"];

export const getTabsForPreset = (preset) => {
  const presets = {
    podcast: {
      contentTabs: [
        {
          id: 0,
          name: CONTENT_TYPES.SHOW_NOTES.name,
          value: CONTENT_TYPES.SHOW_NOTES.value,
          container: CONTENT_TYPES.SHOW_NOTES.container,
          actionButton: "edit",
        },
        {
          id: 1,
          name: CONTENT_TYPES.SUMMARY.name,
          value: CONTENT_TYPES.SUMMARY.value,
          container: CONTENT_TYPES.SUMMARY.container,
          actionButton: "edit",
        },
        {
          id: 2,
          name: CONTENT_TYPES.QUOTES.name,
          value: CONTENT_TYPES.QUOTES.value,
          container: CONTENT_TYPES.QUOTES.container,
          actionButton: "edit",
        },
        {
          id: 3,
          name: CONTENT_TYPES.CHAPTERS.name,
          value: CONTENT_TYPES.CHAPTERS.value,
          container: CONTENT_TYPES.CHAPTERS.container,
          actionButton: "edit",
        },
        {
          id: 4,
          name: CONTENT_TYPES.TRANSCRIPT.name,
          value: CONTENT_TYPES.TRANSCRIPT.value,
          container: CONTENT_TYPES.TRANSCRIPT.container,
          actionButton: "find",
        },
        {
          id: 5,
          name: CONTENT_TYPES.ARTICLE.name,
          value: CONTENT_TYPES.ARTICLE.value,
          container: CONTENT_TYPES.ARTICLE.container,
          actionButton: "edit",
        },
      ],

      socialTabs: [
        {
          id: 6,
          name: CONTENT_TYPES.TWEET_THREAD.name,
          value: CONTENT_TYPES.TWEET_THREAD.value,
          container: CONTENT_TYPES.TWEET_THREAD.container,
          actionButton: "edit",
        },
        {
          id: 7,
          name: CONTENT_TYPES.CAPTIONS.name,
          value: CONTENT_TYPES.CAPTIONS.value,
          container: CONTENT_TYPES.CAPTIONS.container,
          actionButton: "edit",
        },
        {
          id: 8,
          name: CONTENT_TYPES.FOCUS_KEYWORDS.name,
          value: CONTENT_TYPES.FOCUS_KEYWORDS.value,
          container: CONTENT_TYPES.FOCUS_KEYWORDS.container,
          actionButton: "edit",
        },
        {
          id: 9,
          name: CONTENT_TYPES.TITLE_SUGGESTIONS.name,
          value: CONTENT_TYPES.TITLE_SUGGESTIONS.value,
          container: CONTENT_TYPES.TITLE_SUGGESTIONS.container,
          actionButton: "edit",
        },
      ],

      mediaTabs: [
        {
          id: 10,
          name: CONTENT_TYPES.AUDIOGRAMS.name,
          value: CONTENT_TYPES.AUDIOGRAMS.value,
          container: CONTENT_TYPES.AUDIOGRAMS.container,
          fileTypes: ["audio", "video"],
          actionButton: null,
        },
        {
          id: 11,
          name: CONTENT_TYPES.REELS.name,
          value: CONTENT_TYPES.REELS.value,
          container: CONTENT_TYPES.REELS.container,
          fileTypes: ["video"],
          actionButton: null,
        },
      ],

      specialTabs: [
        {
          id: 12,
          name: "Ask Deciphr",
          value: "askDeciphr",
          container: AskDeciphrContainer,
          actionButton: null,
        },
      ],

      createdContentTabs: [
        {
          id: 13,
          name: CONTENT_TYPES.WHITE_PAPER.name,
          value: CONTENT_TYPES.WHITE_PAPER.value,
          container: CONTENT_TYPES.WHITE_PAPER.container,
          actionButton: "edit",
        },
        {
          id: 14,
          name: CONTENT_TYPES.FAQS.name,
          value: CONTENT_TYPES.FAQS.value,
          container: CONTENT_TYPES.FAQS.container,
          actionButton: "edit",
        },
        {
          id: 15,
          name: CONTENT_TYPES.NEWSLETTER.name,
          value: CONTENT_TYPES.NEWSLETTER.value,
          container: CONTENT_TYPES.NEWSLETTER.container,
          actionButton: "edit",
        },
        {
          id: 16,
          name: CONTENT_TYPES.LANDING_PAGE.name,
          value: CONTENT_TYPES.LANDING_PAGE.value,
          container: CONTENT_TYPES.LANDING_PAGE.container,
          actionButton: "edit",
        },
        {
          id: 17,
          name: CONTENT_TYPES.IMPORTANT_DATES.name,
          value: CONTENT_TYPES.IMPORTANT_DATES.value,
          container: CONTENT_TYPES.IMPORTANT_DATES.container,
          actionButton: "edit",
        },
        {
          id: 18,
          name: CONTENT_TYPES.Q_AND_A.name,
          value: CONTENT_TYPES.Q_AND_A.value,
          container: CONTENT_TYPES.Q_AND_A.container,
          actionButton: "edit",
        },
        {
          id: 19,
          name: CONTENT_TYPES.FOLLOW_UP_EMAILS.name,
          value: CONTENT_TYPES.FOLLOW_UP_EMAILS.value,
          container: CONTENT_TYPES.FOLLOW_UP_EMAILS.container,
          actionButton: "edit",
        },
      ],

      defaultTabsForPlan: {
        "Flexi Free": { tabId: 0, value: CONTENT_TYPES.SHOW_NOTES.value, tabType: "content" },
        Professional: { tabId: 0, value: CONTENT_TYPES.SHOW_NOTES.value, tabType: "content" },
        Elite: { tabId: 0, value: CONTENT_TYPES.SHOW_NOTES.value, tabType: "content" },
      },
    },

    meeting: {
      contentTabs: [
        {
          id: 0,
          name: CONTENT_TYPES.MEETING_MINUTES.name,
          value: CONTENT_TYPES.MEETING_MINUTES.value,
          container: CONTENT_TYPES.MEETING_MINUTES.container,
          actionButton: "edit",
        },
        {
          id: 1,
          name: CONTENT_TYPES.SUMMARY.name,
          value: CONTENT_TYPES.SUMMARY.value,
          container: CONTENT_TYPES.SUMMARY.container,
          actionButton: "edit",
        },
        {
          id: 2,
          name: CONTENT_TYPES.CHAPTERS.name,
          value: CONTENT_TYPES.CHAPTERS.value,
          container: CONTENT_TYPES.CHAPTERS.container,
          actionButton: "edit",
        },
        {
          id: 3,
          name: CONTENT_TYPES.ACTION_ITEMS.name,
          value: CONTENT_TYPES.ACTION_ITEMS.value,
          container: CONTENT_TYPES.ACTION_ITEMS.container,
          actionButton: "edit",
        },
        {
          id: 4,
          name: CONTENT_TYPES.TRANSCRIPT.name,
          value: CONTENT_TYPES.TRANSCRIPT.value,
          container: CONTENT_TYPES.TRANSCRIPT.container,
          actionButton: "find",
        },
        {
          id: 5,
          name: CONTENT_TYPES.DECISION_LOG.name,
          value: CONTENT_TYPES.DECISION_LOG.value,
          container: CONTENT_TYPES.DECISION_LOG.container,
          actionButton: "edit",
        },
        {
          id: 6,
          name: CONTENT_TYPES.IMPORTANT_DATES.name,
          value: CONTENT_TYPES.IMPORTANT_DATES.value,
          container: CONTENT_TYPES.IMPORTANT_DATES.container,
          actionButton: "edit",
        },
        {
          id: 7,
          name: CONTENT_TYPES.Q_AND_A.name,
          value: CONTENT_TYPES.Q_AND_A.value,
          container: CONTENT_TYPES.Q_AND_A.container,
          actionButton: "edit",
        },
        {
          id: 8,
          name: CONTENT_TYPES.NEXT_AGENDA.name,
          value: CONTENT_TYPES.NEXT_AGENDA.value,
          container: CONTENT_TYPES.NEXT_AGENDA.container,
          actionButton: "edit",
        },
        {
          id: 9,
          name: CONTENT_TYPES.FOLLOW_UP_EMAILS.name,
          value: CONTENT_TYPES.FOLLOW_UP_EMAILS.value,
          container: CONTENT_TYPES.FOLLOW_UP_EMAILS.container,
          actionButton: "edit",
        },
      ],

      socialTabs: [],

      mediaTabs: [
        {
          id: 10,
          name: CONTENT_TYPES.AUDIOGRAMS.name,
          value: CONTENT_TYPES.AUDIOGRAMS.value,
          container: CONTENT_TYPES.AUDIOGRAMS.container,
          fileTypes: ["audio", "video"],
          actionButton: null,
        },
        {
          id: 11,
          name: CONTENT_TYPES.REELS.name,
          value: CONTENT_TYPES.REELS.value,
          container: CONTENT_TYPES.REELS.container,
          fileTypes: ["video"],
          actionButton: null,
        },
      ],

      specialTabs: [
        {
          id: 12,
          name: "Ask Deciphr",
          value: "askDeciphr",
          container: AskDeciphrContainer,
          actionButton: null,
        },
      ],

      createdContentTabs: [
        {
          id: 13,
          name: CONTENT_TYPES.SUMMARY.name,
          value: CONTENT_TYPES.SUMMARY.value,
          container: CONTENT_TYPES.SUMMARY.container,
          actionButton: "edit",
        },
        {
          id: 14,
          name: CONTENT_TYPES.QUOTES.name,
          value: CONTENT_TYPES.QUOTES.value,
          container: CONTENT_TYPES.QUOTES.container,
          actionButton: "edit",
        },
        {
          id: 15,
          name: CONTENT_TYPES.SESSION_RECAP.name,
          value: CONTENT_TYPES.SESSION_RECAP.value,
          container: CONTENT_TYPES.SESSION_RECAP.container,
          actionButton: "edit",
        },
        {
          id: 16,
          name: CONTENT_TYPES.WHITE_PAPER.name,
          value: CONTENT_TYPES.WHITE_PAPER.value,
          container: CONTENT_TYPES.WHITE_PAPER.container,
          actionButton: "edit",
        },
        {
          id: 17,
          name: CONTENT_TYPES.FAQS.name,
          value: CONTENT_TYPES.FAQS.value,
          container: CONTENT_TYPES.FAQS.container,
          actionButton: "edit",
        },
        {
          id: 18,
          name: CONTENT_TYPES.NEWSLETTER.name,
          value: CONTENT_TYPES.NEWSLETTER.value,
          container: CONTENT_TYPES.NEWSLETTER.container,
          actionButton: "edit",
        },
        {
          id: 19,
          name: CONTENT_TYPES.LANDING_PAGE.name,
          value: CONTENT_TYPES.LANDING_PAGE.value,
          container: CONTENT_TYPES.LANDING_PAGE.container,
          actionButton: "edit",
        },
      ],

      defaultTabsForPlan: {
        "Flexi Free": { tabId: 0, value: CONTENT_TYPES.MEETING_MINUTES.value, tabType: "content" },
        Professional: { tabId: 0, value: CONTENT_TYPES.MEETING_MINUTES.value, tabType: "content" },
        Elite: { tabId: 0, value: CONTENT_TYPES.MEETING_MINUTES.value, tabType: "content" },
      },
    },

    webinar: {
      contentTabs: [
        {
          id: 0,
          name: CONTENT_TYPES.SESSION_RECAP.name,
          value: CONTENT_TYPES.SESSION_RECAP.value,
          container: CONTENT_TYPES.SESSION_RECAP.container,
          actionButton: "edit",
        },
        {
          id: 1,
          name: CONTENT_TYPES.QUOTES.name,
          value: CONTENT_TYPES.QUOTES.value,
          container: CONTENT_TYPES.QUOTES.container,
          actionButton: "edit",
        },
        {
          id: 2,
          name: CONTENT_TYPES.CHAPTERS.name,
          value: CONTENT_TYPES.CHAPTERS.value,
          container: CONTENT_TYPES.CHAPTERS.container,
          actionButton: "edit",
        },
        {
          id: 3,
          name: CONTENT_TYPES.ARTICLE.name,
          value: CONTENT_TYPES.ARTICLE.value,
          container: CONTENT_TYPES.ARTICLE.container,
          actionButton: "edit",
        },
        {
          id: 4,
          name: CONTENT_TYPES.TRANSCRIPT.name,
          value: CONTENT_TYPES.TRANSCRIPT.value,
          container: CONTENT_TYPES.TRANSCRIPT.container,
          actionButton: "find",
        },
        {
          id: 5,
          name: CONTENT_TYPES.WHITE_PAPER.name,
          value: CONTENT_TYPES.WHITE_PAPER.value,
          container: CONTENT_TYPES.WHITE_PAPER.container,
          actionButton: "edit",
        },
        {
          id: 6,
          name: CONTENT_TYPES.FAQS.name,
          value: CONTENT_TYPES.FAQS.value,
          container: CONTENT_TYPES.FAQS.container,
          actionButton: "edit",
        },
        {
          id: 7,
          name: CONTENT_TYPES.NEWSLETTER.name,
          value: CONTENT_TYPES.NEWSLETTER.value,
          container: CONTENT_TYPES.NEWSLETTER.container,
          actionButton: "edit",
        },
        {
          id: 8,
          name: CONTENT_TYPES.LANDING_PAGE.name,
          value: CONTENT_TYPES.LANDING_PAGE.value,
          container: CONTENT_TYPES.LANDING_PAGE.container,
          actionButton: "edit",
        },
        {
          id: 9,
          name: CONTENT_TYPES.CAPTIONS.name,
          value: CONTENT_TYPES.CAPTIONS.value,
          container: CONTENT_TYPES.CAPTIONS.container,
          actionButton: "edit",
        },
      ],

      socialTabs: [],

      mediaTabs: [
        {
          id: 10,
          name: CONTENT_TYPES.AUDIOGRAMS.name,
          value: CONTENT_TYPES.AUDIOGRAMS.value,
          container: CONTENT_TYPES.AUDIOGRAMS.container,
          fileTypes: ["audio", "video"],
          actionButton: null,
        },
        {
          id: 11,
          name: CONTENT_TYPES.REELS.name,
          value: CONTENT_TYPES.REELS.value,
          container: CONTENT_TYPES.REELS.container,
          fileTypes: ["video"],
          actionButton: null,
        },
      ],

      specialTabs: [
        {
          id: 12,
          name: "Ask Deciphr",
          value: "askDeciphr",
          container: AskDeciphrContainer,
          actionButton: null,
        },
      ],

      createdContentTabs: [
        {
          id: 13,
          name: CONTENT_TYPES.SHOW_NOTES.name,
          value: CONTENT_TYPES.SHOW_NOTES.value,
          container: CONTENT_TYPES.SHOW_NOTES.container,
          actionButton: "edit",
        },
        {
          id: 14,
          name: CONTENT_TYPES.SUMMARY.name,
          value: CONTENT_TYPES.SUMMARY.value,
          container: CONTENT_TYPES.SUMMARY.container,
          actionButton: "edit",
        },
        {
          id: 15,
          name: CONTENT_TYPES.QUOTES.name,
          value: CONTENT_TYPES.QUOTES.value,
          container: CONTENT_TYPES.QUOTES.container,
          actionButton: "edit",
        },
        {
          id: 16,
          name: CONTENT_TYPES.CHAPTERS.name,
          value: CONTENT_TYPES.CHAPTERS.value,
          container: CONTENT_TYPES.CHAPTERS.container,
          actionButton: "edit",
        },
        {
          id: 17,
          name: CONTENT_TYPES.ARTICLE.name,
          value: CONTENT_TYPES.ARTICLE.value,
          container: CONTENT_TYPES.ARTICLE.container,
          actionButton: "edit",
        },
        {
          id: 18,
          name: CONTENT_TYPES.TWEET_THREAD.name,
          value: CONTENT_TYPES.TWEET_THREAD.value,
          container: CONTENT_TYPES.TWEET_THREAD.container,
          actionButton: "edit",
        },
        {
          id: 19,
          name: CONTENT_TYPES.CAPTIONS.name,
          value: CONTENT_TYPES.CAPTIONS.value,
          container: CONTENT_TYPES.CAPTIONS.container,
          actionButton: "edit",
        },
        {
          id: 20,
          name: CONTENT_TYPES.FOCUS_KEYWORDS.name,
          value: CONTENT_TYPES.FOCUS_KEYWORDS.value,
          container: CONTENT_TYPES.FOCUS_KEYWORDS.container,
          actionButton: "edit",
        },
        {
          id: 21,
          name: CONTENT_TYPES.TITLE_SUGGESTIONS.name,
          value: CONTENT_TYPES.TITLE_SUGGESTIONS.value,
          container: CONTENT_TYPES.TITLE_SUGGESTIONS.container,
          actionButton: "edit",
        },
        {
          id: 22,
          name: CONTENT_TYPES.MEETING_MINUTES.name,
          value: CONTENT_TYPES.MEETING_MINUTES.value,
          container: CONTENT_TYPES.MEETING_MINUTES.container,
          actionButton: "edit",
        },
        {
          id: 23,
          name: CONTENT_TYPES.ACTION_ITEMS.name,
          value: CONTENT_TYPES.ACTION_ITEMS.value,
          container: CONTENT_TYPES.ACTION_ITEMS.container,
          actionButton: "edit",
        },
        {
          id: 24,
          name: CONTENT_TYPES.IMPORTANT_DATES.name,
          value: CONTENT_TYPES.IMPORTANT_DATES.value,
          container: CONTENT_TYPES.IMPORTANT_DATES.container,
          actionButton: "edit",
        },
        {
          id: 25,
          name: CONTENT_TYPES.Q_AND_A.name,
          value: CONTENT_TYPES.Q_AND_A.value,
          container: CONTENT_TYPES.Q_AND_A.container,
          actionButton: "edit",
        },
        {
          id: 26,
          name: CONTENT_TYPES.NEXT_AGENDA.name,
          value: CONTENT_TYPES.NEXT_AGENDA.value,
          container: CONTENT_TYPES.NEXT_AGENDA.container,
          actionButton: "edit",
        },
        {
          id: 27,
          name: CONTENT_TYPES.FOLLOW_UP_EMAILS.name,
          value: CONTENT_TYPES.FOLLOW_UP_EMAILS.value,
          container: CONTENT_TYPES.FOLLOW_UP_EMAILS.container,
          actionButton: "edit",
        },
        {
          id: 28,
          name: CONTENT_TYPES.SESSION_RECAP.name,
          value: CONTENT_TYPES.SESSION_RECAP.value,
          container: CONTENT_TYPES.SESSION_RECAP.container,
          actionButton: "edit",
        },
        {
          id: 29,
          name: CONTENT_TYPES.WHITE_PAPER.name,
          value: CONTENT_TYPES.WHITE_PAPER.value,
          container: CONTENT_TYPES.WHITE_PAPER.container,
          actionButton: "edit",
        },
        {
          id: 30,
          name: CONTENT_TYPES.FAQS.name,
          value: CONTENT_TYPES.FAQS.value,
          container: CONTENT_TYPES.FAQS.container,
          actionButton: "edit",
        },
        {
          id: 31,
          name: CONTENT_TYPES.NEWSLETTER.name,
          value: CONTENT_TYPES.NEWSLETTER.value,
          container: CONTENT_TYPES.NEWSLETTER.container,
          actionButton: "edit",
        },
        {
          id: 32,
          name: CONTENT_TYPES.LANDING_PAGE.name,
          value: CONTENT_TYPES.LANDING_PAGE.value,
          container: CONTENT_TYPES.LANDING_PAGE.container,
          actionButton: "edit",
        },
      ],

      defaultTabsForPlan: {
        "Flexi Free": { tabId: 0, value: CONTENT_TYPES.SESSION_RECAP.value, tabType: "content" },
        Professional: { tabId: 0, value: CONTENT_TYPES.SESSION_RECAP.value, tabType: "content" },
        Elite: { tabId: 0, value: CONTENT_TYPES.SESSION_RECAP.value, tabType: "content" },
      },
    },
  };

  return presets[preset || "podcast"];
};

export const askDeciphrTab = {
  id: 12,
  name: "Ask Deciphr",
  value: "askDeciphr",
  fileTypes: ["audio", "video"],
  actionButton: null,
};

export const getSidebarLinks = (preset) => {
  const { contentTabs, socialTabs, mediaTabs } = getTabsForPreset(preset);
  const categories = [];

  categories.push({
    id: 3,
    name: "General",
    fileTypes: contentFileTypes,
    icon: GeneralContentIcon,
    tabs: contentTabs,
    type: "content",
  });

  if (mediaTabs.length) {
    categories.push({
      id: 2,
      name: "Multimedia",
      fileTypes: mediaFileTypes,
      icon: MultimediaContentIcon,
      tabs: mediaTabs,
      type: "media",
    });
  }

  if (socialTabs.length) {
    categories.push({
      id: 4,
      name: "Social",
      fileTypes: contentFileTypes,
      icon: SocialContentIcon,
      tabs: socialTabs,
      type: "content",
    });
  }

  return categories;
};

export const getContainerTab = (tabId, preset) => {
  const { contentTabs, socialTabs, mediaTabs, specialTabs, createdContentTabs } = getTabsForPreset(preset);
  const tabs = [...contentTabs, ...socialTabs, ...mediaTabs, ...specialTabs, ...createdContentTabs];
  const tab = tabs.find((t) => t.value === tabId);

  if (tab) {
    return tab.container;
  }

  return null;
};

// export const unifiedTabs = [...podcastContentTabs, ...socialContentTabs, ...mediaTabs];

// export const getTabById = (id) => unifiedTabs.find((tab) => tab.id === parseInt(id, 10));

export const getDefaultTab = (planName, preset, fileType = "") => {
  const documentTypes = ["text", "document"];
  const type = fileType.split("/")[0];
  const tabs = getTabsForPreset(preset);

  if (documentTypes.indexOf(type) > -1) {
    const firstTab = tabs.contentTabs[0];

    return { tabId: 0, tabValue: firstTab.value, tabType: "content" };
  }

  const defaultTab = tabs.defaultTabsForPlan[planName];
  const tabId = defaultTab?.tabId || 0;
  const tabValue = defaultTab?.value;
  const tabType = defaultTab?.tabType || "content";

  return { tabId, tabValue, tabType };
};
