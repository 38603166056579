import PropTypes from "prop-types";
import theme from "theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DAIButton from "Core/components/DAIButton";
import ModalDialog from "Core/components/Modal";
import ModalBody from "Core/components/Modal/body";
import ModalHeader from "Core/components/Modal/header";
import SectionDescription from "./sectionDescription";
import SectionHeader from "./sectionHeader";
import ToggleOptions from "./toggleOptions";
import { ReactComponent as DownloadIcon } from "assets/icons/download-content.svg";

export default function DownloadPopup({
  open,
  isLoading,
  writtenOpts,
  mediaOpts,
  onWrittenOptionToggle,
  onMediaOptionToggle,
  onDownload,
  onClose,
}) {
  return (
    <ModalDialog
      open={open}
      maxWidth="sm"
      onClose={onClose}
    >
      <ModalHeader icon={<DownloadIcon />} onClose={onClose}>
        <Typography
          component="span"
          sx={{
            color: theme.palette.gray[700],
            fontFamily: "Satoshi",
            fontSize: "24px",
            fontWeight: 900,
            letterSpacing: "0.15px",
            mb: "24px",
          }}
        >
          Select content to download
        </Typography>
      </ModalHeader>

      <ModalBody>
        <Box
          sx={{
            display: "flex",
            gap: "64px",
            minWidth: {
              sm: "500px",
            },
          }}
        >
          <Box component="section" sx={{ flex: 1, minWidth: "184px" }}>
            <SectionHeader>
              Content
            </SectionHeader>

            <SectionDescription>
              Download as DOC file
            </SectionDescription>

            <ToggleOptions options={writtenOpts} onToggle={onWrittenOptionToggle} />
          </Box>

          <Box component="section" sx={{ flex: 1 }}>
            <SectionHeader>
              Media
            </SectionHeader>

            <SectionDescription>
              Download as MP4 file
            </SectionDescription>

            <ToggleOptions options={mediaOpts} onToggle={onMediaOptionToggle} />
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: "12px", mt: "32px" }}>
          <DAIButton
            variant="outlined"
            isBlock
            sx={{ fontFamily: "Satoshi", fontSize: "16px", fontWeight: 700, mx: 0 }}
            onClick={onClose}
          >
            Cancel
          </DAIButton>

          <DAIButton
            isBlock
            disabled={isLoading}
            loading={isLoading}
            loadingPosition="start"
            sx={{ fontFamily: "Satoshi", fontSize: "16px", fontWeight: 700, mx: 0 }}
            onClick={onDownload}
          >
            Download Selected
          </DAIButton>
        </Box>
      </ModalBody>
    </ModalDialog>
  );
}

DownloadPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  writtenOpts: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    selected: PropTypes.bool,
  })).isRequired,
  mediaOpts: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    selected: PropTypes.bool,
  })).isRequired,
  onWrittenOptionToggle: PropTypes.func.isRequired,
  onMediaOptionToggle: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
