import { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { getContainerTab } from "DAI/ContentDetails/containers/ContentLinks/data";
import OutputRatingContainer from "DAI/ContentDetails/containers/OutputRatingContainer";
import ContentLoadingState from "DAI/ContentDetails/components/ContentLoadingState";
import FileErrorModal from "DAI/Pricing/components/fileLimits/Modal";
import { getContentTypeLabel, presetContentMap } from "DAI/ContentDetails/services/presets";
import { selectIsLoading, selectIsRecheck, selectPreset } from "DAI/ContentDetails/store/contentSlice";
import { isStreamingSelector } from "DAI/ProcessedData/store/writtenContentSlice";
import { analytics } from "utils/GTM";

export default function ContentContainer({ tabId }) {
  const preset = useSelector(selectPreset);
  const isLoading = useSelector(selectIsLoading);
  const isRecheck = useSelector(selectIsRecheck);
  const isStreaming = useSelector(isStreamingSelector);

  useEffect(() => {
    if (tabId) {
      if (tabId !== "askDeciphr") {
        const contentType = presetContentMap[preset]?.[tabId];

        if (contentType) {
          analytics.track(`View Main Topic ${getContentTypeLabel(contentType)} Page`);
        }
      }
    }
  }, [tabId]);

  if (tabId !== undefined) {
    const Content = getContainerTab(tabId, preset);

    if (isLoading && !isRecheck) {
      return (
        <ContentLoadingState />
      );
    }

    return (
      <Box
        sx={[
          { position: "relative" },
        ]}
      >
        {Content === null && (<ContentLoadingState />)}

        {Content !== null && (<Content key={`${tabId}+${isStreaming}`} />)}

        {Content !== null && !isStreaming && (
          <OutputRatingContainer />
        )}

        <FileErrorModal />
      </Box>
    );
  }

  return false;
}

ContentContainer.propTypes = {
  tabId: PropTypes.string,
};

ContentContainer.defaultProps = {
  tabId: undefined,
};
