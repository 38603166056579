import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import VideoPlayer from "DAI/ProcessedData/containers/AudiogramPlayer";

export default function PreviewAudiogramPopup({
  handleClose,
  downloadHandler,
  audio,
  open,
  selectedAudiogram,
  loading,
}) {
  const onDownload = () => {
    downloadHandler({
      audiogram_id: selectedAudiogram.id,
      video_url: selectedAudiogram.video_url,
    });
  };

  return (
    <Dialog
      open={open}
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={styles.PaperProps}
      maxWidth={"sm"}
    >
      <CancelButton handleClose={handleClose} />

      <Box sx={styles.root}>
        <Box sx={styles.videoWrapper}>
          {open && (
            <VideoPlayer
              selectedAudiogram={selectedAudiogram}
              audioSrc={audio}
              isEdit={open.isEdit}
              download={onDownload}
              loading={loading}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CancelButton = ({ handleClose }) => (
  <IconButton onClick={handleClose} sx={styles.iconwrapper}>
    <CloseIcon sx={styles.icon} />
  </IconButton>
);

const styles = {
  iconwrapper: {
    position: "absolute",
    top: "16px",
    right: "16px",
    zIndex: 2,
  },

  icon: {
    fontSize: "24px",
    color: "#000",
  },

  PaperProps: {
    sx: {
      borderRadius: "20px",
      // backgroundColor: "#EEEFF2",
    },
  },
  videoWrapper: {
    width: "600px",
  },
  root: {
    display: "flex",
  },
};
