import theme from "theme";
import confetti from "assets/confetti.svg";

const styles = {
  root: {
    height: "100%",
    position: "sticky",
    top: "110px",
  },

  loading: {
    opacity: 0,
  },

  title: {
    fontFamily: "Satoshi",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "24px",
    letterSpacing: "0.04em",
    textTransform: "uppercase",
    color: theme.palette.gray[500],
  },

  links: {
    flexGrow: 1,
  },

  listItem: {
    paddingLeft: "0px",
    position: "relative",
    margin: 0,
  },
  itemWrapper: {
    marginTop: "24px",
  },

  sparkle: {
    background: theme.palette.brand[500],
    color: "white",
    borderRadius: "9999px",
    height: "32px",
    width: "32px",
  },

  credits: {
    borderTop: `2px solid ${theme.palette.gray[200]}`,
    padding: "36px 42px 42px 42px",
  },

  creditLeftText: {
    fontFamily: "DM Sans",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "21px",
    color: theme.palette.gray[500],
    marginTop: "1rem",
    mb: "12px",
  },

  confettiBtn: {
    position: "relative",
    height: "77px",
    width: "198px",
    background: `url("${confetti}")`,
  },

  askDeciphrBtn: {
    margin: "2rem 2rem 0 2.5rem",
    paddingBottom: "0.5rem",
  },

  newContentBtn: {
    fontFamily: "Satoshi",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "32px",
    display: "flex",
    alignItems: "center",
    gap: "13px",

    svg: {
      height: "20px",
      width: "auto",
    },
  },
  dfyLinks: {
    borderTop: "1px solid #E3E6EA",
    paddingTop: "35px",
    marginTop: "35px",
  },
  createdContentTitle: {
    mb: "24px",
  },
};

export default styles;
