import React, { useState, useRef} from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import TimeFormat from "DAI/ProcessedData/components/AudiogramComponents/audiogramPlayer/timeformat";
import PlayPauseButtons from "DAI/ProcessedData/components/AudiogramComponents/audiogramPlayer/playPauseButtns";
import Progressbar from "DAI/ProcessedData/components/AudiogramComponents/audiogramPlayer/progressbar";
import Player from "DAI/ProcessedData/components/AudiogramComponents/audiogramPlayer/player";
import VolumBtn from "DAI/ProcessedData/components/AudiogramComponents/audiogramPlayer/volumBtn";
import DownloadBtn from "DAI/ProcessedData/components/AudiogramComponents/audiogramPlayer/donwloadBtn";
import { styles } from "./style";
import {
  setTime,
  timeSelector,
  progressSelector,
  setProgress,
  listOfPendingAudiogramsIdSelector,
} from "store/audiogramSlice";

const VideoComponent = ({ audioSrc, download, isEdit, selectedAudiogram }) => {
  const { id: audiogramId, start, end, background_url, video_url, loading } = selectedAudiogram;
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const time = useSelector(timeSelector);
  const progress = useSelector(progressSelector);
  const listOfPendingAudiogramsId = useSelector(
    listOfPendingAudiogramsIdSelector
  );
  const dispatch = useDispatch();
  const isGenerating = listOfPendingAudiogramsId.includes(audiogramId);

  const handlePlayClick = () => {
    setIsPlaying(!isPlaying);
    if (isPlaying) {
      pause();
    }else{
      play();
    }
  };

  const play = () => {
    const video = videoRef.current;
    video.play();
  };

  const pause = () => {
    const video = videoRef.current;
    video.pause();
  };

  const handleMute = () => {
    const video = videoRef.current;
    video.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  const handleProgressChange = (value) => {
    const video = videoRef.current;
    const newProgress = (value / 100) * video.duration;
    video.currentTime = newProgress;
    dispatch(setProgress(value));
  };


  const handleVideoTimeUpdate = () => {
    const video = videoRef.current;
    const currentTime = video.currentTime;
    dispatch(setTime(video.currentTime));
    dispatch(setProgress((currentTime / video.duration) * 100));
  };

  return (
    <Box sx={styles.root}>
      <Box sx={{ height: "600px" }}>
        <Player
          src={background_url}
          audioSrc={audioSrc}
          handleVideoTimeUpdate={handleVideoTimeUpdate}
          ref={videoRef}
          actualVideo={video_url}
          isEdit={isEdit}
        />
      </Box>
      <Box
        sx={{
          ...styles.controls,
          ...(isEdit ? styles.controlFullWidth : styles.controlHalfWidth),
        }}
      >
        <PlayPauseButtons
          isPlaying={isPlaying}
          handlePlayClick={handlePlayClick}
        />
        <Box sx={styles.progressbarWrapper}>
          <TimeFormat start={start} end={end} time={time} isEdit={isEdit} />
          <Progressbar
            handleProgressChange={handleProgressChange}
            progress={progress}
          />
        </Box>
        <VolumBtn isMuted={isMuted} handleMute={handleMute} />
      </Box>
      {!isEdit && (
        <DownloadBtn
          downloadLoader={loading}
          download={download}
          isGenerating={isGenerating}
        />
      )}
    </Box>
  );
};

export default VideoComponent;
