import React from "react";
import { Box, IconButton } from "@mui/material";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

export default function VolumBtn({ isMuted, handleMute }) {
  return (
    <Box>
      <IconButton aria-label={isMuted ? "mute" : "unmute"} onClick={handleMute}>
        {!isMuted ? (
          <VolumeUpIcon sx={{ fontSize: "2rem", color: "#fff" }} />
        ) : (
          <VolumeOffIcon sx={{ fontSize: "2rem", color: "#fff" }} />
        )}
      </IconButton>
    </Box>
  );
}
