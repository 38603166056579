import PropTypes from "prop-types";
import { Stack } from "@mui/material";
import styles from "DAI/ContentDetails/components/FileTypeIcon/styles";
import { ReactComponent as IconAudio } from "assets/icon-audio.svg";
import { ReactComponent as IconDoc } from "assets/icon-doc.svg";
import { ReactComponent as IconVideo } from "assets/icon-video.svg";

const fileTypeToIconsMap = {
  audio: IconAudio,
  document: IconDoc,
  video: IconVideo,
  undefined: IconDoc, // default
};

const supportedFileTypes = ["audio", "document", "video"];

export default function FileTypeIcon({ fileType }) {
  const index = supportedFileTypes.indexOf(fileType);
  const Icon = fileTypeToIconsMap[supportedFileTypes[index]];

  return (
    <Stack justifyContent="center">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        aria-hidden="true"
        sx={styles.icon}
      >
        <Icon />
      </Stack>
    </Stack>
  );
}

FileTypeIcon.propTypes = {
  fileType: PropTypes.string,
};

FileTypeIcon.defaultProps = {
  fileType: "",
};
