/**
 * Format time given in seconds into mm:ss format
 *
 * @param {number} timeInSeconds
 */
export const formatTime = (timeInSeconds) => {
  if (timeInSeconds < 0) {
    return "00:00";
  }

  const minutes = Math.floor(timeInSeconds / 60)
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor(timeInSeconds % 60)
    .toString()
    .padStart(2, "0");

  return `${minutes}:${seconds}`;
};

export default {
  formatTime,
};
