import Typography from "@mui/material/Typography";

const TimeFormat = ({ time, length }) => {
  //convert length to minutes and seconds
  const minutes = Math.floor(length / 60);
  const seconds = Math.floor(length - minutes * 60);

  //convert time to minutes and seconds
  const startMinutes = Math.floor(time / 60);
  const startSeconds = Math.floor(time - startMinutes * 60);

  return (
    <Typography sx={styles.time}>
      {startMinutes}:{startSeconds < 10 ? `0${startSeconds}` : startSeconds} /{" "}
      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </Typography>
  );
};

export default TimeFormat;

const styles = {
  time: {
    color: "#FFFFFF",
    fontSize: "10px",
    fontWeight: "400",
    lineHeight: "12px",
  },
};
