import { useMemo } from "react";
import PropType from "prop-types";
import theme from "theme";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import DAIButton from "Core/components/DAIButton";
import { mapKeysToCamelCase } from "utils/axiosConfig";
import VideoThumbnail from "../videoReels/VideoThumbnail";
import ExpandableText from "./ExpandableText";
import { ReactComponent as AiAssistantSparkle } from "assets/content-details/ai-assistant.svg";
import { ReactComponent as DownloadCurvedIcon } from "assets/content-details/download-curved.svg";
import { ReactComponent as PaintBrushIcon } from "assets/content-details/paint-brush.svg";

const styles = {
  root: {
    border: "1px solid #E3E6EA",
    borderRadius: "18px",
    padding: "29px 0px 35px 0",
  },
  thumbnail: {
    paddingLeft: "31px",
  },
  chip: {
    background: "#FCFCFD",
    border: "1px solid #E3E6EA",
    borderLeft: "none",
    borderRadius: "0px 8px 8px 0px",
    fontFamily: "Satoshi",
    fontSize: "12px",
    fontWeight: "700",
    lineHeight: "12px",
    letterSpacing: "0.06em",
    height: "30px",
    width: "170px",
    padding: "7px 17px 6.5px 30px",
    whiteSpace: "nowrap",
  },
  userChip: {
    color: theme.palette.gray[400],
  },
  aiChip: {
    color: theme.palette.brand[500],

    "& svg": {
      height: "16px",
    },
  },
  title: {
    fontFamily: "Satoshi",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "32px",
    color: "#23262F",
    paddingRight: "40px",
  },
  buttons: {
    marginTop: "28px",

    button: {
      margin: 0,
    },
  },
  customizeBtn: {
    color: "#1B2730",
    height: "40px",

    "& svg": {
      color: theme.palette.gray[400],
      marginRight: "12px",
    },
  },
  downloadBtn: {
    height: "40px",
    width: "40px",
  },
  content: {
    marginTop: "40px",
    paddingRight: "40px",
  },
  titleSkeleton: {
    fontSize: "20px",
    width: "95%",
  },
};

export default function MediaCard({
  media,
  isPending,
  isEditable,
  isLoading,
  onClickPlay,
  onClickCustomize,
  onClickDownload,
}) {
  const localMedia = useMemo(() => mapKeysToCamelCase(media), [media]);
  let duration = 0;

  if (localMedia?.start && localMedia?.end) {
    duration = Number(localMedia.end) - Number(localMedia.start);
  }

  const getChipData = () => {
    if (localMedia.isUserGenerated) {
      return [
        "USER GENERATED",
        {
          ...styles.chip,
          ...styles.userChip,
        },
      ];
    }

    return [
      "AI GENERATED",
      {
        ...styles.chip,
        ...styles.aiChip,
      },
    ];
  };

  const [chipLabel, chipStyles] = getChipData();

  return (
    <Stack
      sx={styles.root}
      direction="row"
      gap="52px"
    >
      <Stack
        justifyContent="space-between"
      >
        <Box sx={styles.thumbnail}>
          <VideoThumbnail
            videoUrl={localMedia.videoUrl}
            thumbUrl={localMedia.thumbnail}
            durationInMillis={duration}
            onClick={() => onClickPlay(media)}
          />
        </Box>

        <Stack
          sx={chipStyles}
          direction="row"
          alignItems="center"
          gap="8px"
        >
          {!localMedia.isUserGenerated && <AiAssistantSparkle />}

          {chipLabel}
        </Stack>
      </Stack>

      <Box>
        {localMedia?.title ? (
          <Typography component="h4" sx={styles.title}>
            {localMedia?.title}
          </Typography>
        ) : (
          <>
            <Skeleton variant="text" sx={styles.titleSkeleton} />

            <Skeleton variant="text" sx={styles.titleSkeleton} />
          </>
        )}

        <Stack
          direction="row"
          alignItems="center"
          gap="12px"
          sx={styles.buttons}
        >
          <DAIButton
            sx={styles.customizeBtn}
            variant="outlined"
            disabled={isPending || !isEditable}
            onClick={() => onClickCustomize(media)}
          >
            {isPending ? (
              <>Generating...</>
            ) : (
              <>
                <PaintBrushIcon />
                Customize
              </>
            )}
          </DAIButton>

          <DAIButton
            sx={styles.downloadBtn}
            iconButton
            variant="outlined"
            disabled={isPending}
            onClick={() => onClickDownload(media)}
          >
            <DownloadCurvedIcon />
          </DAIButton>
        </Stack>

        <Stack
          sx={styles.content}
          gap="38px"
        >
          <ExpandableText
            title="transcript"
            text={localMedia?.text}
          />

          <ExpandableText
            title="caption"
            text={localMedia?.caption}
          />
        </Stack>
      </Box>

    </Stack>
  );
}

MediaCard.propTypes = {
  media: PropType.oneOf([PropType.object, null]),
  isPending: PropType.bool,
  isEditable: PropType.bool,
  isLoading: PropType.bool,
  onClickPlay: PropType.func,
  onClickCustomize: PropType.func,
  onClickDownload: PropType.func,
};

MediaCard.defaultProps = {
  media: null,
  isPending: false,
  isEditable: true,
  isLoading: false,
  onClickPlay: () => { },
  onClickCustomize: () => { },
  onClickDownload: () => { },
};
