import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DAIButton from "Core/components/DAIButton";
import unlock from "assets/icons/unlock.svg";

export default function LockedContentBanner({ text, icon, onClick }) {
  return (
    <Box
      component="section"
      sx={{
        bgcolor: "#F4F5F6",
        border: "thin solid #E6E8EC",
        borderRadius: "24px",
        display: "flex",
        alignItems: "center",
        gap: "32px",
        padding: "64px",
        my: "24px",
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography
          component="h2"
          sx={{
            fontFamily: "DM Sans",
            fontWeight: 700,
            fontSize: "40px",
            color: "#23262F",
          }}
        >
          Did you know that
        </Typography>

        <Typography
          sx={{
            fontFamily: "DM Sans",
            fontSize: "20px",
            color: "#777E90",
            lineHeight: "160%",
            maxWidth: "480px",
            my: "24px",
            fontWeight: 500,
          }}
        >
          {text}
        </Typography>

        <DAIButton
          startIcon={<img src={unlock} alt="" aria-hidden width="16" height="16" />}
          sx={{ fontWeight: 700 }}
          onClick={onClick}
        >
          Unlock this content
        </DAIButton>
      </Box>

      <Box>
        <img
          src={icon}
          alt=""
          aria-hidden
          loading="lazy"
          style={{ maxWidth: "337px", width: "100%", height: "auto" }}
        />
      </Box>
    </Box>
  );
}

LockedContentBanner.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
