import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

export default function HeroTitle({ text, onEdit }) {
  const [headline, setHeadline] = useState(text);
  const [isEditing, setIsEditing] = useState(false);

  const makeTitleEditable = (e) => {
    setIsEditing(true);

    // So that it gets focus after the component is re-rendered
    setTimeout(() => {
      e.target.focus();
    }, 200);
  };

  const stopEditingAndUpdateTitle = (e) => {
    const newHeadline = e.target.textContent;

    setIsEditing(false);
    setHeadline(newHeadline);
    onEdit(newHeadline);
  };

  useEffect(() => {
    if (text) {
      setHeadline(text);
    }
  }, [text]);

  if (headline) {
    return (
      <Typography
        component="h1"
        contentEditable={isEditing}
        suppressContentEditableWarning
        sx={{
          fontFamily: "Satoshi",
          fontWeight: 700,
          fontSize: "36px",
          lineHeight: "47px",
          outline: 0,

          "@media screen and (max-width: 767px)": {
            fontSize: "24px",
            lineHeight: 1.4,
          },
        }}
        classes={{ underline: { borderColor: "red" } }}
        onPaste={stopEditingAndUpdateTitle}
        onDrop={stopEditingAndUpdateTitle}
        onClick={makeTitleEditable}
        onBlur={stopEditingAndUpdateTitle}
      >
        {headline}
      </Typography>
    );
  }

  return (
    <>
      <Skeleton height="48px" width="400px" />

      <Skeleton height="48px" width="250px" />
    </>
  );
}

HeroTitle.propTypes = {
  text: PropTypes.string,
  onEdit: PropTypes.func,
};

HeroTitle.defaultProps = {
  text: "",
  onEdit: () => { },
};
