import { useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Converter } from "showdown";
import theme from "theme";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { copyHTMLToClipboard } from "DAI/helpers/clipboard";
import { setToast } from "store/toastSlice";
import { analytics } from "utils/GTM";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";

const mdConverter = new Converter({ tables: true });

const styles = {
  root: {
    border: "1px solid #E3E6EA",
    borderRadius: "17px",
    background: "white",
    padding: "20px",
    width: "100%",
    fontFamily: "Satoshi",
    fontWeight: "500",
    lineHeight: "28.8px",
    textAlign: "left",
    marginBottom: "48px",

    "& h3": {
      fontFamily: "Satoshi",
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "32px",
    },

    "& p": {
      fontSize: "16px",
      marginBottom: "2rem",
    },

    "& li p": {
      margin: "0.875rem 0",
    },
  },

  copyBtn: {
    border: "none",
    outline: "none",
    background: "none",
    color: theme.palette.gray[400],
    transition: "all 0.3s",

    "&:hover": {
      color: theme.palette.brand[400],
    },
  },
};

const State = {
  initial: "initial",
  result: "result",
};

export default function AskDeciphrAnswer({ answer, state, show }) {
  const dispatch = useDispatch();
  const html = useMemo(() => mdConverter.makeHtml(answer), [answer]);

  const handleClickCopy = async () => {
    try {
      await copyHTMLToClipboard(html);

      dispatch(
        setToast({
          message: "Copied to clipboard",
          severity: "success",
          autoClose: true,
        }),
      );

      analytics.track("RAG Page Search Result Copied");
    } catch (e) {
      dispatch(
        setToast({
          message: "Copying failed",
          severity: "error",
          autoClose: true,
        }),
      );
    }
  };

  if (show) {
    return (
      <Box sx={styles.root}>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: html }} />

        {state === State.result && (
          <Stack direction="row-reverse">
            <Box sx={styles.copyBtn} component="button" onClick={handleClickCopy}>
              <CopyIcon height="1.25rem" />
            </Box>
          </Stack>
        )}
      </Box>
    );
  }

  return null;
}

AskDeciphrAnswer.propTypes = {
  answer: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
};
