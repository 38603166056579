import { useState } from "react";
import PropType from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import DAIButton from "Core/components/DAIButton";
import DAIMenu from "Core/components/DAIMenu";
import { stripHTML } from "DAI/helpers";
import styles from "DAI/ContentDetails/containers/HeroContainer/styles";
import DownloadContainer from "DAI/ProcessedData/containers/DownloadContent";
import FileTypeIcon from "DAI/ContentDetails/components/FileTypeIcon";
import HeroTitle from "DAI/ContentDetails/components/HeroTitle";
import { selectFileMetadata, updateHeadline } from "DAI/ContentDetails/store/contentSlice";
import { deleteFile } from "store/dai/librarySlice";
import ContentLinksDropdown from "../ContentLinksDropdown";
import ShareContentContainer from "../ShareContentContainer";
import { ReactComponent as ShareIcon } from "assets/content-details/share.svg";
import { ReactComponent as SparkleIcon } from "assets/content-details/sparkle.svg";
import { ReactComponent as TrashIcon } from "assets/content-details/trash.svg";

export const HeroContainerMode = {
  Base: "Base",
  Chat: "Chat",
};

export default function HeroContainer({
  mode,
  onClickChat,
}) {
  const metadata = useSelector(selectFileMetadata);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isShareOpen, setIsShareOpen] = useState(false);

  const handleOpenSharePopup = () => {
    setIsShareOpen(true);
  };

  const handleCloseSharePopup = () => {
    setIsShareOpen(false);
  };

  const menuItems = [
    {
      label: "Share",
      icon: ShareIcon,
      action: handleOpenSharePopup,
    },
    {
      label: "Delete",
      icon: TrashIcon,
      action: async () => {
        await dispatch(deleteFile(metadata.fileId)).unwrap();

        navigate("/library");
      },
    },
  ];

  const onSaveHeadLine = (headline) => {
    const cleanedHeadline = stripHTML(headline)?.trim();

    if (cleanedHeadline !== metadata.headline) {
      dispatch(updateHeadline({ headline: cleanedHeadline }));
    }
  };

  const goBack = () => {
    navigate("/library");
  };

  return (
    <Stack
      direction="row"
      sx={styles.root}
      gap="144px"
    >
      {mode === HeroContainerMode.Base && (
        <DAIButton
          sx={styles.backBtn}
          variant="outlined"
          iconButton
          onClick={goBack}
        >
          <ArrowBackRoundedIcon />
        </DAIButton>
      )}

      <Stack direction="row" alignItems="center" gap="32px">
        <Box
          alignSelf="start"
        >
          <FileTypeIcon fileType={metadata.fileType} />
        </Box>

        <Stack
          justifyContent="space-between"
        >
          <Box>
            <HeroTitle
              text={metadata.headline}
              onEdit={onSaveHeadLine}
            />

            <Stack
              sx={styles.actions}
              direction="row"
              alignItems="center"
              gap="16px"
            >
              {mode === HeroContainerMode.Base ? (
                <DAIButton
                  sx={styles.chatBtn}
                  startIcon={<SparkleIcon />}
                  onClick={onClickChat}
                >
                  Chat
                </DAIButton>
              ) : (
                <ContentLinksDropdown />
              )}

              <DownloadContainer />

              <DAIMenu items={menuItems} color="black">
                <MoreVertOutlinedIcon />
              </DAIMenu>
            </Stack>
          </Box>
        </Stack>
      </Stack>

      <ShareContentContainer
        hideBtn
        open={isShareOpen}
        onClose={handleCloseSharePopup}
      />
    </Stack>
  );
}

HeroContainer.propTypes = {
  mode: PropType.oneOf([HeroContainerMode.Base, HeroContainerMode.Chat]),
  onClickChat: PropType.func,
};

HeroContainer.defaultProps = {
  mode: HeroContainerMode.Base,
  onClickChat: () => { },
};
