import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getTabsForPreset } from "DAI/ContentDetails/containers/ContentLinks/data";
import CopyToClipboard from "DAI/ProcessedData/containers/CopyToClipboard";
import { selectFileType, selectPreset } from "DAI/ContentDetails/store/contentSlice";

export default function HeadingCopyButton() {
  const preset = useSelector(selectPreset);
  const fileType = useSelector(selectFileType);
  const [tabDetails, setTabDetails] = useState(null);
  const [query] = useSearchParams();

  useEffect(() => {
    const tabs = getTabsForPreset(preset);
    const unifiedTabs = [...tabs.contentTabs, ...tabs.socialTabs, ...tabs.createdContentTabs];
    const tabValue = query.get("tab");
    const tab = unifiedTabs.find((t) => t.value === tabValue);

    if (tab) {
      setTabDetails(tab);
    }
  }, [fileType, preset, query]);

  if (tabDetails) {
    return (
      <CopyToClipboard
        tabName={tabDetails.name}
        type={tabDetails.value}
        version="text"
      />
    );
  }
}
