import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import LoadingLogo from "Core/components/LoadingLogo";

export default function AskDeciphrLoadingState({ show }) {
  if (show) {
    return (
      <Box sx={{ m: "64px auto 48px" }}>
        <LoadingLogo />
      </Box>
    );
  }

  return null;
}

AskDeciphrLoadingState.propTypes = {
  show: PropTypes.bool.isRequired,
};
