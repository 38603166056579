import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingLogo from "Core/components/LoadingLogo";

export default function GeneratingOverlay({ show, prefix = "Generating", type = "content" }) {
  if (show) {
    return (
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          height: "300px",
        }}
      >
        <LoadingLogo />

        <Typography
          aria-hidden="true"
          component="span"
          sx={{
            fontSize: "15px",
            fontWeight: 600,
            color: "#6552F3",
            display: "block",
            textAlign: "center",
          }}
        >
          {/* eslint-disable-next-line react/jsx-newline */}
          {prefix} {type}...
        </Typography>
      </Box>
    );
  }

  return null;
}

GeneratingOverlay.propTypes = {
  show: PropTypes.bool.isRequired,
  prefix: PropTypes.string,
  type: PropTypes.string,
};

GeneratingOverlay.defaultProps = {
  prefix: "Generating",
  type: "content",
};
