import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import DAIButton from "Core/components/DAIButton";
import STATES from "DAI/ContentDetails/containers/ShareContentContainer/data";

export default function ShareContentFooterActions({ show, currentState, onShare, onClose }) {
  if (show) {
    return (
      <Box sx={{ display: "flex", gap: "12px", mt: "32px" }}>
        <DAIButton
          variant="outlined"
          isBlock
          sx={{ fontFamily: "Satoshi", fontSize: "16px", fontWeight: 700, mx: 0 }}
          onClick={onClose}
        >
          Cancel
        </DAIButton>

        <DAIButton
          isBlock
          disabled={currentState === STATES.LOADING}
          loading={currentState === STATES.LOADING}
          loadingPosition="start"
          sx={{ fontFamily: "Satoshi", fontSize: "16px", fontWeight: 700, mx: 0 }}
          onClick={onShare}
        >
          {currentState === STATES.SHARED && "Update"}

          {currentState === STATES.UNSHARED && "Share Content"}

          {currentState === STATES.LOADING && "Saving..."}
        </DAIButton>
      </Box>
    );
  }

  return null;
}

ShareContentFooterActions.propTypes = {
  show: PropTypes.bool.isRequired,
  currentState: PropTypes.string.isRequired,
  onShare: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
