import { useEffect, useState } from "react";
import theme from "theme";
import {
  Box,
  ButtonBase,
  css,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { ReactComponent as MoreDotsIcon } from "assets/library/more-menu.svg";

const styles = {
  menu: css({
    border: "1px solid #D0D5DD",
    boxShadow: `
      0px 0.67px 3.5px 0px #00000003,
      0px 2.93px 7.25px 0px #00000004,
      0px 7.2px 14.46px 0px #00000005,
      0px 13.87px 28.35px 0px #00000006,
      0px 23.33px 52.12px 0px #00000008,
      0px 36px 89px 0px #0000000A
    `,
    borderRadius: "10px",

    "& ul": {
      padding: 0,
    },
  }),
  menuItem: css({
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px",
    color: "#475467",
    height: "42px",
  }),
  moreBtn: {
    color: theme.palette.gray[500],
    height: "36px",
    width: "36px",
    borderRadius: "100%",
    transition: "background-color 0.3s",

    "&:hover": {
      backgroundColor: theme.palette.gray[200],
      color: theme.palette.gray[700],
    },
    "&:active": {
      backgroundColor: "#D0D5DD99",
    },
  },
  icon: {
    width: "18px",
    height: "auto",
  },
};

/**
 * @typedef {Object} Item
 * @property {string} Item.label Label to show in the menu
 * @property {typeof MoreDotsIcon} [Item.icon] Icon for the option
 * @property {(clickedItem: Item, index: number) => void} [Item.action] Action to execute when clicked
 * @property {string} [Item.color] Color of the item
 * @property {boolean} [Item.divider] When true a divider is added on top
 *
 * @param {Object} props
 * @param {Item[]} props.items
 * @param {JSX.Element} props.children
 * @param {() => void} [props.onClose] Called when menu is closed
 * @param {(clickedItem: Item, index: number) => void} [props.onClickMenuItemClick] Called when an item clicked
 */
export default function DAIMenu({
  open,
  items,
  color,
  children,
  onClose,
  onClickMenuItemClick,
}) {
  const [localOpen, setLocalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleMenu = (e) => {
    e?.stopPropagation();

    if (e?.currentTarget) {
      setAnchorEl(e.currentTarget);
    }

    if (!localOpen && onClose) {
      onClose();
    }

    setLocalOpen(!localOpen);
  };

  const handleClickMenuItem = async (e, index) => {
    e.stopPropagation();
    const item = items[index];

    if (onClickMenuItemClick) {
      onClickMenuItemClick(item, index);
    }

    if (item.action) {
      item.action(item, index);
    }

    toggleMenu();
  };

  useEffect(() => {
    if (open) {
      setLocalOpen(true);
    } else {
      setLocalOpen(false);
    }
  }, [open]);

  return (
    <>
      <ButtonBase
        disableRipple
        sx={[
          styles.moreBtn,
          {
            color,
          },
        ]}
        type="button"
        tabIndex={-1}
        onClick={toggleMenu}
      >
        {children || (
          <MoreDotsIcon width="24px" height="auto" />
        )}
      </ButtonBase>

      {!!items.length
        && (
          <Menu
            anchorEl={anchorEl}
            open={localOpen}
            PaperProps={{
              sx: styles.menu,
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={toggleMenu}
          >
            {items.map((item, index) => (
              <MenuItem
                key={item.label}
                sx={[
                  styles.menuItem,
                  item.color && { color: item.color },
                  item.divider && { borderTop: `1px solid ${theme.palette.gray[200]}` },
                ]}
                onClick={(e) => handleClickMenuItem(e, index)}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="8px"
                >
                  {item.icon
                    && (
                      <item.icon
                        sx={styles.icon}
                        width="18px"
                        height="auto"
                      />
                    )}

                  <Box>
                    {item.label}
                  </Box>
                </Stack>
              </MenuItem>
            ))}
          </Menu>
        )}
    </>
  );
}
