import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import AudiogramsPlaceholder from "DAI/ProcessedData/containers/AudiogramsPlaceholder";
import { ContentWrapperBody, ContentWrapperHeader } from "DAI/ProcessedData/containers/ContentWrapper";
import SectionTitle from "DAI/ContentDetails/components/SectionTitle";
import PreviewAudiogramPopup from "DAI/ProcessedData/components/AudiogramComponents/previewPopup";
import GeneratingOverlay from "DAI/ProcessedData/components/MediaBlockOverlays/generatingOverlay";
import MediaCard from "DAI/ProcessedData/components/MediaCard";
import { selectFileType, selectHasMedia } from "DAI/ContentDetails/store/contentSlice";
import { downloadVideo } from "pages/newViewTranscript/audiogram/downloadUtil";
import {
  audioFileSelector,
  audiogramSelector,
  checkAllAudiogramStatus,
  downloadLoaderSelector,
  listOfPendingAudiogramsIdSelector,
  setLoading,
} from "store/audiogramSlice";
import { setToast } from "store/toastSlice";
import { analytics } from "utils/GTM";

const STATUS_CHECK_INTERVAL = 10000; // 10 seconds

export default function AudiogramsTabs() {
  const navigate = useNavigate();
  const data = useSelector(audiogramSelector);
  const downloadLoader = useSelector(downloadLoaderSelector);
  const listOfPendingAudiogramsId = useSelector(
    listOfPendingAudiogramsIdSelector,
  );
  const audio = useSelector(audioFileSelector);
  const fileType = useSelector(selectFileType);
  const hasMedia = useSelector(selectHasMedia);
  const params = useParams();
  const dispatch = useDispatch();
  const [selectedAudiogram, setSelectedAudiogram] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (listOfPendingAudiogramsId.length === 0) {
        clearInterval(interval);
        return;
      }

      dispatch(checkAllAudiogramStatus(params.id));
    }, STATUS_CHECK_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [listOfPendingAudiogramsId, params.id, dispatch]);

  const handlePlayVideo = (audiogram) => {
    const eventName = "Preview Audiogram";

    analytics.track(eventName, {
      audiogram_id: audiogram.id,
      background_id: audiogram.background_id,
    });

    setSelectedAudiogram(audiogram);
    setOpen(true);
  };

  const handleClickCustomize = (audiogram) => {
    const eventName = "Audiogram Editor Opened";

    analytics.track(eventName, {
      audiogram_id: audiogram.id,
      background_id: audiogram.background_id,
    });
    const editorPageUrl = `/main_topic/${params.id}/audiograms/${audiogram.id}?tab=layout`;
    navigate(editorPageUrl);

    setSelectedAudiogram(audiogram);
  };

  const downloadHandler = async (payload) => {
    dispatch(setToast({
      severity: "success",
      message: "Starting download",
    }));
    analytics.track("Download Audiogram", payload);

    dispatch(setLoading(payload.audiogram_id));

    await downloadVideo(payload?.video_url || "", "Audiogram");

    dispatch(setLoading(null));
  };

  const handleClose = () => {
    setSelectedAudiogram(null);
    setOpen(false);
    analytics.track("Audiogram Popup Closed");
  };

  if (fileType === "document" || hasMedia === false) {
    return (
      <>
        <ContentWrapperHeader>
          <SectionTitle title="Audiograms" />
        </ContentWrapperHeader>

        <ContentWrapperBody>
          <AudiogramsPlaceholder show />
        </ContentWrapperBody>
      </>
    );
  }

  return (
    <Box>
      <ContentWrapperHeader>
        <SectionTitle title="Audiograms" />
      </ContentWrapperHeader>

      <ContentWrapperBody>
        <Stack
          gap="32px"
          pb="32px"
        >
          {data?.map((item) => (
            <MediaCard
              key={item.id}
              media={item}
              isPending={listOfPendingAudiogramsId.includes(item.id)}
              isLoading={downloadLoader === item.id}
              onClickDownload={downloadHandler}
              onClickCustomize={handleClickCustomize}
              onClickPlay={handlePlayVideo}
            />
          ))}
        </Stack>

        <GeneratingOverlay show={!data} type="Audiograms" />
      </ContentWrapperBody>

      <PreviewAudiogramPopup
        handleClose={handleClose}
        downloadHandler={downloadHandler}
        audio={audio}
        open={open}
        loading={downloadLoader === selectedAudiogram?.id}
        selectedAudiogram={selectedAudiogram}
      />
    </Box>
  );
}
