import { useRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import DAIButton from "Core/components/DAIButton";

const StyledTextArea = styled(TextareaAutosize)(() => ({
  display: "block",
  overflow: "visible",
  resize: "none",
  width: "100%",
  fontFamily: "Satoshi",
  fontSize: "16px",
  fontWeight: 400,
  margin: "24px auto",
  padding: "12px",
  border: "thin solid #DDD",
  borderRadius: "4px",
  transition: "all 0.4s ease-out",

  "&:hover": {
    border: "thin solid #6552F3",
  },

  "&:focus": {
    boxShadow: "0 0 7px 0 rgba(0, 0, 0, 0.25)",
    outline: 0,
  },
}));

export default function FeedbackForm({ show, onSubmit }) {
  const formRef = useRef();

  if (show) {
    return (
      <form name="feedback" noValidate ref={formRef} onSubmit={onSubmit}>
        <label htmlFor="feedback" hidden>Feedback</label>

        <StyledTextArea
          id="feedback"
          required
          placeholder="Let us know your feedback"
          minRows={5}
          maxRows={5}
        />

        <DAIButton
          type="submit"
          size="small"
        >
          Send
        </DAIButton>
      </form>
    );
  }

  return null;
}

FeedbackForm.propTypes = {
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
