import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import FindAndReplaceToggleButton from "DAI/Transcript/components/FindReplaceToggleButton";

export default function SectionAction({ actionType, show, isActionVisible }) {
  if (show && isActionVisible) {
    return (
      <Box sx={{ display: "flex" }}>
        {actionType === "find" && (
          <FindAndReplaceToggleButton show />
        )}
      </Box>
    );
  }

  return null;
}

SectionAction.propTypes = {
  actionType: PropTypes.string,
  show: PropTypes.bool.isRequired,
  isActionVisible: PropTypes.bool,
};
