import PropTypes from "prop-types";
import Box from "@mui/material/Box";

function Loader() {
  return (
    <Box
      component="span"
      sx={{
        width: "6px",
        height: "6px",
        display: "inline-block",
        margin: "12px 3px",
        position: "relative",
        bgcolor: "#777E90",
        boxSizing: "border-box",
        animation: "animloader 1s linear infinite alternate",

        "@keyframes animloader": {
          "0%": {
            transform: "translateY(-4px)",
          },

          "33%": {
            transform: "translateY(4px)",
          },

          "66%": {
            transform: "translateY(-4px)",
          },

          "100%": {
            transform: "translateY(4px)",
          },
        },
      }}
    />
  );
}

function LoaderAlt() {
  return (
    <Box
      component="span"
      sx={{
        width: "6px",
        height: "6px",
        display: "inline-block",
        margin: "12px auto",
        position: "relative",
        bgcolor: "#777E90",
        boxSizing: "border-box",
        animation: "animloaderAlt 1s linear infinite alternate",

        "@keyframes animloaderAlt": {
          "0%": {
            transform: "translateY(4px)",
          },

          "33%": {
            transform: "translateY(-4px)",
          },

          "66%": {
            transform: "translateY(4px)",
          },

          "100%": {
            transform: "translateY(-4px)",
          },
        },
      }}
    />
  );
}

export default function ProcessingLoader({ altText, show }) {
  if (show) {
    return (
      <Box
        component="span"
        aria-hidden
        title={altText}
        tabIndex={-1}
      >
        <Loader />

        <LoaderAlt />

        <Loader />
      </Box>
    );
  }

  return null;
}

ProcessingLoader.propTypes = {
  altText: PropTypes.string,
  show: PropTypes.bool.isRequired,
};

ProcessingLoader.defaultProps = {
  altText: "Processing...",
};
