import PropTypes from "prop-types";
import theme from "theme";
import Typography from "@mui/material/Typography";

const styles = {
  root: {
    fontFamily: "Satoshi",
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "34.8px",
    textAlign: "left",
    color: theme.palette.gray[800],
  },
};

export default function SectionTitle({ title }) {
  return (
    <Typography
      component="h2"
      sx={styles.root}
    >
      {title}
    </Typography>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
