import { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import camelCase from "lodash/camelCase";
import intersectionBy from "lodash/intersectionBy";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import styles from "DAI/ContentDetails/containers/ContentLinks/styles";
import ContentLink from "DAI/ContentDetails/components/ContentLink";
import { getCreateContentTypesListFor } from "DAI/ContentDetails/services/presets";
import { selectFileMetadata } from "DAI/ContentDetails/store/contentSlice";
import { createdContentTypesSelector } from "DAI/ProcessedData/store/writtenContentSlice";
import { getSidebarLinks } from "./data";

const isLinkVisible = (tab, fileType) => {
  let isVisible = false;

  if (Array.isArray(tab.fileTypes)) {
    if (tab.fileTypes.indexOf(fileType) > -1) {
      isVisible = true;
    }
  } else {
    isVisible = true;
  }

  return isVisible;
};

export default function ContentLinks({ currentTab, isLoading }) {
  const metadata = useSelector(selectFileMetadata);
  const sidebarLinks = getSidebarLinks(metadata.preset || "podcast");
  const createdContentTypes = useSelector(createdContentTypesSelector);

  const createdContentTabs = useMemo(() => {
    if (!createdContentTypes?.length || !metadata.preset) {
      return [];
    }

    const presetCreateContentTypes = getCreateContentTypesListFor(metadata.preset);

    const tabs = intersectionBy(createdContentTypes, presetCreateContentTypes, (item) => item.value);

    return tabs.map((type) => ({
      label: type.label,
      value: camelCase(type.value),
    }));
  }, [createdContentTypes, metadata]);

  return (
    <Stack
      justifyContent="space-between"
      sx={[
        styles.root,
        isLoading && styles.loading,
      ]}
    >
      {!!createdContentTabs.length && (
        <Box>
          <Typography component="h2" sx={[styles.title, styles.createdContentTitle]}>
            created content
          </Typography>

          {/* <ButtonBase */}
          {/*   sx={styles.newContentBtn} */}
          {/*   disableRipple */}
          {/* > */}
          {/*   <PlusIcon /> */}
          {/**/}
          {/*   New Content */}
          {/* </ButtonBase> */}

          <Stack
            sx={styles.itemWrapper}
            gap="13px"
          >
            <nav aria-label="Created content links">
              <Stack
                gap="13px"
                component="ul"
                sx={styles.listItem}
              >
                {createdContentTabs.map((tab) => (
                  <ContentLink
                    tabValue={tab.value}
                    docId={metadata.fileId}
                    isActive={currentTab === tab.value}
                    show
                    key={tab.value}
                  >
                    {tab.label}
                  </ContentLink>
                ))}
              </Stack>
            </nav>
          </Stack>
        </Box>
      )}

      <Box sx={[styles.links, !!createdContentTabs.length && styles.dfyLinks]}>
        <div style={{ marginBottom: "48px" }}>
          <Typography component="h2" sx={styles.title}>
            Content
          </Typography>

          <Stack
            sx={styles.itemWrapper}
            gap="13px"
          >
            {sidebarLinks.map((category) => {
              if (
                category.fileTypes.some(
                  (fileType) => fileType === metadata.fileType,
                )
              ) {
                return (
                  <nav aria-label={`${category.name} content links`}>
                    <Stack
                      gap="13px"
                      component="ul"
                      sx={styles.listItem}
                    >
                      {category.tabs.map((tab) => (
                        <ContentLink
                          type={category.type}
                          tabValue={tab.value}
                          docId={metadata.fileId}
                          isActive={currentTab === tab.value}
                          // Hide the "Video Reels" tab when the uploaded file is not a video
                          show={isLinkVisible(tab, metadata.fileType)}
                          key={`${tab.id}${category.id}`}
                        >
                          {tab.name}
                        </ContentLink>
                      ))}
                    </Stack>
                  </nav>
                );
              }

              return <Fragment key={category.id} />;
            })}
          </Stack>
        </div>
      </Box>
    </Stack>
  );
}

ContentLinks.propTypes = {
  currentTab: PropTypes.string,
  isLoading: PropTypes.bool,
};

ContentLinks.defaultProps = {
  currentTab: undefined,
  isLoading: false,
};
