import { useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
import { Divider, ListItemIcon, ListItemText, MenuItem, Stack, SvgIcon } from "@mui/material";
import UserMenuItem from "DAI/Profile/components/UserMenuItem";
import { GlobalData } from "context";
import { reset, userInfoSelector } from "store/user";
import { analytics } from "utils/GTM";
import { items } from "./data";
import { ReactComponent as SignOutIcon } from "assets/icons/sign-out.svg";

export default function UserMenuItemCtn({ onClose }) {
  const { setLogin } = useContext(GlobalData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector(userInfoSelector);

  const itemsToRender = useMemo(() => (
    items.filter((item) => {
      if (userInfo.loginInfo.isSeatAccount && item.hideToSeatAccount) {
        return false;
      }

      return true;
    })
  ), [userInfo.loginInfo.isSeatAccount]);

  const logout = () => {
    localStorage.removeItem("userInfo");
    setLogin(false);
    navigate("/auth/login");
    dispatch(reset());
    onClose();

    window.Intercom("shutdown");
    analytics.track("logout");
    analytics.reset();

    googleLogout();
  };

  return (
    <nav aria-label="account links">
      <Stack component="ul" sx={{ p: 0, width: "100%" }}>
        {itemsToRender.map((item) => (
          <UserMenuItem
            name={item.name}
            icon={item.icon}
            to={item.to}
            target={item.target}
            key={item.name}
            onClose={onClose}
          />
        ))}
      </Stack>

      <Divider flexItem sx={{ width: "100%" }} />

      <MenuItem
        onClick={logout}
        sx={{
          padding: "8px",
          color: "#F44E45",
          width: "100%",
          "& .MuiListItemIcon-root": {
            minWidth: 0,
            mr: "8px",
          },
        }}
      >
        <ListItemIcon>
          <SvgIcon
            sx={{ fontSize: 16, color: "#F44E45" }}
            component={SignOutIcon}
            inheritViewBox
          />
        </ListItemIcon>

        <ListItemText sx={{ fontSize: "14px", fontWeight: 500 }}>
          Log Out
        </ListItemText>
      </MenuItem>
    </nav>
  );
}
