import PropTypes from "prop-types";
import { Box, Stack } from "@mui/material";
import HeadingCopyButton from "DAI/ProcessedData/containers/HeadingCopyButton";
import SectionAction from "DAI/ContentDetails/components/SectionAction";
import SectionTitle from "DAI/ContentDetails/components/SectionTitle";

export default function ContentHeading({ title, show, options }) {
  if (show) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <SectionTitle title={title} />

        {options && (
          <Box sx={{ display: "flex", gap: "12px" }}>
            <SectionAction
              actionType={options.actionButtonType}
              show
              isActionVisible={options.isActionVisible}
            />

            <HeadingCopyButton />
          </Box>
        )}
      </Stack>
    );
  }

  return false;
}

ContentHeading.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  options: PropTypes.shape({
    userPlan: PropTypes.string.isRequired,
    actionButtonType: PropTypes.string,
    isActionVisible: PropTypes.bool,
  }),
};

ContentHeading.defaultProps = {
  options: undefined,
};
