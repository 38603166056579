import * as amplitude from "@amplitude/marketing-analytics-browser";
import googleTagManager from "@analytics/google-tag-manager";
import Analytics from "analytics";

const pluginsArry = () => {
  const arry = [
    googleTagManager({
      containerId: process.env.REACT_APP_GMT_ID,
    }),
  ];
  return arry;
};

export const GTM_Analytics = Analytics({
  app: "deciphr_app",
  plugins: pluginsArry(),
});

/**
 *
 * @param {string} eventName Name of the FB Pixel event to trigger
 * @param {object} [props] Custom properties to attach to the event. Optional.
 */
export const GTMPixelTrack = (eventName, props) => {
  if (process.env.REACT_APP_ENV === "production") {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: eventName,
        props,
      });
    }
  }
};

export const GTM = {
  /**
   * Track the list of items being purchased for GTM Meta Pixel tracking
   *
   * @param {string} plan The subscription/add-on plan name
   * @param {number} add_on The add-on price
   * @param {boolean} isPayG Indicates a one-time purchase of an add-on, without a subscription plan
   */
  trackPurchasedItems: (plan, addOn) => {
    const items = [];
    const isPayG = !!addOn;

    if (isPayG) {
      if (addOn?.value) {
        items.push({ name: addOn.name, price: addOn.price });
      }
    } else {
      items.push({
        name: `${plan.name} (${plan.planCode})`,
        price: plan.amount,
      });
    }

    items.forEach((item) => {
      GTMPixelTrack("fb_purchase", {
        content_name: item.name,
        currency: "USD",
        num_items: 1,
        value: item.price,
      });
    });
  },
};

export const analytics = {
  /**
   *
   * @param {object} page properties of the page like url, title, etc
   */
  page: (page) => {
    GTM_Analytics.page(page);
  },
  /**
   * to track every event in amplitude and GTM
   * @param {string} event event name
   * @param {object} properties other event properties
   */
  track: (event, properties) => {
    if (process.env.REACT_APP_AMPLITUDE_TURN_ON === "true") {
      GTM_Analytics.track(event, properties);
      amplitude.track(event, properties);
    }
  },
  /**
   * to set user properties in amplitude and GTM
   * @param {string} userId unique user id
   * @param {object} properties user properties
   */
  identify: (userId, properties) => {
    GTM_Analytics.identify(userId, properties);

    if (process.env.REACT_APP_AMPLITUDE_TURN_ON === "true") {
      // to set user properties in amplitude
      const identifyEvent = new amplitude.Identify();

      if (userId) identifyEvent.set("user_id", userId);

      Object.keys(properties).forEach((key) => {
        identifyEvent.set(key, properties[key]);
      });

      amplitude.identify(identifyEvent);

      /**
       * to set user id in amplitude
       * this is required to link the user in amplitude
       */
      amplitude.setUserId(userId);
    }
  },

  /**
   * to initialize amplitude on page load if amplitude is turned on
   * this is required to link the user in amplitude
   * conditionally initialize Amplitude based on the environment variable
   */
  init: () => {
    if (process.env.REACT_APP_AMPLITUDE_TURN_ON === "true") {
      amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, null, {
        attribution: {
          initialEmptyValue: "none",
          resetSessionOnNewCampaign: true,
        },
        // pageViewTracking: {
        //   trackHistoryChanges: 'pathOnly' // or 'all'
        // }
      });
    }
  },

  /**
   * to reset user in amplitude
   */
  resetUser: () => {
    if (process.env.REACT_APP_AMPLITUDE_TURN_ON === "true") {
      // amplitude.reset();
    }
  },
};
