import PropTypes from "prop-types";
import theme from "theme";
import { ButtonBase, Stack } from "@mui/material";
import { analytics } from "utils/GTM";
import { ReactComponent as BookmarkIcon } from "assets/rag-search/bookmark.svg";
import { ReactComponent as BookmarkAddedIcon } from "assets/rag-search/bookmark-added.svg";

const styles = {
  root: {
    color: theme.palette.gray[500],
    fontFamily: "Satoshi",
    fontSize: "16px",
    fontWeight: "500",
    whiteSpace: "nowrap",

    "&:hover": {
      color: theme.palette.gray[600],
    },
  },
};

export default function SaveSessionBtn({ isSaved, onClick }) {
  const handleClick = () => {
    if (!isSaved && onClick) {
      onClick();

      analytics.track("Save Session Button Clicked");
    }
  };

  return (
    <ButtonBase onClick={handleClick} disabled={isSaved}>
      <Stack
        direction="row"
        alignItems="center"
        gap="10px"
        sx={styles.root}
      >
        {isSaved
          ? (
            <BookmarkAddedIcon
              height="20px"
              width="auto"
              color={theme.palette.gray[600]}
            />
          )
          : <BookmarkIcon height="20px" width="auto" />}

        {isSaved ? "Saved" : "Save Session"}
      </Stack>
    </ButtonBase>
  );
}

SaveSessionBtn.propTypes = {
  isSaved: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
