import { useEffect, useRef, useState } from "react";
import PropType from "prop-types";
import { visuallyHidden } from "@mui/utils";
import { Box, Stack } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import styles from "DAI/ProcessedData/components/AskDeciphrInput/styles";
import { ReactComponent as SparkleGradientIcon } from "assets/icons/ai-assistant-sparkle-gradient.svg";

export const State = {
  initial: "initial",
  loading: "loading",
  generating: "generating",
  result: "result",
};

/**
 * @param {Object} props
 * @param {keyof typeof State} props.state
 * @param {string} props.placeholder
 * @param {boolean} props.haveLeftIconBg
 * @param {() => void} props.onClear
 */
export default function AskDeciphrInput({
  state,
  value,
  leftIcon = SparkleGradientIcon,
  haveLeftIconBg = true,
  hideLeftIcon = false,
  placeholder = "Let me know how can I help...",
  onChange,
  onSubmit,
}) {
  const LeftIconComponent = leftIcon;
  const [localValue, setLocalValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (typeof value === "string") {
      setLocalValue(value);
    }

    inputRef.current.focus();
  }, [value]);

  const handleChange = (e) => {
    const valueToSet = e?.target?.value || "";

    setLocalValue(valueToSet);

    if (onChange) {
      onChange(valueToSet);
    }
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    e?.stopPropagation();

    if ([State.generating, State.loading].includes(state)) {
      return;
    }

    if (onSubmit) {
      onSubmit(localValue);
    }
  };

  return (
    <Box component="form" sx={styles.root} onSubmit={handleSubmit}>
      <Box sx={visuallyHidden} component="label" htmlFor="ask-deciphr-input">
        Ask any question
      </Box>

      {!hideLeftIcon && (
        <Stack
          sx={{
            ...styles.startItem,
            ...(haveLeftIconBg ? styles.startItemBg : {}),
          }}
          alignItems="center"
          justifyContent="center"
        >
          <LeftIconComponent height="22px" width="auto" />
        </Stack>
      )}

      <Box
        component="input"
        autoComplete="off"
        sx={[
          styles.input,
          hideLeftIcon && styles.hiddenLeftIcon,
        ]}
        id="ask-deciphr-input"
        placeholder={placeholder}
        value={localValue}
        ref={inputRef}
        onChange={handleChange}
      />

      <Stack
        className="send-btn"
        component="button"
        alignItems="center"
        justifyContent="center"
        type="submit"
        disabled={state === State.loading || state === State.generating}
        sx={styles.btn}
      >
        <SendIcon sx={styles.sendIcon} />
      </Stack>
    </Box>
  );
}

AskDeciphrInput.propTypes = {
  state: PropType.string,
  value: PropType.string,
  // eslint-disable-next-line react/forbid-prop-types
  leftIcon: PropType.any,
  haveLeftIconBg: PropType.bool,
  hideLeftIcon: PropType.bool,
  placeholder: PropType.string,
  onChange: PropType.func,
  onSubmit: PropType.func,
};

AskDeciphrInput.defaultProps = {
  state: State.initial,
  value: "",
  leftIcon: SparkleGradientIcon,
  haveLeftIconBg: true,
  hideLeftIcon: false,
  placeholder: "Let me know how can I help...",
  onChange: () => { },
  onSubmit: () => { },
};
