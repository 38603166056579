import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import UnlockMediaModal from "DAI/ProcessedData/components/UnlockMediaModal";
import { ReactComponent as LockIcon } from "assets/lock.svg";

export default function LockContents() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <IconButton size="small" onClick={() => setIsModalOpen(true)}>
        <LockIcon fill="#909196" />
      </IconButton>

      <UnlockMediaModal
        open={isModalOpen}
        contentType={"Title Suggestions"}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
