import { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import ProcessingLoader from "DAI/ContentDetails/components/ProcessingLoader";
import { getContentTypeLabel, mapContentTypeToSnakeCase } from "DAI/ContentDetails/services/presets";
import { isContentReady, selectPreset } from "DAI/ProcessedData/store/writtenContentSlice";
import styles from "./styles";

const MediaTabIcon = lazy(() => import("DAI/ContentDetails/components/MediaTabIcon"));

export default function ContentLink({ children, type, docId, tabValue, isActive, show = true }) {
  const isContentLoaded = useSelector(isContentReady(tabValue));
  const preset = useSelector(selectPreset);
  const contentType = getContentTypeLabel(mapContentTypeToSnakeCase(tabValue, preset));

  if (!show) {
    return null;
  }

  return (
    <Stack
      component="li"
      alignItems="center"
      direction="row"
      gap="26px"
      sx={[styles.root, isActive && styles.active]}
    >
      <Box sx={[styles.bar, isActive && styles.activeBar]} />

      <Stack
        component={Link}
        to={`/content/${docId}?tab=${tabValue}`}
        direction="row"
        gap="0.5rem"
        justifyContent="space-between"
        alignItems="center"
      >
        <span>{children}</span>

        <ProcessingLoader altText={`Generating ${contentType}...`} show={!isContentLoaded} />

        <Suspense fallback={<div />}>
          <MediaTabIcon name={children} show={type === "media"} />
        </Suspense>
      </Stack>
    </Stack>
  );
}

ContentLink.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["media", "content"]).isRequired,
  docId: PropTypes.string.isRequired,
  tabValue: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
};
