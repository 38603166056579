import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { throttle } from "lodash";
import { Box, Stack } from "@mui/material";
import AskDeciphrQnA from "DAI/ProcessedData/containers/AskDeciphrQnA";
import AskDeciphrLoadingState from "DAI/ProcessedData/components/AskDeciphr/loadingState";
import AskDeciphrInput from "DAI/ProcessedData/components/AskDeciphrInput";
import styles from "./styles";
import SuggestionList from "./SuggestionList";

const State = {
  initial: "initial",
  loading: "loading",
  generating: "generating",
  result: "result",
};

export default function FileChat() {
  const [threadId, setThreadId] = useState(null);
  const [state, setState] = useState(State.initial);
  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState("");
  const inputRef = useRef(null);
  const rootRef = useRef(null);
  const endPlaceholderRef = useRef(null);

  const scrollChatToLatest = () => {
    setTimeout(() => {
      endPlaceholderRef.current?.scrollIntoView();
    }, 1000);
  };

  const handleAsk = async (input) => {
    setQuestions((q) => [...q, input]);
    setQuestion("");
    setState(State.loading);
    scrollChatToLatest();
  };

  const handleAnswerLoaded = () => {
    setState(State.result);
  };

  const handleClickSuggestionChip = (value) => {
    setQuestion(value);
  };

  const updateInputStyles = useCallback(
    throttle(
      () => {
        const { width, left } = rootRef.current.getBoundingClientRect();

        inputRef.current.style.width = `${width}px`;
        inputRef.current.style.position = "fixed";
        inputRef.current.style.left = `${left}px`;
        inputRef.current.style.bottom = "0px";
      },
      100,
    ),
    [inputRef],
  );

  useLayoutEffect(() => {
    updateInputStyles();
    window.addEventListener("resize", updateInputStyles);

    return () => {
      window.removeEventListener("resize", updateInputStyles);
    };
  }, []);

  return (
    <Stack
      ref={rootRef}
      direction="column"
      alignItems="center"
      justifyContent="start"
      sx={[
        styles.root,
        {
          paddingTop: (state === State.initial) ? "140px" : "50px",
        },
      ]}
    >
      {!!questions.length && (
        <Box sx={{ width: "95%", paddingBottom: "500px" }}>
          {questions.map((q, index) => (
            <AskDeciphrQnA
              question={q}
              threadId={threadId}
              key={index}
              setThreadId={setThreadId}
              onLoaded={handleAnswerLoaded}
            />
          ))}

          <Box ref={endPlaceholderRef} sx={styles.endPlaceholder} />

          <AskDeciphrLoadingState show={state === State.loading} />
        </Box>
      )}

      {state === State.initial && (
        <SuggestionList
          onClick={handleClickSuggestionChip}
        />
      )}

      <Box
        ref={inputRef}
        sx={[
          styles.input,
        ]}
      >
        <AskDeciphrInput
          state={state}
          placeholder={questions.length > 0 ? "Ask a follow-up question" : "Ask anything about this file"}
          hideLeftIcon
          value={question}
          onSubmit={handleAsk}
        />
      </Box>
    </Stack>
  );
}
