import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import theme from "theme";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { userInfoSelector } from "store/user";

export default function AskDeciphrQuestion({ question }) {
  const { firstName } = useSelector(userInfoSelector);

  return (
    <Stack
      direction="row"
      alignItems="center"
      columnGap="20px"
      justifyContent="flex-start"
      sx={{ width: "100%", mb: "32px" }}
    >
      <Avatar
        sx={{
          bgcolor: "#52A6F3",
          color: "#FFF",
          fontFamily: "DM Sans",
          fontSize: "14px",
          fontWeight: 700,
          height: "32px",
          width: "32px",
        }}
      >
        {firstName[0]}
      </Avatar>

      <Typography
        sx={{
          color: theme.palette.gray[700],
          fontFamily: "Satoshi",
          fontSize: "20px",
          fontWeight: 900,
          letterSpacing: "-0.2px",
          lineHeight: "32px",
        }}
      >
        {question}
      </Typography>
    </Stack>
  );
}

AskDeciphrQuestion.propTypes = {
  question: PropTypes.string.isRequired,
};
