/*
** DEPRECATED: DO NOT PILE ON TO THIS FILE ANY MORE!
**
** The CORRECT way to add page view tracking and other page-related
** metadata is to create a DAI/<feature>/pages/<PageName>/index.js
** file and import React Helmet Async, the Amplitude analytics
** package etc. and set it up there.
**
** An example of this is available at src/DAI/Library/pages/AskDeciphr/index.js
*/

// For analytics tracking only
// eslint-disable-next-line import/prefer-default-export
export const routesMap = {
  login: "View Login Page",
  signup: "View Signup Page",
  forgot: "View Forgot Password Page",
  "password-reset": {
    resetPassword: "View Reset Password Page",
    verifyEmail: "View Email Verification Confirmation Page",
  },
  success: "View Success Page",
  tools_content: "View Tools Content Library Page",
  writing_style: "View Writing Style Page",
  tools: "View All Tools Page",
  profile: "View Profile Page",
  download: "View Download Page",
  content_brain: {
    "": "View Content Brain Home Page",
    create: "View Content Brain Create Page",
    library: "View Content Brain Library Page",
    content: "View Content Brain Processed Content Page",
    planning: "View Content Brain Planning Page",
    dictionary: "View Content Brain Dictionary Page",
    search: "View Content Brain Search Page",
    search_detail: "View Content Brain Search Detail Page",
    planning_detail: "View Content Brain Planning Detail Page",
  },
};
