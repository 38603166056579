import { useRef } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { currentHighlightedIndexSelector, findTextSelector } from "store/transcriptSlice";

export default function EditTranscript({ transcriptText, tabIndex, save, highlightText }) {
  const editRef = useRef(null);
  const searchTerm = useSelector(findTextSelector);
  const currentHighlightedIndex = useSelector(currentHighlightedIndexSelector);

  const saveHandler = () => {
    if (transcriptText === editRef.current.innerText) {
      return;
    }

    save({
      text: editRef.current.innerText,
      index: tabIndex,
    });
  };

  return (
    <Box
      sx={{
        fontWeight: "500",
        color: "#23262F",
        outline: "none",
      }}
      tabIndex={tabIndex}
      className="edit-transcript"
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          saveHandler();
        }
      }}
    >
      <Typography
        gutterBottom
        component="div"
        sx={{
          fontWeight: "500",
          color: "#23262F",
          outline: "none",
        }}
        className="summary"
        contentEditable
        ref={editRef}
        suppressContentEditableWarning
      >
        {highlightText(
          transcriptText,
          searchTerm,
          currentHighlightedIndex,
          tabIndex,
        )}
      </Typography>
    </Box>
  );
}
