const styles = {
  root: {
    background: "white",
    minHeight: "100vh",
  },
  content: {
    paddingTop: "58px",
  },
  splitSection: {
    height: "100%",
    overflow: "auto",
    "-ms-overflow-style": "none", /* Internet Explorer 10+ */
    "scrollbar-width": "none", /* Firefox */

    "&::-webkit-scrollbar": {
      display: "none", /* Safari and Chrome */
    },

    "&:nth-of-type(2)": {
      borderLeft: "1px solid #E3E6EA",
    },
  },
  rightSide: {
    flexGrow: 1,
  },
  baseHero: {
    padding: "44px 46px 45px 46px",
    borderBottom: "1px solid #E3E6EA",
  },
  chatHero: {
    padding: "46px 65px 56px 65px",
    borderBottom: "1px solid #E3E6EA",
  },
  chatModeContentSection: {
    padding: "48px 60px 0px 57px",
  },
};

export default styles;
