import theme from "theme";

const styles = {
  root: {
    position: "relative",
    background: "white",
    width: "100%",
    borderRadius: "35px",

    "&:hover": {
      input: {
        bgcolor: "#F2F4F74D",
      },

      "button.send-btn[disabled]": {
        bgcolor: theme.palette.gray[200],
      },
    },
  },
  input: {
    borderRadius: "35px",
    padding: "12px 70px 12px 74px",
    color: theme.palette.gray[800],
    border: "thin solid #E3E6EA",
    background: "white",
    width: "100%",
    height: "64px",
    fontFamily: "Satoshi",
    fontSize: {
      xs: "16px",
      md: "18px",
    },

    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "0.36px",
    textAlign: "left",

    "&:active, &:focus": {
      border: `thin solid ${theme.palette.primary[500]}`,
      outline: "none",
    },

    "&::placeholder": {
      color: theme.palette.gray[400],
      fontWeight: 600,
      fontSize: {
        xs: "16px",
        md: "18px",
      },
    },
  },

  hiddenLeftIcon: {
    paddingLeft: {
      xs: "20px",
      md: "40px",
    },
  },

  btn: {
    borderRadius: "9999px",
    height: "40px",
    width: "40px",
    border: "none",
    background: theme.palette.brand[500],
    color: "white",
    position: "absolute",
    right: "18px",
    top: "12px",
    zIndex: 2,

    "&:disabled": {
      background: theme.palette.gray[200],
      color: "white",
      pointerEvents: "none",
    },
  },
  clearBtn: {
    background: "none",
    color: theme.palette.gray[400],

    "&:hover": {
      color: theme.palette.gray[500],
    },
  },
  sendIcon: {
    height: "16px",
    width: "auto",
  },
  startItem: {
    position: "absolute",
    left: "15px",
    top: "12px",
    borderRadius: "999px",
    height: "40px",
    width: "40px",
  },
  startItemBg: {
    background: "linear-gradient(270deg, rgba(167, 129, 255, 0.1) 0%, rgba(27, 187, 255, 0.1) 100%)",
  },
};

export default styles;
