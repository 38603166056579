import PropTypes from "prop-types";
import theme from "theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as AIIcon } from "assets/icons/ai-assistant-sparkle-gradient.svg";

const styles = {
  headingContainer: {
    marginBottom: "72px",
    textAlign: "center",
  },

  heading: {
    marginBottom: "8px",
  },

  headingSubtext: {
    fontFamily: "DM Sans",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "29px",
    letterSpacing: "-0.03em",
    textAlign: "center",
    marginTop: "1rem",
    color: theme.palette.gray[500],
  },
};

export default function AskDeciphrHeader({ show }) {
  if (show) {
    return (
      <Box sx={styles.headingContainer}>
        <AIIcon aria-label="Ask Deciphr" height={40} width={40} style={styles.heading} />

        <Typography component="p" sx={styles.headingSubtext}>
          Find answers, ideas, or inspiration from this file
        </Typography>
      </Box>
    );
  }

  return null;
}

AskDeciphrHeader.propTypes = {
  show: PropTypes.bool.isRequired,
};
