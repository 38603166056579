import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ContentHeading from "DAI/ContentDetails/containers/ContentHeading";
import AskDeciphrQnA from "DAI/ProcessedData/containers/AskDeciphrQnA";
import SaveSessionBtn from "DAI/Library/components/SaveSessionBtn";
import AskDeciphrHeader from "DAI/ProcessedData/components/AskDeciphr/header";
import AskDeciphrLoadingState from "DAI/ProcessedData/components/AskDeciphr/loadingState";
import AskDeciphrSuggestions from "DAI/ProcessedData/components/AskDeciphr/suggestions";
import AskDeciphrInput from "DAI/ProcessedData/components/AskDeciphrInput";
import { saveSession, selectAskDeciphrSlice, selectHasOutput } from "DAI/ProcessedData/store/askDeciphrSlice";
import styles from "./styles";

const State = {
  initial: "initial",
  loading: "loading",
  generating: "generating",
  result: "result",
};

export default function AskDeciphrContainer() {
  const isDirty = useSelector(selectHasOutput);
  const [threadId, setThreadId] = useState(null);
  const [state, setState] = useState(State.initial);
  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState("");
  const { savedThreadIds } = useSelector(selectAskDeciphrSlice);

  const dispatch = useDispatch();

  const handleAsk = async (input) => {
    setQuestions((q) => [...q, input]);
    setQuestion("");
  };

  const handleClickSuggestionChip = (value) => {
    setQuestion(value);
  };

  const isThreadSaved = useMemo(
    () => (!!savedThreadIds.find((item) => (item === threadId))),
    [threadId, savedThreadIds],
  );

  const saveThread = () => {
    if (!threadId) {
      return;
    }

    dispatch(saveSession(threadId)).unwrap();
  };

  useEffect(() => {
    if (isDirty === false) {
      // reset state to initial
      setState(State.initial);
      setQuestion("");
      setQuestions([]);
      setThreadId(null);

      window.scrollTo(0, 0);
    }
  }, [isDirty]);

  return (
    <>
      <ContentHeading
        title="Ask Deciphr"
        show
        options={{
          feature: "ask_deciphr",
        }}
      />

      <Stack
        direction="column"
        alignItems="center"
        justifyContent="start"
        sx={{
          minHeight: "calc(100vh - 114px)",
          padding: "50px 100px 100px 100px",
          paddingTop: (state === State.initial) ? "140px" : "50px",
          position: "relative",
        }}
      >
        <Box width="100%">
          <AskDeciphrHeader show={state === State.initial} />
        </Box>

        <Box sx={{ width: "95%" }}>
          {questions.map((q, index) => (
            <AskDeciphrQnA
              question={q}
              threadId={threadId}
              state={state}
              key={index}
              setThreadId={setThreadId}
              setState={setState}
            />
          ))}

          {state === State.result
            && (
              <Box sx={styles.saveSessionBtn}>
                <SaveSessionBtn
                  isSaved={isThreadSaved}
                  onClick={saveThread}
                />
              </Box>
            )}
        </Box>

        <AskDeciphrLoadingState show={state === State.loading} />

        <Box
          sx={{
            mt: questions.length ? "60px" : 0,
            position: (questions.length) ? "absolute" : "static",
            bottom: 0,
            width: (questions.length) ? "84%" : "100%",
          }}
        >
          <AskDeciphrInput
            state={state}
            value={question}
            placeholder={questions.length > 0 ? "Ask a follow-up question" : "Ask me any questions about this file..."}
            onChange={setQuestion}
            onSubmit={handleAsk}
          />

          <AskDeciphrSuggestions
            show={state === State.initial}
            onClick={handleClickSuggestionChip}
          />
        </Box>
      </Stack>
    </>
  );
}
